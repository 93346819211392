import { useMutation } from "@tanstack/react-query";

import { sendPasswordResetEmail } from "../../services/auth";

interface IPayload {
    email: string;
    setIsSuccess: Function;
    setApiError: Function;
    setIsProcessing: Function;
};

interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useSendPasswordResetEmail = () => {
    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await sendPasswordResetEmail(data.email);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response) {
                variables.setIsSuccess(true);
            }
        },
        onError: (error: IResponse, variables: IPayload) => {
            variables.setApiError(error.message);
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useSendPasswordResetEmail;