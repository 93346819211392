import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { ISaveWorkerJobs } from "../../interfaces/services";

import useToast from "../useToast";

import { saveWorkerJobs } from "../../services/worker_jobs";

export interface IPayload {
    payload: ISaveWorkerJobs;
    setIsProcessing: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useSaveWorkerJobs = () => {
    const navigate = useNavigate();
    // const queryClient = useQueryClient();

    const { addToast } = useToast();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await saveWorkerJobs(data.payload);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response?.statusCode === 200) {
                addToast({
                    type: 'success',
                    title: 'Job details saved successfully.',
                });

                // queryClient.invalidateQueries({ queryKey: ['worker_jobs'] });
                setTimeout(() => {
                    navigate(`/foreign-workers/${variables.payload.foreignWorkerId}/immigration`);
                }, 500);
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useSaveWorkerJobs;