import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { closeModal, openModal } from '../store/slices/modal';

import cookiesHelper from '../helpers/cookies';

import useRefreshAuthToken from '../hooks/auth/useRefreshAuthToken';

const SessionTimeoutProvider = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const timeOutRef = useRef<NodeJS.Timeout>();

    const [globalToken, setGlobalToken] = useState<string | null>(cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string));

    const { mutate: refreshAuthToken } = useRefreshAuthToken();


    const openTimeoutModal = () => {
        if (!globalToken) return;

        dispatch(openModal({
            name: 'TIMEOUT_MODAL',
        }))
    };

    const handleTokenRefreshOnAction = () => {
        const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);

        if (!token) return;
        if (token !== globalToken)
            setGlobalToken(token);

        const tokenData = JSON.parse(atob(token.split('.')[1]));

        const tokenExpiration = tokenData.exp;
        const currentTime = new Date().getTime() / 1000;

        if (tokenExpiration - currentTime <= 4 * 60) {
            refreshAuthToken({
                setIsProcessing: () => { },
                onClose: () => {
                    dispatch(closeModal('TIMEOUT_MODAL'));
                },
                setToken: setGlobalToken,
            });
        }
    };

    useEffect(() => {
        const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
        setGlobalToken(token);

        if (location.pathname.includes('/auth')) return;

        const handleWindowEvents = () => {
            clearTimeout(timeOutRef.current);

            handleTokenRefreshOnAction();

            timeOutRef.current = setTimeout(() => {
                openTimeoutModal();
            }, 55 * 60 * 1000);
        };

        window.addEventListener('click', handleWindowEvents);
        window.addEventListener('mousemove', handleWindowEvents);
        window.addEventListener('keydown', handleWindowEvents);
        window.addEventListener('scroll', handleWindowEvents);

        handleWindowEvents();

        return () => {
            window.removeEventListener('click', handleWindowEvents);
            window.removeEventListener('mousemove', handleWindowEvents);
            window.removeEventListener('keydown', handleWindowEvents);
            window.removeEventListener('scroll', handleWindowEvents);
        };
    }, [location.pathname, globalToken]); // eslint-disable-line

    return <>{children}</>;
}

export default SessionTimeoutProvider;