import { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import moment from 'moment';

import { closeModal, openModal } from '../../../store/slices/modal';

import useParseDocument from '../../../hooks/documents/useParseDocument';
import useGetPreSignedUrl from '../../../hooks/documents/useGetPreSignedUrl';
import useDeleteDocument from '../../../hooks/documents/useDeleteDocument';
import useGetDocumentExpiryNotification from '../../../hooks/expiry_schedules/useGetDocumentExpiryNotification';
import useDeleteVisa from '../../../hooks/visas/useDeleteVisa';

import { IPassport, IVisa } from '../../../interfaces/entities'
import { IVisaData, VisaField } from '../../../interfaces/foreign_worker';
import { ISelectDropdownOption } from '../../../interfaces/formElements';
import { IGenericDocument } from '../../../interfaces/services';
import { TDocStatus, TVisaType } from '../../../interfaces/entities.types';

import { OPDocumentStatuses, OPVisaTypes } from '../../../constants/options';

import PreviewPanel from './PreviewPanel';
import UploadFileBtn from './UploadFileBtn';
import ImmigrationFormAction from './ImmigrationFormAction';
import { CalenderInput, SelectDropdown, TextInput } from '../../reusables/FormElements';

import { getDateString } from '../../../helpers/utility';

interface IVisaFormProps {
    index: number;
    visaData: IVisa | null;
    passportsData: IPassport[];
    linked: boolean;
    isLoading: boolean;
    hasUnsavedData: { [key: number]: boolean; };
    setVisaDatas: React.Dispatch<React.SetStateAction<(IVisa | null)[]>>;
    setIsGlobalLoading: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
    setHasUnsavedData: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
}

const VisaForm = (props: IVisaFormProps) => {
    const { worker_id } = useParams();
    const dispatch = useDispatch();

    const { index, visaData, passportsData, linked, isLoading, hasUnsavedData, setVisaDatas, setIsGlobalLoading, setHasUnsavedData } = props;

    const requiredFields: VisaField[] = ["passport", "visaNumber", "visaType", "status"];
    const selectFields: VisaField[] = ["passport", "visaType", "status"];

    const initialFormData: IVisaData = {
        id: null,
        document_id: { value: 0, error: "" },
        passport: { value: { value: '', label: '' }, error: "" },
        visaNumber: { value: '', error: "" },
        visaType: { value: { value: '', label: '' }, error: "" },
        submissionDate: { value: '', error: "" },
        issueDate: { value: '', error: "" },
        expiryDate: { value: '', error: "" },
        refusedDate: { value: '', error: "" },
        status: { value: { value: '', label: '' }, error: "" },
        document: null
    }

    const [formData, setFormData] = useState<IVisaData>(initialFormData);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFileProcessing, setIsFileProcessing] = useState(false);
    const [apiError, setApiError] = useState<string | null>(null);
    const [signedUrl, setSignedUrl] = useState<string | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [cancelParse, setCancelParse] = useState<CancelTokenSource | null>(null);

    const { mutate: parseDocument } = useParseDocument();
    const { mutate: getPreSignedUrl } = useGetPreSignedUrl();
    const { mutate: deleteDocument } = useDeleteDocument();
    const { mutate: getDocumentExpiryNotifications } = useGetDocumentExpiryNotification();
    const { mutate: deleteVisa } = useDeleteVisa();

    const getFormData = () => {
        if (visaData) {
            const visaDetail = visaData.visa_details[0];
            const passport = passportsData.find(passport => passport.id === visaData.passport_id);
            const visaType = OPVisaTypes.find(type => type.value === visaDetail.type);
            const submissionDate = visaDetail.submission_date ? getDateString(visaDetail.submission_date) : null;
            const issueDate = visaDetail.issued ? getDateString(visaDetail.issued) : null;
            const expiryDate = visaDetail.expire ? getDateString(visaDetail.expire) : null;
            const refusedDate = visaDetail.refused_date ? getDateString(visaDetail.refused_date) : null;
            const status = OPDocumentStatuses.find(status => status.value === visaDetail.status);

            return {
                id: visaData.id,
                document_id: { value: visaDetail.document_id, error: "" },
                passport: { value: { value: passport?.id?.toString() ?? '', label: passport?.passport_details[0]?.passport_number ?? '' }, error: "" },
                visaNumber: { value: visaDetail.visa_number ?? '', error: "" },
                visaType: { value: { value: visaType?.value ?? '', label: visaType?.label ?? '' }, error: "" },
                submissionDate: { value: submissionDate ?? '', error: "" },
                issueDate: { value: issueDate ?? '', error: "" },
                expiryDate: { value: expiryDate ?? '', error: "" },
                refusedDate: { value: refusedDate ?? '', error: "" },
                status: { value: { value: status?.value ?? '', label: status?.label ?? '' }, error: "" },
                document: visaDetail.document ?? null,
            };
        } else {
            return initialFormData;
        }
    }

    const hasGlobalUnsavedChanges = () => {
        return Object.entries(hasUnsavedData).some(([key, value]) => Number(key) !== index && value);
    }

    const onDiscardChanges = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (isDataChanged() && !hasGlobalUnsavedChanges())
            setFormData(getFormData());
    }

    const onChangeText = (name: VisaField, value: string) => {
        setFormData({ ...formData, [name]: { value, error: "" } });
    }

    const onChangeSelect = (name: VisaField, option: ISelectDropdownOption) => {
        setFormData({ ...formData, [name]: { value: option, error: "" } });
    }

    const isDataChanged = () => {
        const originalData = getFormData();

        return !isEqual(originalData, formData);
    }

    const isDisabled = (reason: string | null) => {
        const emptyFields = Object.keys(formData).some(field => {
            return requiredFields.some(field => {
                let value = "";
                if (selectFields.includes(field)) {
                    const dataValue = formData[field].value as ISelectDropdownOption;
                    value = dataValue.value;
                } else {
                    value = formData[field].value as string;
                }
                return !value?.trim()?.length;
            });
        });

        const commonDisabled = !worker_id || linked || isLoading || isProcessing || isFileProcessing || hasGlobalUnsavedChanges();

        if (reason === 'save') {
            return emptyFields || commonDisabled || !isDataChanged();
        }

        return commonDisabled;
    }

    const onDeleteVisa = async () => {
        if (!worker_id) return;
        if (formData.id) {
            deleteVisa({
                foreignWorkerId: Number(worker_id),
                payload: { visaId: Number(formData.id) },
                setIsProcessing,
                onSuccess: () => {
                    setIsGlobalLoading({ [index]: false });
                    setHasUnsavedData({ [index]: false });
                    setVisaDatas(prevData => prevData.filter((_, i) => i !== index));
                }
            });
        } else {
            setIsGlobalLoading({ [index]: false });
            setHasUnsavedData({ [index]: false });
            setVisaDatas(prevData => prevData.filter((_, i) => i !== index));
        }
    }

    const isOkToDelete = () => {
        return !linked && !hasGlobalUnsavedChanges() && (formData.passport.value.value?.length ||
            formData.visaNumber.value?.length ||
            formData.visaType.value.value?.length ||
            formData.submissionDate.value?.length ||
            formData.issueDate.value?.length ||
            formData.expiryDate.value?.length ||
            formData.status.value.value?.length);
    }

    const onConfirmDelete = (e: React.MouseEvent) => {
        e.preventDefault();

        if (isProcessing || !isOkToDelete()) return;

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: "Confirm delete?",
                content: "Do you want to delete this visa? This action is irreversible.",
                actionTxt: "Confirm",
                action: async () => {
                    await onDeleteVisa();
                    dispatch(closeModal('MESSAGE_MODAL'));
                },
            }
        }));
    }

    const onSaveData = () => {
        if (isDisabled('save')) return;

        setApiError(null);

        setFormData(prevData => {
            return Object.keys(prevData).reduce((acc, key) => {
                if (key === 'id') return { ...acc, id: prevData.id };
                if (key === 'document') return { ...acc, document: prevData.document };
                return { ...acc, [key]: { value: prevData[key as VisaField].value, error: "" } };
            }, {} as IVisaData);
        });

        setIsProcessing(true);

        const genericDocument: IGenericDocument = {
            foreign_worker_id: worker_id ? Number(worker_id) : 0,
            documentMetadata: [
                {
                    id: formData.id ?? 0,
                    type: 'visa',
                    doc_number: formData.visaNumber.value ?? '',
                    expiry_date: formData.expiryDate.value ? moment.utc(formData.expiryDate.value).toDate() : null,
                    issue_date: formData.issueDate.value ? moment.utc(formData.issueDate.value).toDate() : null,
                }
            ]
        }

        const payload = {
            id: formData.id,
            document_id: formData.document_id.value ? formData.document_id.value : null,
            passport_id: Number(formData.passport.value.value),
            visa_number: formData.visaNumber.value,
            type: formData.visaType.value.value as TVisaType,
            submission_date: formData.submissionDate.value?.length ? formData.submissionDate.value : null,
            issued: formData.status.value.value === 'approved' && formData.issueDate.value.length ? moment.utc(formData.issueDate.value).toDate() : null,
            expire: formData.status.value.value === 'approved' && formData.expiryDate.value.length ? moment.utc(formData.expiryDate.value).toDate() : null,
            refused_date: formData.status.value.value === 'refused' && formData.refusedDate.value.length ? moment.utc(formData.refusedDate.value).toDate() : null,
            status: formData.status.value.value as TDocStatus,
        };

        getDocumentExpiryNotifications({
            payload: genericDocument,
            isProcessing,
            setIsProcessing,
            documentType: 'visa',
            documentPayload: payload,
        });
    }

    const onGetPreSignedUrl = (isDownload = false) => {
        if (!formData.document?.id) return;

        getPreSignedUrl({
            workerId: worker_id ? Number(worker_id) : 0,
            documentId: formData.document.id ?? 0,
            documentType: 'visa',
            fileName: formData.document.original,
            expiresIn: 3600,
            setPresignedUrl: setSignedUrl,
            isDownload
        });
    }

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (linked || hasGlobalUnsavedChanges()) return;

        const file = e.target.files?.[0];
        e.target.value = '';

        if (file) {
            parseDocument({
                type: "visa",
                data: file,
                setIsProcessing: setIsFileProcessing,
                setApiError,
                docId: formData.id ?? 0,
                foreignWorkerId: Number(worker_id),
                passports: passportsData,
                lmias: null,
                permitTypes: null,
                setCancelToken: setCancelParse,
                setFormData,
                isDataChanged,
                setPresignedUrl: setSignedUrl,
            }, {
                onSuccess: () => {
                    setShowPreview(true);
                }
            });
        }
    }

    const onRemoveFile = () => {
        if (linked || hasGlobalUnsavedChanges()) return;

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: "Remove Upload?",
                content: "Do you want to remove this visa document? You cannot undo this action.",
                actionTxt: "Confirm",
                action: () => {
                    deleteDocument({
                        workerId: worker_id ? Number(worker_id) : 0,
                        documentId: formData.document_id.value ?? 0,
                        type: 'visa',
                    }, {
                        onSuccess: () => {
                            setFormData(prevData => {
                                return {
                                    ...prevData,
                                    document_id: { value: 0, error: "" },
                                    document: null,
                                }
                            });

                            isDataChanged();
                        },
                    });

                    dispatch(closeModal('MESSAGE_MODAL'));
                },
            }
        }));
    };

    const onPreviewFile = () => {
        setShowPreview(true);
        onGetPreSignedUrl();
    };

    const onDownloadFile = async () => {
        onGetPreSignedUrl(true);
    };

    const onMinimizePreview = () => {
        setShowPreview(false);
        setSignedUrl(null);
    }

    useEffect(() => {
        setFormData(getFormData());
    }, [visaData, passportsData]); // eslint-disable-line

    useEffect(() => {
        if (isProcessing || isFileProcessing || isLoading) {
            setIsGlobalLoading({ [index]: true });
        } else {
            setIsGlobalLoading({ [index]: false });
        }
    }, [isFileProcessing, isLoading, isProcessing, index]); // eslint-disable-line

    useEffect(() => {
        if (isDataChanged()) {
            setHasUnsavedData({ [index]: true });
        } else {
            setHasUnsavedData({ [index]: false });
        }
    }, [formData, index]) // eslint-disable-line

    return (
        <>
            <div className='immployer__details_form_inputs__container__deletable_forms__with__preview'>
                <div className="immployer__details_form_inputs__container__deletable_forms">
                    <UploadFileBtn
                        linked={linked || hasGlobalUnsavedChanges()}
                        docType='Visa'
                        document={formData.document}
                        isProcessing={isFileProcessing}
                        apiError={apiError}
                        cancelParse={cancelParse}
                        onFileChange={onFileChange}
                        onRemoveFile={onRemoveFile}
                        onPreviewFile={onPreviewFile}
                    />

                    <div className="immployer__details_form_inputs">
                        <SelectDropdown
                            name="passport"
                            label="Linked Passport"
                            required={true}
                            placeholder="-- select passport --"
                            value={formData.passport.value}
                            onChange={(option: ISelectDropdownOption) => onChangeSelect("passport", option)}
                            errorMsg={formData.passport.error}
                            options={!passportsData?.length ? [] : passportsData?.map(passport => ({ value: passport.id.toString(), label: passport.passport_details[0].passport_number }))}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || !passportsData?.length}
                        />
                        <TextInput
                            name="visaNumber"
                            type="text"
                            label="Visa/ETA Number"
                            required={true}
                            placeholder="Enter visa number"
                            pattern_mask="doc_number"
                            value={formData.visaNumber.value}
                            onChange={(value: string) => onChangeText("visaNumber", value)}
                            errorMsg={formData.visaNumber.error}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <SelectDropdown
                            name="visaType"
                            label="Visa/ETA Type"
                            placeholder="-- select visa type --"
                            value={formData.visaType.value}
                            required={true}
                            onChange={(option: ISelectDropdownOption) => onChangeSelect("visaType", option)}
                            errorMsg={formData.visaType.error}
                            options={OPVisaTypes}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <SelectDropdown
                            name="status"
                            label="Visa/ETA Status"
                            placeholder="-- select status --"
                            value={formData.status.value}
                            required={true}
                            onChange={(option: ISelectDropdownOption) => onChangeSelect("status", option)}
                            errorMsg={formData.status.error}
                            options={OPDocumentStatuses}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <CalenderInput
                            name="submissionDate"
                            label="Submission Date"
                            errorMsg={formData.submissionDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("submissionDate", value)}
                            value={formData.submissionDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || formData.status.value.value === 'approved'}
                        />
                        <CalenderInput
                            name="refusedDate"
                            label="Decision Date"
                            errorMsg={formData.refusedDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("refusedDate", value)}
                            value={formData.refusedDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || formData.status.value.value !== 'refused'}
                        />
                        <CalenderInput
                            name="issueDate"
                            label="Issue Date"
                            errorMsg={formData.issueDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("issueDate", value)}
                            value={formData.issueDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || formData.status.value.value !== 'approved'}
                        />
                        <CalenderInput
                            name="expiryDate"
                            label="Expiry Date"
                            errorMsg={formData.expiryDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("expiryDate", value)}
                            value={formData.expiryDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || formData.status.value.value !== 'approved'}
                        />
                    </div>
                </div>
                {formData.document && signedUrl && showPreview &&
                    <PreviewPanel
                        linked={linked || hasGlobalUnsavedChanges()}
                        fileName={formData.document.original}
                        src={signedUrl}
                        onDownloadFile={onDownloadFile}
                        onRemoveFile={onRemoveFile}
                        onMinimizePreview={onMinimizePreview}
                    />
                }
            </div>
            <ImmigrationFormAction
                docType="Visa"
                isProcessing={isProcessing}
                isDataChanged={isDataChanged || hasGlobalUnsavedChanges()}
                onDiscardChanges={onDiscardChanges}
                isDisabled={isDisabled}
                onConfirmDelete={onConfirmDelete}
                onSaveData={onSaveData}
                isOkToDelete={isOkToDelete}
            />
        </>
    )
};

export default VisaForm;