import { useQuery } from "@tanstack/react-query";

import { getOrganizationStatus } from "../../services/organizations";

import { IOrganizationStatus } from "../../interfaces/services";

const useGetOrganizationStatus = () => {

    const queryFn = async (): Promise<IOrganizationStatus> => {
        return await getOrganizationStatus();
    };

    return useQuery({
        queryKey: ['organization_status'],
        queryFn,
        retry: 0,
    });
};

export default useGetOrganizationStatus;