import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteVisa } from "../../services/visas";

import useToast from "../useToast";

import { IVisa } from "../../interfaces/entities";
import { IWorkerImmigrationDocs } from "../../interfaces/services";

export interface IPayload {
    foreignWorkerId: number;
    payload: {
        visaId: number;
    };
    setIsProcessing: Function;
    onSuccess: (visas: IVisa[]) => void;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useDeleteVisa = () => {
    const queryClient = useQueryClient();

    const { addToast } = useToast();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await deleteVisa(data.payload.visaId);
    };

    return useMutation({
        mutationFn,
        onSuccess: async (response: IResponse, variables: IPayload) => {
            if (response) {
                addToast({
                    type: 'success',
                    title: 'Visa deleted successfully.',
                });

                await queryClient.invalidateQueries({ queryKey: ['foreign_worker_immigration_docs'] });
                const newData: IWorkerImmigrationDocs | undefined = queryClient.getQueryData(['foreign_worker_immigration_docs', variables.foreignWorkerId]);
                variables.onSuccess(newData?.visas ?? []);
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useDeleteVisa;