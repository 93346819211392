import React from "react";

import useGetExpiringPermits from "../../hooks/permits/useGetExpiringPermits";

const ExpiringPermits = () => {

    const { data: counts, isLoading: isExpiringPermitsLoading } = useGetExpiringPermits();

    return (
        <div className="immployer__expiring_permits_view">
            <div className="immployer__expiring_permits__container">
                <table className="immployer__expiring_permits_table">
                    <thead>
                        <tr>
                            <th>Permit Deadlines</th>
                            <th className="text_right color_primary">Total Permit Holders</th>
                            <th className="wt_500"><span>{counts?.total ?? 0}</span></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isExpiringPermitsLoading
                                ? <tr>
                                    <td colSpan={3}>
                                        <div className="display_flex flex_dir_column display_flex_center">
                                            <span className="wt_600 pb_10">Loading</span>
                                            <i className='immployer__list_loader' />
                                        </div>
                                    </td>
                                </tr>
                                : <>
                                    <tr>
                                        <td>Within 30 days</td>
                                        <td>
                                            <div className="immployer__progress_bar_base">
                                                {Number(counts?.total ?? 0) > 0 &&
                                                    <div className='immployer__progress_bar_one_month' style={{ width: `${Number(counts?.oneMonth ?? 0) / Number(counts?.total ?? 0) * 100}%` }} />
                                                }
                                            </div>
                                        </td>
                                        <td>{counts?.oneMonth ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Within 90 days</td>
                                        <td>
                                            <div className="immployer__progress_bar_base">
                                                {Number(counts?.total ?? 0) > 0 &&
                                                    <div className="immployer__progress_bar_three_months" style={{ width: `${Number(counts?.threeMonths ?? 0) / Number(counts?.total ?? 0) * 100}%` }} />
                                                }
                                            </div>
                                        </td>
                                        <td>{counts?.threeMonths ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Within 6 months</td>
                                        <td>
                                            <div className="immployer__progress_bar_base">
                                                {Number(counts?.total ?? 0) > 0 &&
                                                    <div className="immployer__progress_bar_six_months" style={{ width: `${Number(counts?.sixMonths ?? 0) / Number(counts?.total ?? 0) * 100}%` }} />
                                                }
                                            </div>
                                        </td>
                                        <td>{counts?.sixMonths ?? 0}</td>
                                    </tr>
                                    <tr>
                                        <td>Within 12 months</td>
                                        <td>
                                            <div className="immployer__progress_bar_base">
                                                {Number(counts?.total ?? 0) > 0 &&
                                                    <div className="immployer__progress_bar_other_months" style={{ width: `${Number(counts?.others ?? 0) / Number(counts?.total ?? 0) * 100}%` }} />
                                                }
                                            </div>
                                        </td>
                                        <td>{counts?.others ?? 0}</td>
                                    </tr>
                                </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ExpiringPermits;