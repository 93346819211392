import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { signIn } from "../../services/auth";

import { IEmailPassword } from "../../interfaces/services";

import cookiesHelper from "../../helpers/cookies";

import { setSessionData } from "../../store/slices/global";

export interface IPayload {
    creds: IEmailPassword;
    setApiError: Function;
    setIsProcessing: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
    data?: {
        auth_token: string;
        email: string;
        name: string;
        role: string;
    };
};

const useSignIn = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await signIn(data.creds);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response?.data?.auth_token) {
                cookiesHelper.setCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string, response.data.auth_token, (60 * 60 * 1000));
                dispatch(setSessionData({
                    email: response.data.email,
                    name: response.data.name,
                    role: response.data.role,
                    LMIAApplicable: true,
                }));
                navigate("/", { replace: true });
            }
        },
        onError: (error: IResponse, variables: IPayload) => {
            variables.setApiError(error.message);
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useSignIn;