import { useMutation, useQueryClient } from "@tanstack/react-query";

import { markNotificationAsRead } from "../../services/notifications";

interface IPayload {
    notificationId: number;
}
const useMarkNotificationRead = () => {
    const mutationFn = async (payload: IPayload) => {
        return await markNotificationAsRead(payload.notificationId);
    }

    const queryClient = useQueryClient();

    return useMutation({
        mutationFn,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['notifications_list'] });
        },
        onError: (error: any) => { },
        onSettled: () => { },
    });
};
export default useMarkNotificationRead;