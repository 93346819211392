import { useQuery } from "@tanstack/react-query";

import { getEmployerDetails } from "../../services/employers"

import { IEmployer } from "../../interfaces/entities";

const useGetEmployerDetails = (employerId: string | undefined) => {
    const employer_id = employerId ? parseInt(employerId) : 0;

    const queryFn = async (): Promise<IEmployer> => {
        return await getEmployerDetails(employer_id);
    };

    return useQuery({
        queryKey: ['employer_details', employer_id],
        queryFn,
        enabled: employer_id > 0,
        retry: 0,
    });
};

export default useGetEmployerDetails;