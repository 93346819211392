import { ICreateToast, IToast } from "../interfaces/toast";

import useValues from "./useValues";

const useToast = () => {
    const { toasts, setToasts } = useValues();

    const generateUEID = () => {
        const first = (Math.random() * 46656) | 0;
        const second = (Math.random() * 46656) | 0;
        const firstStr = ('000' + first.toString(36)).slice(-3);
        const secondStr = ('000' + second.toString(36)).slice(-3);

        return firstStr + secondStr;
    };

    const addToast = (toast: ICreateToast) => {
        setToasts(() => {
            const newToast: IToast = {
                ...toast,
                id: generateUEID()
            };

            return [...toasts, newToast];
        });
    };

    const removeToast = (id: string) => {
        setToasts(toasts.filter(toast => toast.id !== id));
    };

    return { addToast, removeToast };
};

export default useToast;