import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { IEmployerData } from "../../../interfaces/employer";

import EmployerDetailsFormView from "../../views/details_forms/Employer";

import useGetEmployerDetails from "../../../hooks/employers/useGetEmployerDetails";

import EmployerDetailsLayout from "../../layouts/details/Employer";

const EmployerDetailsPage = () => {
    const { employer_id } = useParams();

    const [formData, setFormData] = useState<IEmployerData>({
        id: null,
        legal_name: { value: "", error: "" },
        operating_name: { value: "", error: "" },
        type: { value: { label: "", value: "" }, error: "" },
        naics: { value: { label: "", value: "" }, error: "" },
        cra_number: { value: "", error: "" },
        tech_company: { value: { label: "", value: "" }, error: "" },
        non_canadian_emp: { value: "", error: "" },
        lmia_in_past: { value: { label: "", value: "" }, error: "" },
    });

    const { data: employer, isLoading, error } = useGetEmployerDetails(employer_id);

    return (
        <EmployerDetailsLayout
            operation='update'
            currentTab="General"
            title={employer?.legal_name ?? 'Employer Information'}
            error={!!error}
        >
            <EmployerDetailsFormView
                operation='update'
                isLoading={isLoading}
                employerData={employer ?? null}
                formData={formData}
                setFormData={setFormData}
            />
        </EmployerDetailsLayout>
    );
};

export default EmployerDetailsPage;