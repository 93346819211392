import React from "react";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";
import 'moment-timezone';

import WorkerDetailsLayout from "../../../layouts/details/Worker";

import useGetForeignWorkerDetails from "../../../../hooks/foreign_workers/useGetForeignWorkerDetails";
import useGetForeignWorkerInvitations from "../../../../hooks/invitations/useGetForeignWorkerInvitations";
import useGetForeignWorkerChangeLog from "../../../../hooks/foreign_workers/useGetForeignWorkerChangeLog";
import useGetWorkerJobs from "../../../../hooks/worker_jobs/useGetWorkerJobs";

import { getDateString } from "../../../../helpers/utility";

const ForeignWorkerChangeLogPage = () => {
    const { worker_id } = useParams();
    const location = useLocation();

    const { data: worker } = useGetForeignWorkerDetails(worker_id);
    const { data: invitations } = useGetForeignWorkerInvitations(worker_id);
    const { data: changeLog, isLoading, error } = useGetForeignWorkerChangeLog(worker_id);
    const { data: workerJobs } = useGetWorkerJobs(worker_id);

    return (
        <WorkerDetailsLayout
            goBackRoute={location.state?.goBackRoute ?? null}
            operation='update'
            currentTab="Change-Log"
            title={worker?.worker_details?.length ? `${worker.worker_details[0].first_name} ${worker.worker_details[0].last_name}` : 'Worker Change-Log'}
            error={!!error}
            invitations={invitations ?? []}
            linkedStatus={{
                isDataAvailable: !!worker?.worker_details[0],
                linked: !!worker?.worker_details[0]?.linked,
                linkedDate: worker?.worker_details[0]?.date_linked ?? null,
                unlinkedDate: worker?.worker_details[0]?.date_unlinked ?? null,
            }}
            isEmploymentAdded={!!workerJobs?.length}
        >
            <div className='immployer__change_log__page_view'>
                {
                    isLoading
                        ?
                        <div className="display_flex flex_dir_column display_flex_center">
                            <br /><br />
                            <span className="wt_600 pb_10">Loading</span>
                            <i className='immployer__list_loader' />
                        </div>
                        : !changeLog?.length
                            ? <div className="display_flex flex_dir_column display_flex_center">
                                <br /><br />
                                <span className="wt_600 pb_10">No record yet.</span>
                            </div>
                            : changeLog.map((cLog, index) =>
                                <div key={index} className="immployer__change_log__card">
                                    <div className="immployer__change_log__card__left">
                                        <p className="immployer__change_log__card__title">{cLog.title}</p>
                                        <p className="immployer__change_log__card__description">{cLog.description}</p>
                                        {cLog.changedFields?.length > 0 &&
                                            <ul className="immployer__change_log__card__changes">
                                                {cLog.changedFields.map((changeField, idx) => {
                                                    let field = changeField.field.replaceAll('_', ' ');
                                                    field = field.charAt(0).toUpperCase() + field.slice(1);

                                                    let oldValue = changeField.oldValue;
                                                    if (changeField.dataType === 'boolean') {
                                                        oldValue = !!oldValue ? 'true' : 'false';
                                                    } else if (changeField.dataType === 'date') {
                                                        if (!!oldValue) {
                                                            oldValue = getDateString(oldValue as string, 'MMM DD-YYYY');
                                                        } else {
                                                            oldValue = null;
                                                        }
                                                    } else {
                                                        oldValue = oldValue as string;
                                                    };

                                                    let newValue = changeField.newValue;
                                                    if (changeField.dataType === 'boolean') {
                                                        newValue = !!newValue ? 'true' : 'false';
                                                    } else if (changeField.dataType === 'date') {
                                                        if (!!newValue) {
                                                            newValue = getDateString(newValue as string, 'MMM DD-YYYY');
                                                        } else {
                                                            newValue = null;
                                                        }
                                                    } else {
                                                        newValue = newValue as string;
                                                    };

                                                    return (
                                                        <li key={idx}>
                                                            {
                                                                oldValue === null && !!newValue
                                                                    ? <span>{field} ({newValue}) is added.</span>
                                                                    : newValue === null && !!oldValue
                                                                        ? <span>{field} ({oldValue}) is removed.</span>
                                                                        : <span>{field} is updated from {oldValue} to {newValue}.</span>
                                                            }
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        }
                                    </div>
                                    <div className="immployer__change_log__card__right">
                                        <p className="immployer__change_log__card__date">{moment.utc(cLog.effective_date).tz('America/New_York').format('MMM-DD-YYYY, H:MM a z')}</p>
                                    </div>
                                </div>
                            )
                }
            </div>
        </WorkerDetailsLayout >
    );
};

export default ForeignWorkerChangeLogPage;