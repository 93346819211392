import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { ISaveEmployer } from "../../interfaces/services";
import { IEmployer } from "../../interfaces/entities";

import useToast from "../useToast";

import { saveEmployer } from "../../services/employers";

export interface IPayload {
    payload: ISaveEmployer;
    operation: string;
    setIsProcessing: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
    data: IEmployer
};

const useSaveEmployer = () => {
    const navigate = useNavigate();
    // const queryClient = useQueryClient();

    const { addToast } = useToast();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await saveEmployer(data.payload);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response?.statusCode === 200) {
                addToast({
                    type: 'success',
                    title: 'Employer details saved successfully.',
                });

                if (variables.operation === 'create') {
                    setTimeout(() => {
                        navigate(`/employers/${response?.data.id}/locations`);
                        // navigate(`/employers`);
                    }, 500);
                } else {
                    // queryClient.invalidateQueries({ queryKey: ['employer_details'] });
                    setTimeout(() => {
                        navigate(`/employers`);
                    }, 500);
                };
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useSaveEmployer;