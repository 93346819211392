import React from "react";
import { useLocation, useParams } from "react-router-dom";

import InvitationsListTable from "../../../sub_components/tables/InvitationsList";
import WorkerDetailsLayout from "../../../layouts/details/Worker";

import useGetForeignWorkerDetails from "../../../../hooks/foreign_workers/useGetForeignWorkerDetails";
import useGetForeignWorkerInvitations from "../../../../hooks/invitations/useGetForeignWorkerInvitations";
import useGetWorkerJobs from "../../../../hooks/worker_jobs/useGetWorkerJobs";

const ForeignWorkerInvitationsPage = () => {
    const { worker_id } = useParams();
    const location = useLocation();

    const { data: worker } = useGetForeignWorkerDetails(worker_id);
    const { data: invitations, isLoading, error } = useGetForeignWorkerInvitations(worker_id);
    const { data: workerJobs } = useGetWorkerJobs(worker_id);

    return (
        <WorkerDetailsLayout
            goBackRoute={location.state?.goBackRoute ?? null}
            operation='update'
            currentTab="Invitations"
            title={worker?.worker_details?.length ? `${worker.worker_details[0].first_name} ${worker.worker_details[0].last_name}` : 'Worker Invitations'}
            error={!!error}
            invitations={invitations ?? []}
            linkedStatus={{
                isDataAvailable: !!worker?.worker_details[0],
                linked: !!worker?.worker_details[0]?.linked,
                linkedDate: worker?.worker_details[0]?.date_linked ?? null,
                unlinkedDate: worker?.worker_details[0]?.date_unlinked ?? null,
            }}
            isEmploymentAdded={!!workerJobs?.length}
        >
            <div className='immployer__foreign_worker_invitations__page_view'>
                <InvitationsListTable
                    isLoading={isLoading}
                    invitations={invitations ?? []}
                />
            </div>
        </WorkerDetailsLayout>
    );
};

export default ForeignWorkerInvitationsPage;