import { useQuery } from "@tanstack/react-query";

import { getNotifications } from "../../services/notifications";

import { INotification } from "../../interfaces/entities";

const useGetNotifications = (requestor: string, requestorId: string | undefined) => {
    const requestor_id = requestorId ? parseInt(requestorId) : 0;
    const enabled = !!requestor && (requestor === 'organization' || requestor_id > 0);

    const queryFn = async (): Promise<INotification[]> => {
        return await getNotifications(requestor, requestor_id);
    };

    return useQuery({
        queryKey: ['notifications_list', requestor, requestorId],
        queryFn,
        enabled,
        retry: 0,
    });
};

export default useGetNotifications;