import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { ILMIAType } from "../interfaces/entities";

export const getLMIATypes = async (): Promise<ILMIAType[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`lmia-types`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    }
};