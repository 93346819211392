import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { imageAssets, logoAssets } from "../../constants/assets";

import SignInView from "../views/auth/SignIn";
// import SignUpView from "../views/auth/SignUp";
import ForgotPasswordView from "../views/auth/ForgotPassword";
import ResetPasswordView from "../views/auth/ResetPassword";
// import ActivateAccountView from "../views/auth/ActivateAccount";

const AuthPage = () => {
    const { view } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (
            // view !== "sign-up" &&
            // view !== "activate-account" &&
            view !== "sign-in" &&
            view !== "forgot-password" &&
            view !== "reset-password" &&
            view !== "set-password"
        ) {
            navigate("/auth/sign-in");
        };

        // eslint-disable-next-line
    }, [view]);

    return (
        <div className="immployer__auth__page_view">
            <div className="immployer__auth_bg__section">
                <img src={logoAssets.Logo} alt="" className="immployer__auth_logo" />
                <img src={imageAssets.GlobalTalentStream} alt="" className="immployer__auth_bg1" />
                <img src={imageAssets.HappySearch} alt="" className="immployer__auth_bg2" />
                <img src={imageAssets.Chart} alt="" className="immployer__auth_bg3" />
            </div>
            <div className="immployer__auth_forms__section">
                {
                    view === "sign-in"
                        ? <SignInView />
                        // : view === "sign-up"
                        //     ? <SignUpView />
                        // : view === "activate-account"
                        //     ? <ActivateAccountView />
                        : view === "forgot-password"
                            ? <ForgotPasswordView />
                            : view === "reset-password" || view === "set-password"
                                ? <ResetPasswordView />
                                : null
                }
            </div>
        </div>
    );
};

export default AuthPage;