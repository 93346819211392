import React from 'react';

import MainLayout from '../layouts/Main';

const ComingSoonPage = () => {
    return (
        <MainLayout>
            <div className="immployer__coming_soon__page_view">
                <br /><br />
                <br /><br />
                <br /><br />
                <h2><strong>Coming Soon!</strong></h2>
                <br /><br />
                <h4>We are working on this page. Please check back later!</h4>
            </div>
        </MainLayout>
    );
};

export default ComingSoonPage;