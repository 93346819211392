import { Routes, Route, Navigate } from 'react-router-dom';

import AuthRoutes from './AuthRoutes';
import PrivateRoutes from './PrivateRoutes';

import Error from '../components/pages/Error';
import ComingSoon from '../components/pages/ComingSoon';

import Auth from '../components/pages/Auth';
import Home from '../components/pages/Home';
import Employers from '../components/pages/employer/Index';
import AddEmployer from '../components/pages/employer/Add';
import EmployerDetails from '../components/pages/employer/Details';
import Locations from '../components/pages/employer/location/Index';
import AddLocation from '../components/pages/employer/location/Add';
import LocationDetails from '../components/pages/employer/location/Details';
import LMIAs from '../components/pages/employer/lmia/Index';
import AddLMIA from '../components/pages/employer/lmia/Add';
import LMIADetails from '../components/pages/employer/lmia/Details';
import EmployerForeignWorkers from '../components/pages/employer/foreign_worker/Index';
import ForeignWorkers from '../components/pages/foreign_worker/Index';
import AddForeignWorker from '../components/pages/foreign_worker/Add';
import ForeignWorkerPersonalDetails from '../components/pages/foreign_worker/details/Personal';
import ForeignWorkerEmploymentDetails from '../components/pages/foreign_worker/details/Employment';
import ForeignWorkerImmigrationDetails from '../components/pages/foreign_worker/details/Immigration';
import ForeignWorkerInvitations from '../components/pages/foreign_worker/details/Invitations';
import ForeignWorkerNotifications from '../components/pages/foreign_worker/details/Notifications';
import EmployerNotifications from '../components/pages/employer/notifications/Index';
import ForeignWorkerChangeLog from '../components/pages/foreign_worker/details/ChangeLog';
import CreateAssessment from '../components/pages/assessment/Create';
import Assessments from '../components/pages/assessment/Index';
import AssessmentDetails from '../components/pages/assessment/Details';
import ImmigrationRecommendations from '../components/pages/assessment/ImmigrationRecommendations';
// import PartnerRecommendations from '../components/pages/assessment/PartnerRecommendations';

const NavigationRoutes = () => {
    return (
        <Routes>
            <Route element={<AuthRoutes />}>
                <Route path='/auth' element={<Navigate to='/auth/sign-in' replace />} />
                <Route path='/auth/:view' element={<Auth />} />
            </Route>

            <Route element={<PrivateRoutes />}>
                <Route path='/' element={<Home />} />
                <Route path='/employers' element={<Employers />} />
                <Route path='/employers/new' element={<AddEmployer />} />
                <Route path='/employers/:employer_id/details' element={<EmployerDetails />} />

                <Route path='/employers/:employer_id/locations' element={<Locations />} />
                <Route path='/employers/:employer_id/locations/new' element={<AddLocation />} />
                <Route path='/employers/:employer_id/locations/:location_id/details' element={<LocationDetails />} />

                <Route path='/employers/:employer_id/lmias' element={<LMIAs />} />
                <Route path='/employers/:employer_id/lmias/new' element={<AddLMIA />} />
                <Route path='/employers/:employer_id/lmias/:lmia_id/details' element={<LMIADetails />} />

                <Route path='/employers/:employer_id/notifications' element={<EmployerNotifications />} />

                <Route path='/employers/:employer_id/foreign-workers' element={<EmployerForeignWorkers />} />
                <Route path='/foreign-workers' element={<ForeignWorkers />} />
                <Route path='/foreign-workers/new' element={<AddForeignWorker />} />
                <Route path='/foreign-workers/:worker_id/personal' element={<ForeignWorkerPersonalDetails />} />
                <Route path='/foreign-workers/:worker_id/employment' element={<ForeignWorkerEmploymentDetails />} />
                <Route path='/foreign-workers/:worker_id/immigration' element={<ForeignWorkerImmigrationDetails />} />
                <Route path='/foreign-workers/:worker_id/invitations' element={<ForeignWorkerInvitations />} />
                <Route path='/foreign-workers/:worker_id/notifications' element={<ForeignWorkerNotifications />} />
                <Route path='/foreign-workers/:worker_id/change-log' element={<ForeignWorkerChangeLog />} />

                <Route path='/assessments' element={process.env.REACT_APP_APP_ENV === 'PRODUCTION' ? <ComingSoon /> : <Assessments />} />
                <Route path='/assessments/new' element={process.env.REACT_APP_APP_ENV === 'PRODUCTION' ? <ComingSoon /> : <CreateAssessment />} />
                <Route path='/assessments/:assessment_id/details' element={process.env.REACT_APP_APP_ENV === 'PRODUCTION' ? <ComingSoon /> : <AssessmentDetails />} />
                <Route path='/assessments/:assessment_id/immigration-recommendations' element={process.env.REACT_APP_APP_ENV === 'PRODUCTION' ? <ComingSoon /> : <ImmigrationRecommendations />} />
                {/* <Route path='/assessments/:assessment_id/partner-recommendations' element={<PartnerRecommendations />} /> */}

                <Route path='/documents' element={<ComingSoon />} />
                <Route path='/hiring' element={<ComingSoon />} />
                <Route path='/help' element={<ComingSoon />} />
            </Route>

            <Route path="*" element={<Error />} />
        </Routes>
    );
};

export default NavigationRoutes;