import React from 'react'
import { FaRegWindowMinimize } from "react-icons/fa";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { iconAssets } from '../../../constants/assets';

interface IPreviewPanelProps {
    linked: boolean;
    fileName: string;
    src: string;
    onDownloadFile: () => void;
    onRemoveFile: () => void;
    onMinimizePreview: () => void;
}

const PreviewPanel = (props: IPreviewPanelProps) => {
    let { linked, fileName, src, onDownloadFile, onRemoveFile, onMinimizePreview } = props;

    if (fileName?.trim()?.length)
        fileName = fileName.split('_')[1].split('_')[0];

    return (
        <div className='immployer__details_form_inputs__container__preview_panel'>
            <div className='preview__file_details__container'>
                <h5 className='preview__file_name'>{fileName}</h5>

                <div className='preview__file_actions'>
                    <button className='preview__file_minimize_btn' onClick={onMinimizePreview}>
                        <FaRegWindowMinimize />
                    </button>
                    <button className='preview__file_download_btn' onClick={onDownloadFile}>
                        <img src={iconAssets.Download.grey} alt='' onMouseOver={(e) => e.currentTarget.src = iconAssets.Download.primary} onMouseOut={(e) => e.currentTarget.src = iconAssets.Download.grey} />
                    </button>
                    {!linked && <button className='preview__file_delete_btn' onClick={onRemoveFile}>
                        <img src={iconAssets.Bin.grey} alt='' onMouseOver={(e) => e.currentTarget.src = iconAssets.Bin.error} onMouseOut={(e) => e.currentTarget.src = iconAssets.Bin.grey} />
                    </button>}
                </div>
            </div>

            {src &&
                <>
                    <TransformWrapper>
                        <span className=''>Scroll or expand to zoom</span>
                        <TransformComponent>
                            <img className='preview__file_image' src={src} alt={fileName} />
                        </TransformComponent>
                    </TransformWrapper>
                </>
            }
        </div>
    )
}

export default PreviewPanel;