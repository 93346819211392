import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { IPassword, IEmailPassword, ISignUpCreds } from "../interfaces/services";

export const signUp = async (payload: ISignUpCreds) => {
    try {
        const res = await api.post(`users/sign-up`, payload);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    }
};

export const sendActivationEmail = async (email: string) => {
    try {
        const res = await api.get(`users/send-activation-email/${email}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    }
};

export const activateAccount = async (token: string) => {
    try {
        const res = await api.patch(`users/activate-account/${token}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    }
};

export const sendPasswordResetEmail = async (email: string) => {
    try {
        const res = await api.get(`users/send-password-reset-email/${email}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    }
};

export const resetPassword = async (token: string, payload: IPassword) => {
    try {
        const res = await api.patch(`users/reset-password/${token}`, payload);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    }
};

export const signIn = async (payload: IEmailPassword) => {
    try {
        const res = await api.post(`users/sign-in`, payload);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    }
};

export const refreshAuthToken = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`users/refresh-token`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    }
};

export const signOut = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`users/sign-out`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    }
};