import { useMutation, useQueryClient } from "@tanstack/react-query";

import { saveVisa } from "../../services/visas";

import { ISaveVisa } from "../../interfaces/services";

import useToast from "../useToast";

export interface IPayload {
    payload: ISaveVisa;
    setIsProcessing: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useSaveVisa = () => {
    const queryClient = useQueryClient();

    const { addToast } = useToast();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await saveVisa(data.payload);
    };

    return useMutation({
        mutationFn,
        onSuccess: async (response: IResponse, variables: IPayload) => {
            if (response) {
                addToast({
                    type: 'success',
                    title: 'Visa record saved successfully.',
                });

                queryClient.invalidateQueries({ queryKey: ['foreign_worker_immigration_docs'] });
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useSaveVisa;