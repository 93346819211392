import { createSlice } from "@reduxjs/toolkit";
import { produce } from 'immer';

import { GlobalSliceState, SessionData, StoreState } from "../../interfaces/store";

const initialState: GlobalSliceState = {
    sessionData: null,
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        resetSession: (state) => {
            return produce(state, draft => {
                draft.sessionData = null;
            });
        },
        setSessionData: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = action.payload;
            });
        },
        setLMIAApplicable: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = { ...draft.sessionData, LMIAApplicable: action.payload } as SessionData;
            });
        },
    }
});

export const {
    resetSession,
    setSessionData,
    setLMIAApplicable,
} = globalSlice.actions;
export default globalSlice.reducer;

// Selectors
export const getSessionData = () => (state: StoreState) => state.global.sessionData;
export const getLMIAApplicable = () => (state: StoreState) => !!state.global.sessionData?.LMIAApplicable;