import React from "react";

import MainLayout from "../Main";

import { ILocationLayoutProps } from "../../../interfaces/layouts";

const LocationDetailsLayout = (props: ILocationLayoutProps) => {
    const { title, error, children } = props;

    return (
        <MainLayout>
            <div className="immployer__common_details__layout">
                <div className="immployer__form_details__head">
                    <h2 className="color_dark">{title}</h2>
                </div>
                <div className="immployer__common_details__tabs__container">
                    <div className="immployer__tabs">
                        <button className={`immployer__tab immployer__tab__active`}>
                            Location Details
                        </button>
                    </div>
                </div>
                <div className="immployer__common_details__content">
                    {
                        error
                            ? <div className="immployer__common_details__error_view">
                                <br /><br />
                                <h4 className="color_dark text_center">Error occured while fetching the data.</h4>
                                <h4 className="color_dark text_center">Please try again later.</h4>
                            </div>
                            : children
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default LocationDetailsLayout;