import { useQuery } from "@tanstack/react-query";

import { getLocationDetails } from "../../services/locations";

import { ILocation } from "../../interfaces/entities";

const useGetLocationDetails = (locationId: string | undefined) => {
    const location_id = locationId ? parseInt(locationId) : 0;

    const queryFn = async (): Promise<ILocation> => {
        return await getLocationDetails(location_id);
    };

    return useQuery({
        queryKey: ['location_details', location_id],
        queryFn,
        enabled: location_id > 0,
        retry: 0,
    });
};

export default useGetLocationDetails;