import React, { useState } from "react";

import EmployerDetailsLayout from "../../layouts/details/Employer";
import EmployerDetailsFormView from "../../views/details_forms/Employer";

import { IEmployerData } from "../../../interfaces/employer";

const AddEmployerPage = () => {
    const [formData, setFormData] = useState<IEmployerData>({
        id: null,
        legal_name: { value: "", error: "" },
        operating_name: { value: "", error: "" },
        type: { value: { label: "", value: "" }, error: "" },
        naics: { value: { label: "", value: "" }, error: "" },
        cra_number: { value: "", error: "" },
        tech_company: { value: { label: "", value: "" }, error: "" },
        non_canadian_emp: { value: "", error: "" },
        lmia_in_past: { value: { label: "", value: "" }, error: "" },
    });

    return (
        <EmployerDetailsLayout
            operation='create'
            currentTab="General"
            title='Add an Employer'
            error={false}
        >
            <EmployerDetailsFormView
                operation='create'
                isLoading={false}
                employerData={null}
                formData={formData}
                setFormData={setFormData}
            />
        </EmployerDetailsLayout>
    );
};

export default AddEmployerPage;