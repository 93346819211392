import React from "react";
import { useLocation, useParams } from "react-router-dom";

import WorkerDetailsLayout from "../../../layouts/details/Worker";
import WorkerEmploymentDetailsFormView from "../../../views/details_forms/foreign_worker/Employment";

import useGetForeignWorkerDetails from "../../../../hooks/foreign_workers/useGetForeignWorkerDetails";
import useGetWorkerJobs from "../../../../hooks/worker_jobs/useGetWorkerJobs";
import useGetForeignWorkerInvitations from "../../../../hooks/invitations/useGetForeignWorkerInvitations";

const ForeignWorkerEmploymentDetailsPage = () => {
    const { worker_id } = useParams();
    const location = useLocation();

    const { data: worker } = useGetForeignWorkerDetails(worker_id);
    const { data: workerJobs, isLoading, error } = useGetWorkerJobs(worker_id);
    const { data: invitations } = useGetForeignWorkerInvitations(worker_id);

    return (
        <WorkerDetailsLayout
            goBackRoute={location.state?.goBackRoute ?? null}
            operation='update'
            currentTab="Employment"
            title={worker?.worker_details?.length ? `${worker.worker_details[0].first_name} ${worker.worker_details[0].last_name}` : 'Worker Employement'}
            error={!!error}
            invitations={invitations ?? []}
            linkedStatus={{
                isDataAvailable: !!worker?.worker_details[0],
                linked: !!worker?.worker_details[0]?.linked,
                linkedDate: worker?.worker_details[0]?.date_linked ?? null,
                unlinkedDate: worker?.worker_details[0]?.date_unlinked ?? null,
            }}
            isEmploymentAdded={!!workerJobs?.length}
        >
            <WorkerEmploymentDetailsFormView
                goBackRoute={location.state?.goBackRoute ?? null}
                isLoading={isLoading}
                workerJobs={workerJobs ?? []}
            />
        </WorkerDetailsLayout>
    );
};

export default ForeignWorkerEmploymentDetailsPage;