import api from "."

import cookiesHelper from "../helpers/cookies";

import { IEmployer } from "../interfaces/entities";
import { ISortQuery, ISaveEmployer } from "../interfaces/services";

export const getEmployers = async (sortQuery: ISortQuery): Promise<IEmployer[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`

    try {
        const res = await api.get(`employers`, {
            params: { ...sortQuery }
        });

        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const saveEmployer = async (data: ISaveEmployer) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`

    try {
        const res = await api.post(`employers/user`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getEmployerDetails = async (employerId: number): Promise<IEmployer> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`

    try {
        const res = await api.get(`employers/details/${employerId}`);
        return res.data?.data ?? null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};