import React, { MouseEvent, ChangeEvent, useEffect, useState } from "react";
import { List, AutoSizer, ListRowRenderer } from 'react-virtualized';
import moment from "moment";
import { useDispatch } from "react-redux";

import { RiEyeFill, RiEyeOffFill } from "react-icons/ri";
import { BsXCircleFill } from "react-icons/bs";

import { FormElementWrapperProps, TextInputProps, SelectDropdownProps, ISelectDropdownOption, CalenderInputProps, SearchSelectProps } from "../../interfaces/formElements";
import OutsideAlerter from "../wrappers/OutsideAlerter";
import Calender from "./Calender";

import { iconAssets } from "../../constants/assets";

import { getCanadianDollarsString, getDateString, getElipsisString, getPhoneNumberString } from "../../helpers/utility";

import { closeModal, openModal } from "../../store/slices/modal";

const FormElementWrapper = (props: FormElementWrapperProps) => {
    const { className, length_flex = 1, children, label, label_info, required, tooltip, errorMsg, onElementFocus, onElementBlur } = props;

    return (
        <div
            className={`immployer__form_element_wrapper immployer__form_element_wrapper__flex_${length_flex} ${className}`}
            onFocus={() => onElementFocus && onElementFocus()}
            onBlur={() => onElementBlur && onElementBlur()}
        >
            {!!label &&
                <label>
                    <span>{label}</span>
                    {!!label_info && <span className="label_info">&nbsp;{label_info}</span>}
                    {!!required && <span className="color_error">&nbsp;*</span>}

                    {!!tooltip && <>&nbsp; &nbsp;{tooltip}</>}
                </label>
            }
            {children}
            {errorMsg && errorMsg?.length > 0 && <div className="error_text">{errorMsg}</div>}
        </div>
    );
};

export const TextInput = (props: TextInputProps) => {
    const { name, type, pattern_mask, max_length, tooltip, read_only, label, label_info, required, errorMsg, placeholder, onChange, value, length_flex = 1 } = props;

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPreSecVisible, setIsPreSecVisible] = useState(false);
    const [isPostSecVisible, setIsPostSecVisible] = useState(false);

    useEffect(() => {
        if (type === "password") {
            setIsPostSecVisible(true);
        };
    }, [type]);

    useEffect(() => {
        if (pattern_mask === "currency_cad") {
            setIsPreSecVisible(true);
        };
    }, [pattern_mask]);

    const onClickInputButton = () => {
        if (type === 'password') {
            setIsPasswordVisible(!isPasswordVisible);
        };
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (read_only) return;

        let value = e.target.value;

        if (pattern_mask === 'integer') {
            value = value.replace(/[^0-9]/g, '');
        } else if (pattern_mask === 'float') {
            value = value.replace(/[^0-9.]/g, '');
            value = value.replace(/\.(?=.*\.)/, '');
        } else if (pattern_mask === "currency_cad") {
            value = getCanadianDollarsString(value);
        } else if (pattern_mask === "phone_number") {
            value = getPhoneNumberString(value);
        } else if (pattern_mask === "doc_number") {
            value = value.replace(/[^a-zA-Z0-9]/g, '');
            value = value.toUpperCase();
        }

        if (max_length) {
            value = value.slice(0, max_length);
        }

        onChange(value);
    };

    return (
        <FormElementWrapper
            className={`immployer__text_input_wrapper`}
            label={label}
            label_info={label_info}
            required={required}
            errorMsg={errorMsg}
            tooltip={tooltip}
            length_flex={length_flex}
        >
            <div className={`input_group ${read_only ? 'input_group__readonly' : ''}`}>
                {isPreSecVisible &&
                    <div className="input_group__pre_sec">
                        {pattern_mask === "currency_cad" && <div className="color_dark">$</div>}
                    </div>
                }
                <input
                    name={name}
                    type={type === "password" && isPasswordVisible ? "text" : type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChangeInput}
                    readOnly={read_only}
                />
                {isPostSecVisible &&
                    <div className="input_group__post_sec">
                        {type === "password" &&
                            <button onClick={onClickInputButton} type="button">
                                {
                                    !isPasswordVisible
                                        ? <RiEyeOffFill />
                                        : <RiEyeFill className="color_primary" />
                                }
                            </button>
                        }
                    </div>
                }
            </div>
        </FormElementWrapper>
    );
};

export const SelectDropdown = (props: SelectDropdownProps) => {
    const { read_only, length_flex = 1, label, label_info, required, errorMsg, placeholder, onChange, value, options, addNewBtnData } = props;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState(false);

    const onElementFocus = () => {
        if (read_only) return;
        setFocused(true);
        setOpen(true);
    };

    const onElementBlur = () => {
        if (read_only) return;
        setFocused(false);
        setOpen(false);
    }

    const onToggleDropdown = () => {
        if (read_only || (!options.length && !addNewBtnData)) return;

        if (focused) {
            setFocused(false);
            return;
        }

        setOpen(!open);
    };

    const onChangeSelect = (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, option: ISelectDropdownOption) => {
        e.preventDefault();
        if (read_only) return;
        setOpen(false);
        onChange(option);
    };

    const onAddNew = (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        e.preventDefault();

        if (read_only || !addNewBtnData) return;

        setOpen(false);

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: `Add New ${addNewBtnData.name}`,
                content: `Are you sure you want to add a new ${addNewBtnData.name}? You will be redirected to the ${addNewBtnData.name}'s page. You will lose any unsaved changes.`,
                actionTxt: "Proceed",
                action: async () => {
                    dispatch(closeModal('MESSAGE_MODAL'));
                    addNewBtnData.action();
                }
            }
        }));
    };

    return (
        <FormElementWrapper
            className={`immployer__select_dropdown_wrapper`}
            label={label}
            label_info={label_info}
            required={required}
            errorMsg={errorMsg}
            length_flex={length_flex}
            onElementFocus={onElementFocus}
            onElementBlur={onElementBlur}
        >
            <OutsideAlerter callback={() => setOpen(false)} isActive={open}>
                <div className={`immployer__select_dropdown__container ${open ? 'immployer__select_dropdown__container__opened' : ''}`}>
                    <button
                        className={`immployer__select_dropdown__btn ${read_only ? 'immployer__select_dropdown__btn__readonly' : ''}`}
                        onClick={onToggleDropdown}
                    // onFocus={onElementFocus}
                    >
                        {
                            value.label?.length
                                ? <span className="color_dark">{getElipsisString(value.label, 23)}</span>
                                : <span className="color_grey">{placeholder?.length ? placeholder : '-- Select --'}</span>
                        }
                        <div className="icons_sec">
                            <img src={iconAssets.TriangleDown[read_only ? 'grey' : 'primary']} alt="" />
                            <img src={iconAssets.TriangleDown[read_only ? 'grey' : 'primary']} alt="" />
                        </div>
                    </button>
                    {open &&
                        <div className="immployer__ssd_dropdown__content">
                            {options.length === 0
                                ? <button>No records yet!</button>
                                : <>
                                    {(!!placeholder?.length && !required) &&
                                        <button onMouseDown={(e) => onChangeSelect(e, { value: "", label: "" })}>{placeholder}</button>
                                    }
                                    {options.map((option, index) =>
                                        <button
                                            key={index}
                                            className={option.readOnly ? 'immployer__ssd_dropdown__option__readonly' : ''}
                                            onMouseDown={(e) => option.readOnly ? null : onChangeSelect(e, option)}
                                        >
                                            {option.label}
                                        </button>
                                    )}
                                </>
                            }
                            {!!addNewBtnData &&
                                <button
                                    className="immployer__ssd_dropdown__option__add_new"
                                    onMouseDown={onAddNew}
                                >
                                    <img src={iconAssets.Plus.primary} alt="" />
                                    <span>{addNewBtnData.label}</span>
                                </button>
                            }
                        </div>
                    }
                </div>
            </OutsideAlerter>
        </FormElementWrapper>
    );
};

export const SearchSelect = (props: SearchSelectProps) => {
    const { read_only, length_flex = 1, label, label_info, required, errorMsg, placeholder, onChange, value, options } = props;

    const [open, setOpen] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState<ISelectDropdownOption[]>(options);
    const [search, setSearch] = useState("");

    useEffect(() => {
        setSearch(value.label);

        // eslint-disable-next-line
    }, [value]);

    const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
        if (read_only) return;
        setOpen(true);

        const val = e.target.value;
        setSearch(val);

        const filteredOptions = options.filter(option => option.label.toLowerCase().includes(val.toLowerCase()));
        setFilteredOptions(filteredOptions);
    };

    const onChangeSelect = (option: ISelectDropdownOption) => {
        if (read_only) return;

        setOpen(false);
        onChange(option);
    };

    const onBlurInput = () => {
        if (read_only) return;

        const option = options.find(option => option.label === search);
        if (option) {
            onChange(option);
        } else {
            onChange({ value: "", label: "" });
        };
    };

    const rowRenderer: ListRowRenderer = ({ index, style }) => {
        return (
            <div
                key={index}
                style={{
                    ...style,
                    whiteSpace: "pre",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%"
                }}
            >
                <button onClick={() => onChangeSelect(filteredOptions[index])}>
                    {filteredOptions[index].label}
                </button>
            </div>
        );
    };

    return (
        <FormElementWrapper
            className={`immployer__search_select_wrapper`}
            label={label}
            label_info={label_info}
            required={required}
            errorMsg={errorMsg}
            length_flex={length_flex}
        >
            <OutsideAlerter callback={() => setOpen(false)} isActive={open}>
                <div className={`immployer__search_select__container ${open ? 'immployer__search_select__container__opened' : ''}`}>
                    <div className={`immployer__search_select__input_group ${read_only ? 'immployer__search_select__input_group__readonly' : ''}`}>
                        <input
                            type="text"
                            className={`immployer__search_select__input`}
                            onChange={onChangeSearch}
                            onBlur={onBlurInput}
                            placeholder={placeholder}
                            value={search}
                            readOnly={read_only}
                        />
                        <img src={iconAssets.Search[read_only ? 'grey' : 'primary']} alt="" />
                    </div>
                    {open &&
                        <div className="immployer__ssd_dropdown__virtualized_content">
                            {
                                filteredOptions.length === 0
                                    ? <div><div><button>No records found!</button></div></div>
                                    : <AutoSizer disableWidth>
                                        {() => {
                                            return (
                                                <List
                                                    width={1}
                                                    height={filteredOptions.length > 10 ? 400 : filteredOptions.length * 40}
                                                    overscanRowsCount={2}
                                                    rowCount={filteredOptions.length}
                                                    rowHeight={40}
                                                    rowRenderer={rowRenderer}
                                                    containerStyle={{ width: "100%", maxWidth: "100%" }}
                                                    style={{ width: "100%" }}
                                                />
                                            );
                                        }}
                                    </AutoSizer>
                            }
                        </div>
                    }
                </div>
            </OutsideAlerter>
        </FormElementWrapper>
    );
};

export const CalenderInput = (props: CalenderInputProps) => {
    const { read_only, label, label_info, required, errorMsg, placeholder, onChange, value, format, tooltip } = props;

    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const onOpenCalender = () => {
        if (read_only) return;
        setOpen(true);
    };

    const onChangeCalender = (date: Date, keepOpen?: boolean) => {
        if (read_only) return;

        if (!keepOpen) setOpen(false);

        setInputValue(getDateString(date));
        onChange(getDateString(date));
    };

    const onClearCalender = (e: MouseEvent) => {
        if (read_only) return;
        setInputValue("");
        onChange("");

        e.stopPropagation();
    };

    const onBlurInput = () => {
        if (read_only) return;

        if (inputValue.length < 10) {
            if (value?.trim()?.length) {
                setInputValue(getDateString(value));
            } else {
                setInputValue("");
            };
        };
    };

    const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
        if (read_only) return;

        setOpen(true);

        let value = e.target.value;

        if (inputValue.length > value.length) {
            setInputValue(value);
            return;
        };

        value = value.replace(/[^0-9]/g, '');

        if (format === 'mm/dd/yyyy') {
            if (value.length >= 8) {
                value = value.slice(0, 8);
                value = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(4)}`;

                const date = moment.utc(value, 'MM/DD/YYYY', true);
                if (!date.isValid()) {
                    value = moment.utc().format('MM/DD/YYYY');
                    onChangeCalender(moment.utc().toDate(), true);
                } else {
                    if (parseInt(date.format('YYYY')) < 1960) {
                        value = '01/01/1960';
                        onChangeCalender(moment.utc(value, 'MM/DD/YYYY').toDate(), true);
                    } else if (parseInt(date.format('YYYY')) > 2100) {
                        value = '12/31/2100';
                        onChangeCalender(moment.utc(value, 'MM/DD/YYYY').toDate(), true);
                    } else {
                        onChangeCalender(date.toDate(), true);
                    };
                };
            } else {
                if (value.length === 1) {
                    if (parseInt(value) > 1) {
                        value = '0' + value;
                        value += '/';
                    };
                } else if (value.length === 2) {
                    if (parseInt(value) === 0) {
                        value = '01'
                    } else if (parseInt(value) > 12) {
                        value = '12'
                    };
                    value += '/';
                } else if (value.length === 3) {
                    const lastDigit = parseInt(value[2]);
                    const month = value.slice(0, 2);
                    const daysInMonth = moment.utc(`${month}/01/2024`, 'MM/DD/YYYY', true).daysInMonth();

                    if (lastDigit > parseInt(daysInMonth.toString()[0])) {
                        value = value.slice(0, 2) + '/0' + lastDigit + '/';
                    } else {
                        value = value.slice(0, 2) + '/' + lastDigit;
                    };
                } else if (value.length === 4) {
                    const month = value.slice(0, 2);
                    const daysInMonth = moment.utc(`${month}/01/2024`, 'MM/DD/YYYY', true).daysInMonth();
                    const date = value.slice(2, 4);

                    if (parseInt(date) > daysInMonth) {
                        value = `${month}/${daysInMonth}/`;
                    } else {
                        value = `${month}/${date}/`;
                    };
                } else if (value.length >= 5 && value.length <= 7) {
                    const month = value.slice(0, 2);
                    const date = value.slice(2, 4);
                    const yearStr = value.slice(4);

                    value = `${month}/${date}/${yearStr}`;
                };
            };
        };

        setInputValue(value);
    };

    const onElementFocus = () => {
        if (read_only) return;
        setOpen(true);
    };

    const onElementBlur = () => {
        if (read_only) return;
        setOpen(false);
    }

    return (
        <FormElementWrapper
            className={`immployer__calender_input_wrapper`}
            label={label}
            label_info={label_info}
            required={required}
            errorMsg={errorMsg}
            tooltip={tooltip}
            onElementFocus={onElementFocus}
            onElementBlur={onElementBlur}
        >
            <OutsideAlerter callback={() => setOpen(false)} isActive={open}>
                <div
                    className={`immployer__calender_input__container ${open ? 'immployer__calender_input__container__opened' : ''}`}
                    onFocus={onElementFocus}>
                    <div
                        className={`immployer__calender_input__cont ${read_only ? 'immployer__calender_input__cont__readonly' : ''}`}
                        onClick={onOpenCalender}
                    >
                        <input
                            type="text"
                            onChange={onChangeInput}
                            onBlur={onBlurInput}
                            placeholder={placeholder}
                            value={inputValue}
                            readOnly={read_only}
                        />
                        <span className="immployer__calender_input__cont__span">
                            {value?.trim()?.length > 0 && !read_only && <span onClick={onClearCalender}><BsXCircleFill /></span>}
                            <img src={iconAssets.Calender[read_only ? 'grey' : 'primary']} alt="" />
                        </span>
                    </div>
                    {open &&
                        <div className="immployer__calender_input__content">
                            <Calender
                                selected={value?.trim()?.length ? moment.utc(value, 'MM/DD/YYYY', true).toDate() : moment.utc().toDate()}
                                setSelected={onChangeCalender}
                            />
                        </div>
                    }
                </div>
            </OutsideAlerter>
        </FormElementWrapper>
    );
};