import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { signOut } from "../../services/auth";

import cookiesHelper from "../../helpers/cookies";

import { resetSession } from "../../store/slices/global";

const useSignOut = () => {
    const dispatch = useDispatch();

    const mutationFn = async () => {
        return await signOut();
    };

    return useMutation({
        mutationFn,
        onSettled: () => {
            cookiesHelper.removeCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
            dispatch(resetSession());
            window.location.href = "/auth/sign-in";
        },
    });
};

export default useSignOut;