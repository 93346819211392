import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { ISaveVisa } from "../interfaces/services";

export const saveVisas = async (data: ISaveVisa[]) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`visas`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const saveVisa = async (data: ISaveVisa) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`visas`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const deleteVisa = async (id: number) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.patch(`visas/delete/${id}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};