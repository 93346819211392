import { useQuery } from "@tanstack/react-query";

import { getPermitTypes } from "../../services/permit_types";

import { IPermitType } from "../../interfaces/entities";

const useGetPermitTypes = () => {

    const queryFn = async (): Promise<IPermitType[]> => {
        return await getPermitTypes();
    };

    return useQuery({
        queryKey: ['permit_types'],
        queryFn,
        retry: 0,
    });
};

export default useGetPermitTypes;