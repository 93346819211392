import React from "react";
import { Link } from "react-router-dom";

import { iconAssets, logoAssets } from "../../constants/assets";

// import { SearchInput } from "../reusables/Inputs";

const Header = () => {

    return (
        <div className="immployer__header">
            <div className="immployer__header__logo_sec">
                <Link to="/" className='immployer__header__logo'>
                    <img src={logoAssets.Logo} alt="" className='fit_asset_max' />
                </Link>
            </div>
            <div className="immployer__header__search_sec">
                {/* <SearchInput /> */}
            </div>
            <div className="immployer__header__menu_sec">
                <button className="header_icon_btn">
                    <img src={iconAssets.Bell.primary} alt="" />
                </button>
                <button className="header_icon_btn">
                    <img src={iconAssets.InfoAsk.primary} alt="" />
                </button>
            </div>
        </div>
    );
};

export default Header;