import React, { useEffect } from 'react';

import { BsCheckCircleFill, BsExclamationCircleFill, BsInfoCircleFill, BsX } from 'react-icons/bs';

import { IToast } from '../../interfaces/toast';

import useValues from '../../hooks/useValues';
import useToast from '../../hooks/useToast';

const Toast = (props: { toast: IToast }) => {
    const { id, type, title, message, timeout } = props.toast;

    const { removeToast } = useToast();

    useEffect(() => {
        const timeoutTime = timeout ?? 4000;

        const timer = setTimeout(() => {
            removeToast(id);
        }, timeoutTime);

        return () => clearTimeout(timer);

        // eslint-disable-next-line
    }, [id]);

    return (
        <div className={`immployer__toast immployer__toast__${type}`}>
            <div className="immployer__toast_icon">
                {type === 'error' && <BsExclamationCircleFill className='color_error' />}
                {type === 'success' && <BsCheckCircleFill className='color_success' />}
                {type === 'info' && <BsInfoCircleFill className='color_primary' />}
            </div>
            <div className='immployer__toast_content'>
                <h5 className='color_dark'>{title}</h5>
                {!!message && <p className='mice'>{message}</p>}
            </div>
            {<button className='immployer__toast__close_btn' onClick={() => removeToast(id)}><BsX /></button>}
        </div>
    );
};

export const Toasts = () => {
    const { toasts } = useValues();

    return (
        <div className='immployer__toasts_container'>
            {toasts.map(toast =>
                <Toast key={toast.id} toast={toast} />
            )}
        </div>
    );
};

export default Toasts;