import React, { useState } from "react";
import { Link } from "react-router-dom";

import { TextInput } from "../../reusables/FormElements";

import validationHelper from "../../../helpers/validators";

import useSignIn from "../../../hooks/auth/useSignIn";

const SignInView = () => {
    const [email, setEmail] = useState({ value: "", error: "" });
    const [password, setPassword] = useState({ value: "", error: "" });
    const [isProcessing, setIsProcessing] = useState(false);
    const [apiError, setApiError] = useState("");

    const { mutate: signIn } = useSignIn();

    const onSignIn = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (isProcessing) return;

        setEmail({ ...email, error: "" });
        setPassword({ ...password, error: "" });
        setApiError("");

        const emailValError = validationHelper.validateInput("email", email.value);
        if (emailValError) {
            setEmail({ ...email, error: emailValError });
        };

        const passwordValError = validationHelper.validateInput("password", password.value);
        if (passwordValError) {
            setPassword({ ...password, error: passwordValError });
        };

        if (emailValError || passwordValError) return;

        setIsProcessing(true);

        signIn({
            creds: {
                email: email.value,
                password: password.value
            },
            setApiError,
            setIsProcessing,
        });
    };

    return (
        <div className="immployer__auth__form_view immployer__auth__sign_in_form_view">
            <h3 className="color_dark">Sign In</h3>
            <hr />
            <form onSubmit={onSignIn}>
                <TextInput
                    name="email"
                    type="text"
                    label="Email"
                    required={true}
                    errorMsg={email.error}
                    placeholder="Enter your email"
                    onChange={(value: string) => setEmail({ ...email, value })}
                    value={email.value}
                />
                <br />
                <TextInput
                    name="password"
                    type="password"
                    label="Password"
                    required={true}
                    errorMsg={password.error}
                    placeholder="Enter your password"
                    onChange={(value: string) => setPassword({ ...password, value })}
                    value={password.value}
                />
                <div className="forgot_password_action">
                    <Link to="/auth/forgot-password" className="color_grey wt_500">Forgot Password?</Link>
                </div>
                <br />
                <button
                    className="btn_block immployer__btn immployer__btn__primary"
                    type="submit"
                    disabled={isProcessing}
                >
                    {
                        isProcessing
                            ? <i className='immployer__btn_loader' />
                            : 'Sign In'
                    }
                </button>
            </form>
            {apiError?.length > 0 && <p className="immployer__auth_api_error">{apiError}</p>}
            <hr />
            {/* <p className="color_dark_grey text_center">Don't have an account? <Link to="/auth/sign-up" className="color_grey display_inline wt_500">Sign Up</Link></p> */}
        </div>
    );
};

export default SignInView;