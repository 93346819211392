import { useMutation } from "@tanstack/react-query";

import { resetPassword } from "../../services/auth";
import { IPassword } from "../../interfaces/services";

interface IPayload {
    token: string;
    creds: IPassword;
    setIsSuccess: Function;
    setApiError: Function;
    setIsProcessing: Function;
};

interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useResetPassword = () => {
    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await resetPassword(data.token, data.creds);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response) {
                variables.setIsSuccess(true);
            }
        },
        onError: (error: IResponse, variables: IPayload) => {
            variables.setApiError(error.message);
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useResetPassword;