import { useMutation } from "@tanstack/react-query";

import { refreshAuthToken } from "../../services/auth";

import cookiesHelper from "../../helpers/cookies";

import useSignOut from "./useSignOut";

export interface IPayload {
    setIsProcessing: Function;
    setToken: Function;
    onClose: Function;
};

interface IResponse {
    error?: string;
    message: string;
    data: string;
};

const useRefreshAuthToken = () => {
    const { mutate: signOut } = useSignOut();

    const mutationFn = async (data: IPayload): Promise<any> => {
        return await refreshAuthToken();
    }

    return useMutation({
        mutationFn,
        onMutate: (variables: IPayload) => {
            variables.setIsProcessing(true);
        },
        onSuccess: (data: any, variables: IPayload) => {
            cookiesHelper.setCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string, data.data, 60 * 60 * 1000);
            variables.setToken(data.data);
            variables.onClose();
        },
        onError: (error: IResponse, variables: IPayload) => {
            signOut();
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    })
};
export default useRefreshAuthToken;