import React, { useState } from "react";
import { useParams } from "react-router-dom";

import LMIADetailsLayout from "../../../layouts/details/LMIA";
import LMIADetailsFormView from "../../../views/details_forms/LMIA";

import { ILMIAData } from "../../../../interfaces/lmia";

import useGetLMIADetails from "../../../../hooks/lmias/useGetLMIADetails";

const LMIADetailsPage = () => {
    const { lmia_id } = useParams();

    const [formData, setFormData] = useState<ILMIAData>({
        id: null,
        location: { value: { label: "", value: "" }, error: "" },
        lmia_number: { value: "", error: "" },
        type: { value: { label: "", value: "" }, error: "" },
        category: { value: { label: "", value: "" }, error: "" },
        jobTitle: { value: { label: "", value: "" }, error: "" },
        submissionDate: { value: "", error: "" },
        issueDate: { value: "", error: "" },
        expiryDate: { value: "", error: "" },
        wage: { value: "", error: "" },
        wageType: { value: { label: "", value: "" }, error: "" },
        positions: { value: "", error: "" },
        status: { value: { label: "", value: "" }, error: "" },
    });

    const { data: lmia, isLoading, error } = useGetLMIADetails(lmia_id);

    return (
        <LMIADetailsLayout
            title='LMIA Information'
            error={!!error}
        >
            <LMIADetailsFormView
                operation='update'
                isLoading={isLoading}
                lmiaData={lmia ?? null}
                formData={formData}
                setFormData={setFormData}
            />
        </LMIADetailsLayout>
    );
};

export default LMIADetailsPage;