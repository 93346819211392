import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { ILMIA } from "../interfaces/entities";
import { ISaveLMIA, ISortQuery } from "../interfaces/services";

export const getLMIAs = async (employerId: number, sortQuery: ISortQuery): Promise<ILMIA[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const { sort_key, sort_order } = sortQuery;

    try {
        const res = await api.get(`lmias/list/${employerId}`, {
            params: {
                sort_key,
                sort_order,
            }
        });
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const saveLMIA = async (data: ISaveLMIA) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`lmias`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getLMIADetails = async (lmiaId: number): Promise<ILMIA> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`lmias/details/${lmiaId}`);
        return res.data?.data ?? null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getLMIAsForForeignWorker = async (foreignWorkerId: number): Promise<ILMIA[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`lmias/foreign-worker/${foreignWorkerId}`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};