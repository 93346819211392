import { useQuery } from "@tanstack/react-query";

import { getJobTitles } from "../../services/jobs";

import { IJobTitle } from "../../interfaces/entities";

const useGetJobTitles = () => {

    const queryFn = async (): Promise<IJobTitle[]> => {
        return await getJobTitles();
    };

    return useQuery({
        queryKey: ['job_titles'],
        queryFn,
        retry: 0,
    });
};

export default useGetJobTitles;