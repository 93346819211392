import React, { useState } from "react";

import LocationDetailsLayout from "../../../layouts/details/Location";
import LocationDetailsFormView from "../../../views/details_forms/Location";

import { ILocationData } from "../../../../interfaces/location";

const AddLocationPage = () => {
    const [formData, setFormData] = useState<ILocationData>({
        id: null,
        identifier: { value: "", error: "" },
        street: { value: "", error: "" },
        city: { value: "", error: "" },
        province: { value: { label: "", value: "" }, error: "" },
        postal: { value: "", error: "" },
        phone: { value: "", error: "" },
        manager: { value: "", error: "" },
        type: { value: { label: "", value: "" }, error: "" },
        full_time_emp: { value: "", error: "" },
        part_time_emp: { value: "", error: "" },
    });

    return (
        <LocationDetailsLayout
            title='Add a Location'
            error={false}
        >
            <LocationDetailsFormView
                operation='create'
                isLoading={false}
                locationData={null}
                formData={formData}
                setFormData={setFormData}
            />
        </LocationDetailsLayout>
    );
};

export default AddLocationPage;