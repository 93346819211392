import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { inviteForeignWorker } from "../../services/invitations";

import useToast from "../useToast";

export interface IPayload {
    foreignWorkerId: number;
    setIsProcessing: Function;
    onClose: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useInviteForeignWorker = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const { addToast } = useToast();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await inviteForeignWorker(data.foreignWorkerId);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response) {
                addToast({
                    type: 'success',
                    title: 'Success',
                    message: 'The foreign worker has been invited.'
                });
                variables.onClose();

                queryClient.invalidateQueries({ queryKey: ['foreign_worker_invitations'] });
                queryClient.invalidateQueries({ queryKey: ['foreign_worker_details'] });
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            if (error.message === 'Please complete the employment information.') {
                addToast({
                    type: 'error',
                    title: 'Employment required.',
                    message: error.message
                });

                setTimeout(() => {
                    navigate(`/foreign-workers/${variables.foreignWorkerId}/employment`);
                }, 500);
            } else {
                addToast({
                    type: 'error',
                    title: 'Error occured',
                    message: error.message
                });
            };
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useInviteForeignWorker;