import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// import { SearchInput } from '../../../reusables/Inputs';
import EmployerDetailsLayout from '../../../layouts/details/Employer';
import ForeignWorkersListTable from '../../../sub_components/tables/ForeignWorkersList';

import useGetForeignWorkersDataList from '../../../../hooks/foreign_workers/useGetForeignWorkersDataList';
import useGetEmployerDetails from '../../../../hooks/employers/useGetEmployerDetails';

import { iconAssets } from '../../../../constants/assets';

import { ISortQuery } from '../../../../interfaces/services';

const EmployerForeignWorkersPage = () => {
    const { employer_id } = useParams();
    const navigate = useNavigate();

    const [sortQuery, setSortQuery] = useState<ISortQuery>({ sort_key: 'first_name', sort_order: 'ASC' });

    const { data: employer } = useGetEmployerDetails(employer_id);
    const { data: workers, isLoading } = useGetForeignWorkersDataList('employer', employer_id, sortQuery);

    return (
        <EmployerDetailsLayout
            operation={null}
            currentTab="Foreign Workers"
            title={employer?.legal_name ?? 'Employer Information'}
            error={false}
        >
            <div className="immployer__lists__page_view">
                <br />
                <div className="immployer__lists__top_section">
                    {/* <SearchInput /> */}
                    <button
                        onClick={() => navigate(`/foreign-workers/new`, { state: { goBackRoute: `/employers/${employer_id}/foreign-workers` } })}
                        className="immployer__btn immployer__btn__primary"
                    >
                        <img src={iconAssets.Plus.white} alt="" />
                        <span>Add&nbsp;Worker</span>
                    </button>
                </div>
                <ForeignWorkersListTable
                    goBackRoute={`/employers/${employer_id}/foreign-workers`}
                    workers={workers ?? []}
                    isLoading={isLoading}
                    sortQuery={sortQuery}
                    setSortQuery={setSortQuery}
                />
            </div>
        </EmployerDetailsLayout>
    );
};

export default EmployerForeignWorkersPage;