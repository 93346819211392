import { combineReducers } from 'redux';

import modalReducer from './slices/modal';
import globalReducer from './slices/global';

const rootReducer = combineReducers({
    global: globalReducer,
    modals: modalReducer,
});

export default rootReducer;