import { useQuery } from "@tanstack/react-query";

import { getForeignWorkersDataList } from "../../services/foreign_workers";

import { IForeignWorkerListItem, ISortQuery } from "../../interfaces/services";

const useGetForeignWorkersDataList = (requestor: string, employerId: string | undefined, sortQuery: ISortQuery) => {
    const employer_id = employerId ? parseInt(employerId) : 0;
    const enabled = !!requestor && (requestor === 'organization' || employer_id > 0);

    const { sort_key, sort_order } = sortQuery;

    const queryFn = async (): Promise<IForeignWorkerListItem[]> => {
        return await getForeignWorkersDataList(requestor, employer_id, sortQuery);
    };

    return useQuery({
        queryKey: ['workers_data_list', requestor, employerId, sort_key, sort_order],
        queryFn,
        enabled,
        retry: 0,
    });
};

export default useGetForeignWorkersDataList;