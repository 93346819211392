import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { IGenericDocument, ISinExpiryNotificationPayload } from "../interfaces/services";

export const getDocumentExpiryNotifications = async (genericDocument: IGenericDocument) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`expiry-schedules/document-expiry-notifications`, genericDocument);

        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getSinExpiryNotification = async (sinExpiryNotificationBody: ISinExpiryNotificationPayload) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`expiry-schedules/sin-expiry-notifications`, sinExpiryNotificationBody);

        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};