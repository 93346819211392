import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { ICreateNotification, ISaveForeignWorker, ISinExpiryNotificationPayload } from "../../interfaces/services";

import useToast from "../useToast";

import { getSinExpiryNotification } from "../../services/expiry_schedules";

import { openModal } from "../../store/slices/modal";

import useSaveForeignWorker from "../foreign_workers/useSaveForeignWorker";

export interface IPayload {
    payload: ISinExpiryNotificationPayload;
    documentType: string;
    operation: string;
    documentPayload: ISaveForeignWorker;
    isProcessing: boolean;
    setIsProcessing: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
    data: ICreateNotification[];
};

const useGetSinExpiryNotification = () => {
    const { addToast } = useToast();

    const dispatch = useDispatch();

    const { mutate: saveForeignWorker } = useSaveForeignWorker();

    const saveDocument = (operation: string, documentPayload: any, setIsProcessing: Function) => {
        saveForeignWorker({
            payload: documentPayload,
            operation: operation,
            setIsProcessing,
        });
    };

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await getSinExpiryNotification(data.payload);
    };

    return useMutation({
        mutationFn,
        onSuccess: async (response: IResponse, variables: IPayload) => {
            if (response?.statusCode === 200) {
                if (response.data) {
                    dispatch(openModal({
                        name: 'MESSAGE_MODAL',
                        data: {
                            view: 'NOTIFICATION',
                            notifications: [response.data],
                            documentType: variables.documentType,
                            operation: variables.operation,
                            documentPayload: variables.documentPayload,
                            isProcessing: variables.isProcessing,
                            setIsProcessing: variables.setIsProcessing,
                        }
                    }));
                } else {
                    saveDocument(variables.operation, variables.documentPayload, variables.setIsProcessing);
                };
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useGetSinExpiryNotification;