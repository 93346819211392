import React, { useState } from "react";
import moment from 'moment';
import { useDispatch } from "react-redux";

import { IColumnProps, IInvitationsListTableProps } from "../../../interfaces/tables";
import { IForeignWorkerInvitation } from "../../../interfaces/entities";

import { iconAssets } from "../../../constants/assets";

import useResendInvitation from "../../../hooks/invitations/useResendInvitation";
import useCancelInvitation from "../../../hooks/invitations/useCancelInvitation";

import { openModal } from "../../../store/slices/modal";

import { getDateString } from "../../../helpers/utility";

const InvitationsListTable = (props: IInvitationsListTableProps) => {
    const { isLoading, invitations } = props;

    const columns: IColumnProps[] = [
        { name: "Date Sent", buttonClass: 'btn_block' },
        { name: "Status" },
        { name: "Email", buttonClass: 'btn_block' },
        { name: "Action", buttonClass: 'btn_block' },
    ];

    const dispatch = useDispatch();

    const [processRunningFor, setProcessRunningFor] = useState<number | null>(null);

    const { mutate: resendInvitation } = useResendInvitation();
    const { mutate: cancelInvitation } = useCancelInvitation();

    const onResendInvitation = (idx: number, invitation: IForeignWorkerInvitation) => {
        if (invitation.status !== 'pending' || processRunningFor !== null) return;

        setProcessRunningFor(idx);

        resendInvitation({
            invitationId: invitation.id,
            setProcessRunningFor,
        });
    };

    const onCancelInvitation = (idx: number, invitation: IForeignWorkerInvitation) => {
        if (invitation.status !== 'pending' || processRunningFor !== null) return;

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: "Cancel Invitation?",
                content: "Are you sure you want to cancel this invitation? This action cannot be undone. But you can resend the invitation later.",
                actionTxt: "Confirm",
                action: () => {
                    setProcessRunningFor(idx);

                    cancelInvitation({
                        invitationId: invitation.id,
                        setProcessRunningFor,
                    });
                }
            }
        }));
    };

    return (
        <div className="immployer__list_table__container">
            <table className="immployer__list_table">
                <thead>
                    <tr>
                        {columns.map((column, index) =>
                            <th key={index}>
                                <div className="display_flex display_flex_center">
                                    <button className={`${column.buttonClass ?? ''}`}>
                                        {column.name}
                                        {column?.sort && <img src={iconAssets.Exchange.primary} alt="" />}
                                    </button>
                                </div>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading
                            ? <tr>
                                <td colSpan={columns.length}>
                                    <div className="display_flex flex_dir_column display_flex_center">
                                        <span className="wt_600 pb_10">Loading</span>
                                        <i className='immployer__list_loader' />
                                    </div>
                                </td>
                            </tr>
                            : !invitations?.length
                                ? <tr>
                                    <td colSpan={columns.length} className='text_center'>
                                        No records found.
                                    </td>
                                </tr>
                                : invitations.map((invitation, index) =>
                                    <tr key={index}>
                                        <td>
                                            {getDateString(invitation.date_created, 'MMM DD-YYYY')}
                                            &nbsp;({moment.utc(invitation.date_created).fromNow()})
                                        </td>
                                        <td>
                                            <div className="display_flex display_flex_center">
                                                {
                                                    invitation.status === "accepted"
                                                        ? <img src={iconAssets.CheckmarkCircle.success} alt="" />
                                                        : invitation.status === "pending"
                                                            ? <img src={iconAssets.Dots3HorizCircle.primary} alt="" />
                                                            : <img src={iconAssets.WarningTriangle.error} alt="" />
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            {invitation.email}
                                        </td>
                                        <td className="display_flex" style={{ gap: '15px' }}>
                                            <button
                                                className={`immployer__btn immployer__btn__primary ${invitation.status !== 'pending' ? 'immployer__btn__primary__disabled' : ''}`}
                                                onClick={() => onResendInvitation(index, invitation)}
                                            >
                                                {processRunningFor === index
                                                    ? <i className='immployer__btn_loader' />
                                                    : 'Resend'
                                                }
                                            </button>

                                            <button
                                                className={`immployer__btn immployer__btn__secondary ${invitation.status !== 'pending' ? 'immployer__btn__secondary__disabled' : ''}`}
                                                onClick={() => onCancelInvitation(index, invitation)}
                                            >
                                                {processRunningFor === index
                                                    ? <i className='immployer__btn_loader' />
                                                    : 'Cancel Invite'
                                                }
                                            </button>
                                        </td>
                                    </tr>
                                )
                    }
                </tbody>
            </table>
        </div >
    );
};

export default InvitationsListTable;