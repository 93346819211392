import { useQuery } from "@tanstack/react-query";

import { getWorkerImmigrationDocs } from "../../services/foreign_workers";

import { IWorkerImmigrationDocs } from "../../interfaces/services";

const useGetWorkerImmigrationDocs = (foreignWorkerId: string | undefined, currentTab: string | undefined) => {
    const foreign_worker_id = foreignWorkerId ? parseInt(foreignWorkerId) : 0;

    const queryFn = async (): Promise<IWorkerImmigrationDocs> => {
        return await getWorkerImmigrationDocs(foreign_worker_id);
    };

    return useQuery({
        queryKey: ['foreign_worker_immigration_docs',  foreign_worker_id, currentTab],
        queryFn,
        enabled: foreign_worker_id > 0,
        retry: 0,
    });
};

export default useGetWorkerImmigrationDocs;