import React from 'react'

import { IApplicantProgram } from '../../../../interfaces/services';

import { iconAssets } from '../../../../constants/assets';

interface IOtherProgramCardProps {
    program: IApplicantProgram;
}
const OtherProgramCard = (props: IOtherProgramCardProps) => {
    const { program } = props;

    return (
        <div className='immployer__recommendation_card'>
            <div className='immployer__recommendation_card__content'>
                <div className='immployer__recommendation_card__header'>
                    <h5 className='immployer__recommendation_card__header_title'>
                        {program.name}
                    </h5>
                </div>
                <div className='immployer__recommendation_card__body'>
                    <h6 className='immployer__recommendation_card__body__description'>
                        {program.description}
                    </h6>
                </div>
            </div>
            <div className='immployer__recommendation_card__favorite_icon_container'>
                <img src={program.favorite ? iconAssets.Heart.heart_fill : iconAssets.Heart.heart_outline} alt='' />
            </div>
        </div>
    )
}

export default OtherProgramCard;