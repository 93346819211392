import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import WorkerDetailsLayout from "../../layouts/details/Worker";
import WorkerPersonalDetailsFormView from "../../views/details_forms/foreign_worker/Personal";

import { IWorkerPersonalData } from "../../../interfaces/foreign_worker";

const AddForeignWorkerPage = () => {
    const location = useLocation();

    const [formData, setFormData] = useState<IWorkerPersonalData>({
        id: null,
        firstName: { value: '', error: '' },
        middleName: { value: '', error: '' },
        lastName: { value: '', error: '' },
        birthDate: { value: '', error: '' },
        phone: { value: '', error: '' },
        personalEmail: { value: '', error: '' },
        sinExpiryDate: { value: '', error: '' }
    });

    return (
        <WorkerDetailsLayout
            goBackRoute={location.state?.goBackRoute ?? null}
            operation='create'
            currentTab="Personal"
            title='Add a Foreign Worker'
            error={false}
            invitations={[]}
            linkedStatus={{
                isDataAvailable: false,
                linked: false,
                linkedDate: null,
                unlinkedDate: null,
            }}
            isEmploymentAdded={false}
        >
            <WorkerPersonalDetailsFormView
                goBackRoute={location.state?.goBackRoute ?? null}
                operation='create'
                linked={false}
                isLoading={false}
                workerData={null}
                formData={formData}
                setFormData={setFormData}
            />
        </WorkerDetailsLayout>
    );
};

export default AddForeignWorkerPage;