import React, { useEffect, useState } from 'react'

import { IProgramsDropdownOption } from '../../../../interfaces/formElements'

import { iconAssets } from '../../../../constants/assets'

interface IProgramsDropDownProps {
    value: IProgramsDropdownOption;
    options: IProgramsDropdownOption[];
    onChangeSelect: (option: IProgramsDropdownOption) => void;
};

const ProgramsDropDown = (props: IProgramsDropDownProps) => {
    const { value: selectedItem, options, onChangeSelect } = props

    const [openDropDown, setOpenDropDown] = useState(false)

    const onProgramSelect = (option: IProgramsDropdownOption) => {
        onChangeSelect(option)
        setOpenDropDown(false)
    };

    useEffect(() => {
        const closeDropdown = (e: MouseEvent) => {
            const target = e.target as HTMLElement
            if (!target.closest('.immployer__programs_dropdown')) {
                setOpenDropDown(false)
            }
        }

        document.addEventListener('click', closeDropdown)

        return () => {
            document.removeEventListener('click', closeDropdown)
        }
    }, [])

    return (
        <div className={`immployer__programs_dropdown ${openDropDown ? 'immployer__programs_dropdown__open' : ''}`}>
            <div
                className='immployer__programs_dropdown__selected_program'
                onClick={() => setOpenDropDown(!openDropDown)}
            >
                <div className='immployer__programs_dropdown__program_name__container'>
                    {selectedItem.difficulty &&
                        <span className={`immployer__programs_dropdown__program_difficulty__icon immployer__programs_dropdown__program_difficulty__icon__${selectedItem.difficulty}`} />
                    }
                    <h5 className='immployer__programs_dropdown__program_name'>{selectedItem.label}</h5>
                </div>
                <div className='immployer__programs_dropdown__dropdown_icons'>
                    <img src={iconAssets.TriangleDown.primary} alt='' />
                    <img src={iconAssets.TriangleDown.primary} alt='' />
                </div>
            </div>
            {openDropDown &&
                <div className='immployer__programs_dropdown__program_list'>
                    {options.map((option, index) =>
                        selectedItem.value !== option.value &&
                        <div
                            className='immployer__programs_dropdown__program_list_item'
                            key={index}
                            onClick={() => onProgramSelect(option)}
                        >
                            <div className='immployer__programs_dropdown__program_name__container'>
                                {option.difficulty &&
                                    <span className={`immployer__programs_dropdown__program_difficulty__icon immployer__programs_dropdown__program_difficulty__icon__${option.difficulty}`} />
                                }
                                <h5 className='immployer__programs_dropdown__program_name'>
                                    {option.label}
                                </h5>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export default ProgramsDropDown;