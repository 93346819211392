import { useQuery } from "@tanstack/react-query";

import { getLMIADetails } from "../../services/lmias";

import { ILMIA } from "../../interfaces/entities";

const useGetLMIADetails = (lmiaId: string | undefined) => {
    const lmia_id = lmiaId ? parseInt(lmiaId) : 0;

    const queryFn = async (): Promise<ILMIA> => {
        return await getLMIADetails(lmia_id);
    };

    return useQuery({
        queryKey: ['lmia_details', lmia_id],
        queryFn,
        enabled: lmia_id > 0,
        retry: 0,
    });
};

export default useGetLMIADetails;