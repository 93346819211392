import { useQuery } from "@tanstack/react-query";

import { getLocations } from "../../services/locations";

import { ILocation } from "../../interfaces/entities";
import { ISortQuery } from "../../interfaces/services";

const useGetLocations = (requestor: string, requestorId: string | undefined, sortQuery: ISortQuery) => {
    const requestor_id = requestorId ? parseInt(requestorId) : 0;
    const enabled = !!requestor && (requestor === 'organization' || requestor_id > 0);

    const { sort_key, sort_order } = sortQuery;

    const queryFn = async (): Promise<ILocation[]> => {
        return await getLocations(requestor, requestor_id, sortQuery);
    };

    return useQuery({
        queryKey: ['locations_list', requestor, requestorId, sort_key, sort_order],
        queryFn,
        enabled,
        retry: 0,
    });
};

export default useGetLocations;