import axios, { CancelTokenSource } from "axios";

import { useMutation } from "@tanstack/react-query";

import { parseDocument } from "../../services/documents";

import useGetPreSignedUrl from "./useGetPreSignedUrl";

import { IParsedPassportData, IParsedPermitData, IParsedVisaData, IPassportData, IPermitData, IVisaData } from "../../interfaces/foreign_worker";
import { ILMIA, IPassport, IPermitType } from "../../interfaces/entities";

import { getDateString } from "../../helpers/utility";

import { OPVisaTypes } from "../../constants/options";

interface IPayload {
    type: 'passport' | 'permit' | 'visa' | 'lmia';
    data: File;
    docId: number;
    foreignWorkerId: number;
    passports: IPassport[] | null;
    lmias: ILMIA[] | null;
    permitTypes: IPermitType[] | null;
    setCancelToken: React.Dispatch<React.SetStateAction<CancelTokenSource | null>>;
    setFormData: Function;
    setPresignedUrl: Function;
    setIsProcessing: Function;
    setApiError: Function;
    isDataChanged: () => void;
}

interface IResponse {
    error?: string;
    statusCode: number;
    message: string;
    data: IParsedPassportData | IParsedPermitData | IParsedVisaData;
}

const useParseDocument = () => {
    const { mutate: getPreSignedUrl } = useGetPreSignedUrl();

    const mutationFn = async (payload: IPayload) => {
        const { type, data, setCancelToken, foreignWorkerId, docId } = payload;

        const formData = new FormData();
        formData.append('file', data);

        const source = axios.CancelToken.source();
        setCancelToken(source);


        return await parseDocument(foreignWorkerId, docId, type, formData, source.token);
    }

    return useMutation({
        mutationFn: mutationFn,
        onMutate(variables: IPayload) {
            variables.setIsProcessing(true);
            variables.setApiError(null);
        },
        onSuccess: (response: IResponse, variables: IPayload) => {
            variables.setIsProcessing(false);

            variables.setApiError(null);

            if (variables.type === 'passport') {
                variables.setFormData((prev: IPassportData) => {
                    const data = response.data as IParsedPassportData;
                    const { id: docId, type: docType, original: docOriginal, display: docDisplay, upload_timestamp: docUploadTimestamp } = data.document;

                    return {
                        ...prev,
                        document_id: { value: docId, error: "" },
                        passportNumber: { value: data.passport_number ?? prev.passportNumber.value, error: "" },
                        issueDate: { value: data.issued ? getDateString(data.issued) : prev.issueDate.value, error: "" },
                        expiryDate: { value: data.expire ? getDateString(data.expire) : prev.expiryDate.value, error: "" },
                        nationality: { value: { value: data.nationality ?? prev.nationality.value.value, label: data.nationality ?? prev.nationality.value.label }, error: "" },
                        document: { id: docId, type: docType, original: docOriginal, display: docDisplay, upload_timestamp: docUploadTimestamp },
                    }
                });
            } else if (variables.type === 'permit') {
                variables.setFormData((prev: IPermitData) => {
                    const data = response.data as IParsedPermitData;
                    const passports = variables.passports ?? [];
                    const lmias = variables.lmias ?? [];

                    const { id: docId, type: docType, original: docOriginal, display: docDisplay, upload_timestamp: docUploadTimestamp } = data.document;

                    const caseType = data.case_type ?? prev.caseType.value;
                    const permitNumber = data.permit_number ?? prev.permitNumber.value;
                    const passport = passports.find(passport => passport.passport_details[0].passport_number === data.travel_doc_number);
                    const lmia = lmias.find(lmia => lmia.lmia_number === data.lmia_number);
                    const issued = data.issued ? getDateString(data.issued) : prev.issueDate.value;
                    const expire = data.expire ? getDateString(data.expire) : prev.expiryDate.value;
                    const status = issued.length && expire.length ? { value: 'approved', label: 'Approved' } : prev.status.value;

                    return {
                        ...prev,
                        document_id: { value: docId, error: "" },
                        passport: { value: { value: passport?.id.toString() || prev.passport.value.value, label: passport?.passport_details[0].passport_number || prev.passport.value.label }, error: "" },
                        permitNumber: { value: permitNumber, error: "" },
                        permitType: { value: { value: prev.permitType.value.value, label: prev.permitType.value.label }, error: "" },
                        lmia: { value: { value: lmia?.id.toString() || prev.lmia.value.value, label: lmia?.lmia_number && lmia?.title ? `${lmia.lmia_number} - ${lmia.title}` : prev.lmia.value.label }, error: "" },
                        caseType: { value: caseType, error: "" },
                        company: { value: prev.company.value, error: "" },
                        submissionDate: { value: prev.submissionDate.value, error: "" },
                        issueDate: { value: issued, error: "" },
                        expiryDate: { value: expire, error: "" },
                        status: { value: status, error: "" },
                        document: { id: docId, type: docType, original: docOriginal, display: docDisplay, upload_timestamp: docUploadTimestamp },
                    }
                });
            } else if (variables.type === 'visa') {
                variables.setFormData((prev: IVisaData) => {
                    const data = response.data as IParsedVisaData;
                    const passports = variables.passports ?? [];

                    const { id: docId, type: docType, original: docOriginal, display: docDisplay, upload_timestamp: docUploadTimestamp } = data.document;

                    const passport = passports.find(passport => passport.passport_details[0].passport_number === data.passport_number);
                    const issued = data.issued ? getDateString(data.issued) : prev.issueDate.value;
                    const expire = data.expire ? getDateString(data.expire) : prev.expiryDate.value;
                    const visaType = OPVisaTypes.find(visaType => visaType.value === data.type);
                    const visaNumber = data.visa_number ?? prev.visaNumber.value;
                    const status = issued.length && expire.length ? { value: 'approved', label: 'Approved' } : prev.status.value;
                    return {
                        ...prev,
                        document_id: { value: docId, error: "" },
                        passport: { value: { value: passport?.id.toString() || prev.passport.value.value, label: passport?.passport_details[0].passport_number || prev.passport.value.label }, error: "" },
                        visaNumber: { value: visaNumber, error: "" },
                        visaType: { value: { value: visaType?.value ?? prev.visaType.value.value, label: visaType?.label ?? prev.visaType.value.label }, error: "" },
                        submissionDate: { value: prev.submissionDate.value, error: "" },
                        issueDate: { value: issued, error: "" },
                        expiryDate: { value: expire, error: "" },
                        status: { value: status, error: "" },
                        document: { id: docId, type: docType, original: docOriginal, display: docDisplay, upload_timestamp: docUploadTimestamp },
                    }
                });
            }

            getPreSignedUrl({
                expiresIn: 360,
                workerId: variables.foreignWorkerId,
                documentId: response.data.document.id ?? 0,
                documentType: variables.type,
                fileName: response.data.document.original,
                isDownload: false,
                setPresignedUrl: variables.setPresignedUrl,
            })

            variables.isDataChanged();
        },
        onError: (error: IResponse, variables: IPayload) => {
            variables.setIsProcessing(false);
            variables.setApiError(error.message);
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        }
    });
};
export default useParseDocument;