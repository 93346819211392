import React, { useState } from "react";
import { Link } from "react-router-dom";

import { TextInput } from "../../reusables/FormElements";

import validationHelper from "../../../helpers/validators";

import useSendPasswordResetEmail from "../../../hooks/auth/useSendPasswordResetEmail";

const ForgotPasswordView = () => {

    const [email, setEmail] = useState({ value: "", error: "" });
    const [isProcessing, setIsProcessing] = useState(false);
    const [apiError, setApiError] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const { mutate: sendPasswordResetEmail } = useSendPasswordResetEmail();

    const onForgotPassword = () => {
        if (isProcessing) return;

        setEmail({ ...email, error: "" });
        setApiError("");
        setIsSuccess(false);

        const emailValError = validationHelper.validateInput("email", email.value);
        if (emailValError) {
            setEmail({ ...email, error: emailValError });
        };

        if (emailValError) return;

        setIsProcessing(true);

        sendPasswordResetEmail({
            email: email.value,
            setIsSuccess,
            setApiError,
            setIsProcessing,
        });
    };

    return (
        <div className="immployer__auth__form_view immployer__auth__forgot_password_form_view">
            <h3 className="color_dark">Forgot password?</h3>
            <hr />
            <TextInput
                name="email"
                type="text"
                label="Registered Email"
                required={true}
                errorMsg={email.error}
                placeholder="Enter registered email"
                onChange={(value: string) => setEmail({ ...email, value })}
                value={email.value}
            />
            <br /><br />
            <button
                className="btn_block immployer__btn immployer__btn__primary"
                onClick={onForgotPassword}
                disabled={isProcessing}
            >
                {
                    isProcessing
                        ? <i className='immployer__btn_loader' />
                        : 'Send Reset Link'
                }
            </button>
            {
                apiError?.length > 0
                    ? <p className="immployer__auth_api_error">{apiError}</p>
                    : isSuccess
                        ? <p className="immployer__auth_api_success">
                            Password reset link sent to your email. Be sure to check your spam/junk folder.
                        </p>
                        : null
            }
            <hr />
            <p className="color_dark_grey text_center">Sign in instead? <Link to="/auth/sign-in" className="color_grey display_inline wt_500">Sign In</Link></p>
        </div>
    );
};

export default ForgotPasswordView;