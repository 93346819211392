import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { getFormattedString } from "../../helpers/utility";

interface BreadcrumbPath {
    name: string;
    path: string;
};
const Breadcrumb = () => {
    const location = useLocation();

    const [breadcrumbPaths, setBreadcrumbPaths] = useState<BreadcrumbPath[]>([]);
    const [displayPaths, setDisplayPaths] = useState<BreadcrumbPath[]>([]);

    useEffect(() => {
        const pathsSplit = location.pathname.split("/");
        const paths: string[] = pathsSplit.filter(path => path?.length > 0);

        const pathsWithLink = paths.map((path, index) => {
            return {
                name: path,
                path: "/" + paths.slice(0, index + 1).join("/"),
            };
        });
        setBreadcrumbPaths(pathsWithLink);

        const displayPaths = pathsWithLink
            .filter((path, index) => index < pathsWithLink.length - 1)
            .filter((path) => isNaN(parseInt(path.name)));

        let containsLmia = false;
        let lmiaPath = '';
        displayPaths.forEach((path) => {
            if (path.name === 'lmias') {
                path.name = 'lmia';
                lmiaPath = path.path;
                containsLmia = true;
            }
        });

        if (containsLmia) {
            displayPaths.push({
                name: 'exempt',
                path: lmiaPath,
            });
        }

        setDisplayPaths(displayPaths);
    }, [location.pathname]);

    return (
        <div className="immployer__breadcrumb">
            {breadcrumbPaths.length > 1 &&
                <div className="immployer__breadcrumb__content">
                    {displayPaths.map((path, index) => {
                        return (
                            <div key={index} className="immployer__breadcrumb__content__path">
                                <Link to={path.path}>{path.name === 'lmia' ? 'LMIA' : getFormattedString(path.name, 'title_case')}</Link>
                                <span>&nbsp;/&nbsp;</span>
                            </div>
                        );
                    })}
                    <div className="immployer__breadcrumb__content__path">
                        <span>{getFormattedString(breadcrumbPaths[breadcrumbPaths.length - 1].name, 'title_case')}</span>
                    </div>
                </div>
            }
        </div>
    );
};

export default Breadcrumb;