import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import WorkerDetailsLayout from "../../../layouts/details/Worker";
import WorkerPersonalDetailsFormView from "../../../views/details_forms/foreign_worker/Personal";

import { IWorkerPersonalData } from "../../../../interfaces/foreign_worker";

import useGetForeignWorkerDetails from "../../../../hooks/foreign_workers/useGetForeignWorkerDetails";
import useGetForeignWorkerInvitations from "../../../../hooks/invitations/useGetForeignWorkerInvitations";
import useGetWorkerJobs from "../../../../hooks/worker_jobs/useGetWorkerJobs";

const ForeignWorkerPersonalDetailsPage = () => {
    const { worker_id } = useParams();
    const location = useLocation();

    const [formData, setFormData] = useState<IWorkerPersonalData>({
        id: null,
        firstName: { value: '', error: '' },
        middleName: { value: '', error: '' },
        lastName: { value: '', error: '' },
        birthDate: { value: '', error: '' },
        phone: { value: '', error: '' },
        personalEmail: { value: '', error: '' },
        sinExpiryDate: { value: '', error: '' }
    });

    const { data: worker, isLoading, error } = useGetForeignWorkerDetails(worker_id);
    const { data: invitations } = useGetForeignWorkerInvitations(worker_id);
    const { data: workerJobs } = useGetWorkerJobs(worker_id);

    return (
        <WorkerDetailsLayout
            goBackRoute={location.state?.goBackRoute ?? null}
            operation='update'
            currentTab="Personal"
            title={worker?.worker_details?.length ? `${worker.worker_details[0].first_name} ${worker.worker_details[0].last_name}` : 'Worker Information'}
            error={!!error}
            invitations={invitations ?? []}
            linkedStatus={{
                isDataAvailable: !!worker?.worker_details[0],
                linked: !!worker?.worker_details[0]?.linked,
                linkedDate: worker?.worker_details[0]?.date_linked ?? null,
                unlinkedDate: worker?.worker_details[0]?.date_unlinked ?? null,
            }}
            isEmploymentAdded={!!workerJobs?.length}
        >
            <WorkerPersonalDetailsFormView
                goBackRoute={location.state?.goBackRoute ?? null}
                operation='update'
                linked={!!worker?.worker_details[0]?.linked}
                isLoading={isLoading}
                workerData={worker ?? null}
                formData={formData}
                setFormData={setFormData}
            />
        </WorkerDetailsLayout>
    );
};

export default ForeignWorkerPersonalDetailsPage;