import { useMutation } from "@tanstack/react-query";

import { getPreSignedUrl, getDownloadUrl } from "../../services/documents";

interface IPayload {
    workerId: number;
    fileName: string;
    documentId: number;
    documentType: string;
    expiresIn: number;
    setPresignedUrl: Function;
    isDownload: boolean;
}

interface IResponse {
    statusCode: number;
    message: string;
    data: string;
    error?: string;
}

const useGetPreSignedUrl = () => {

    const mutationFn = async (payload: IPayload) => {
        const { workerId, documentId, documentType, expiresIn } = payload;
        const response = await getPreSignedUrl(workerId, documentId, documentType, expiresIn);
        return response;
    };

    return useMutation({
        mutationFn,

        onMutate: () => { },

        onSuccess: async (response: IResponse, variables: IPayload) => {
            if (response.statusCode === 200) {
                variables.setPresignedUrl(response.data);

                const { isDownload, fileName } = variables;

                if (isDownload) {
                    const url = await getDownloadUrl(response.data);

                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        },

        onError: (error: IResponse, variables: IPayload) => { },

        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => { }
    });
}
export default useGetPreSignedUrl;