import React, { ReactNode } from "react";

import Header from "../sub_components/Header";
import Sidebar from "../sub_components/Sidebar";
import Breadcrumb from "../sub_components/Breadcrumb";

const MainLayout = ({ children }: { children: ReactNode }) => {

    return (
        <div className="immployer__main_layout">
            <Header />
            <div className="immployer__main_layout_content">
                <Sidebar />
                <div className="immployer__main_layout__children">
                    <Breadcrumb />
                    <div className="immployer__main_layout__page_views">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainLayout;