import { useQuery } from "@tanstack/react-query";

import { getForeignWorkerApplicantScoreHypoGroups } from "../../services/foreign_workers";

import { ICombinedApplicantScoreHypoGroups } from "../../interfaces/services";

const useGetForeignWorkerApplicantScoreHypoGroups = (foreignWorkerId: string, linked: boolean) => {
    const foreign_worker_id = foreignWorkerId ? parseInt(foreignWorkerId) : 0;
    const queryFn = async (): Promise<ICombinedApplicantScoreHypoGroups> => {
        return await getForeignWorkerApplicantScoreHypoGroups(foreign_worker_id);
    };

    return useQuery({
        queryKey: ['foreign_worker_applicant_score_hypo_groups', foreign_worker_id],
        queryFn,
        enabled: foreign_worker_id > 0 && linked,
        retry: 0,
    });
};
export default useGetForeignWorkerApplicantScoreHypoGroups;