import { useQuery } from "@tanstack/react-query";

import { getForeignWorkerDetails } from "../../services/foreign_workers";

import { IForeignWorker } from "../../interfaces/entities";

const useGetForeignWorkerDetails = (foreignWorkerId: string | undefined) => {
    const foreign_worker_id = foreignWorkerId ? parseInt(foreignWorkerId) : 0;

    const queryFn = async (): Promise<IForeignWorker> => {
        return await getForeignWorkerDetails(foreign_worker_id);
    };

    return useQuery({
        queryKey: ['foreign_worker_details', foreign_worker_id],
        queryFn,
        enabled: foreign_worker_id > 0,
        retry: 0,
    });
};

export default useGetForeignWorkerDetails;