import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { ISaveWorkerJobs } from "../interfaces/services";
import { IWorkerJob } from "../interfaces/entities";

export const getWorkerJobs = async (foreignWorkerId: number): Promise<IWorkerJob[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`worker-jobs/worker/${foreignWorkerId}`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const saveWorkerJobs = async (data: ISaveWorkerJobs) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`worker-jobs`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};