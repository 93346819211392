
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { BsPlusCircleFill } from "react-icons/bs";

import { IPassportProps } from "../../../../../interfaces/foreign_worker";
import { IPassport } from "../../../../../interfaces/entities";

import { closeModal, openModal } from "../../../../../store/slices/modal";

import PassportForm from "../../../../sub_components/immigration/PassportForm";

const PassportsView = (props: IPassportProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { goBackRoute, linked, passportsData, visasData, permitsData, isLoading, setCurrentTab } = props;

    const [passports, setPassports] = useState<(IPassport | null)[]>([]);
    const [isGlobalLoading, setIsGlobalLoading] = useState<{ [key: number]: boolean }>({});
    const [hasUnsavedData, setHasUnsavedData] = useState<{ [key: number]: boolean }>({});

    useEffect(() => {
        if (passportsData?.length) {
            setPassports([...passportsData]);
        } else {
            setPassports([null]);
        }
    }, [passportsData]); // eslint-disable-line

    const isDisabled = () => {
        let isDisabled = false;
        Object.entries(isGlobalLoading).forEach(([key, value]) => {
            if (value) isDisabled = true;
        });

        return isDisabled;
    }

    const isSafeToNavigate = () => {
        let safeToNavigate = true;
        Object.entries(hasUnsavedData).forEach(([key, value]) => {
            if (value) safeToNavigate = false;
        });

        return safeToNavigate;
    }

    const onGoBack = () => {
        if (!isSafeToNavigate()) {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    view: 'CONFIRMATION',
                    closeBtn: true,
                    title: "Unsaved changes",
                    content: "You have unsaved data. Are you sure you want to leave this page?",
                    actionTxt: "Confirm",
                    action: () => {
                        dispatch(closeModal('MESSAGE_MODAL'));
                        if (goBackRoute) {
                            navigate(goBackRoute);
                        } else {
                            navigate(`/foreign-workers`);
                        };
                    }
                }
            }));
        } else {
            if (goBackRoute) {
                navigate(goBackRoute);
            } else {
                navigate(`/foreign-workers`);
            }
        }
    };

    const onGoNext = () => {
        if (!isSafeToNavigate()) {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    view: 'CONFIRMATION',
                    closeBtn: true,
                    title: "Unsaved changes",
                    content: "You have unsaved data. Are you sure you want to leave this page?",
                    actionTxt: "Confirm",
                    action: () => {
                        dispatch(closeModal('MESSAGE_MODAL'));
                        setCurrentTab('Permits');
                    }
                }
            }));
        } else {
            setCurrentTab('Permits');
        }
    };

    const onAddNewRecord = () => {
        if (!isSafeToNavigate() || !passports?.[passports.length - 1] || linked) return;

        setPassports([...passports, null]);
    }

    return (
        <div className='immployer__details_form_view'>
            <div className="immployer__details_form_inputs__container">
                {passports.map((passport, index) => (
                    <PassportForm
                        key={index}
                        index={index}
                        passportData={passport}
                        visasData={visasData}
                        permitsData={permitsData}
                        linked={linked}
                        isLoading={isLoading}
                        hasUnsavedData={hasUnsavedData}
                        setPassportDatas={setPassports}
                        setIsGlobalLoading={setIsGlobalLoading}
                        setHasUnsavedData={setHasUnsavedData}
                    />
                ))}

                <button
                    className={`immployer__details_form__add_more_btn ${!isSafeToNavigate() || !passports?.[passports.length - 1] || linked ? "immployer__details_form__add_more_btn__disabled" : ""}`}
                    onClick={onAddNewRecord}
                >
                    <BsPlusCircleFill />
                    <span>Add new Passport</span>
                </button>
            </div>

            <div className="immployer__details_form_actions">
                <button
                    className={`immployer__btn immployer__btn__secondary ${isDisabled() ? "immployer__btn__secondary__disabled" : ""}`}
                    onClick={onGoBack}
                >
                    Go back
                </button>

                <button
                    className={`immployer__btn immployer__btn__primary ${isDisabled() ? "immployer__btn__primary__disabled" : ""}`}
                    onClick={onGoNext}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PassportsView;