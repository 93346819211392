import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { IForeignWorkerInvitation } from "../interfaces/entities";

export const getForeignWorkerInvitations = async (foreignWorkerId: number): Promise<IForeignWorkerInvitation[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`invitations/foreign-worker/${foreignWorkerId}`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const inviteForeignWorker = async (foreignWorkerId: number) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`invitations/foreign-worker/invite/${foreignWorkerId}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const resendInvitation = async (invitationId: number) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`invitations/foreign-worker/resend/${invitationId}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const cancelInvitation = async (invitationId: number) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`invitations/foreign-worker/cancel/${invitationId}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};