import React, { ReactNode, Dispatch, SetStateAction, createContext, useState } from "react";

import { IToast } from "../interfaces/toast";

const ValueContext = createContext({
    toasts: [] as IToast[],
    setToasts: {} as Dispatch<SetStateAction<IToast[]>>,
});

interface Props {
    children?: ReactNode
}

export const ValueProvider = ({ children }: Props) => {

    const [toasts, setToasts] = useState<IToast[]>([]);

    return (
        <ValueContext.Provider
            value={{
                toasts, setToasts
            }}>
            {children}
        </ValueContext.Provider>
    );
};

export default ValueContext;