import React from "react";
import moment from 'moment';
import { useNavigate } from "react-router-dom";

import { IColumnProps, IForeignWorkersListTableProps } from "../../../interfaces/tables";
import { IForeignWorkerListItem } from "../../../interfaces/services";

import { iconAssets } from "../../../constants/assets";

import { getDateString, getElipsisString } from "../../../helpers/utility";

const ForeignWorkersListTable = (props: IForeignWorkersListTableProps) => {
    const { goBackRoute, isLoading, workers, sortQuery, setSortQuery } = props;

    const navigate = useNavigate();

    const columns: IColumnProps[] = [
        { name: "Employee Name", identifier: 'first_name', sort: true, buttonClass: 'btn_block' },
        { name: "Linked", identifier: 'linked', sort: true, },
        { name: "Nationality", identifier: 'nationality', buttonClass: 'btn_block' },
        { name: "Permit Expiration", identifier: 'permit_expire', sort: true, buttonClass: 'btn_block' },
        { name: "Permit Type", identifier: 'permit_type', sort: true, buttonClass: 'btn_block' },
        { name: "Role", identifier: 'role', sort: true, buttonClass: 'btn_block' },
    ];

    const onGoToDetails = (worker: IForeignWorkerListItem) => {
        navigate(`/foreign-workers/${worker.id}/personal`, { state: { goBackRoute } });
    };

    const handleSort = (sort_key: string) => {
        setSortQuery({
            sort_key,
            sort_order: sortQuery.sort_order === 'ASC' ? 'DESC' : 'ASC'
        });
    };

    return (
        <div className="immployer__list_table__container">
            <table className="immployer__list_table">
                <thead>
                    <tr>
                        {columns.map((column, index) =>
                            <th key={index}>
                                <div className="display_flex display_flex_center">
                                    <button onClick={() => column.sort ? handleSort(column.identifier ?? 'first_name') : null} className={`${column.buttonClass ?? ''}`}>
                                        {column.name}
                                        {column?.sort && column.identifier === sortQuery.sort_key && <img className={column.identifier === sortQuery.sort_key ? `${sortQuery.sort_order === 'ASC' ? 'up' : ''}` : ``} src={iconAssets.ArrowDown.primary} alt="" />}
                                    </button>
                                </div>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading
                            ? <tr>
                                <td colSpan={columns.length}>
                                    <div className="display_flex flex_dir_column display_flex_center">
                                        <span className="wt_600 pb_10">Loading</span>
                                        <i className='immployer__list_loader' />
                                    </div>
                                </td>
                            </tr>
                            : !workers?.length
                                ? <tr>
                                    <td colSpan={columns.length} className='text_center'>
                                        No records found.
                                    </td>
                                </tr>
                                : workers.map((worker, index) =>
                                    <tr
                                        key={index}
                                        onClick={() => onGoToDetails(worker)}
                                    >
                                        <td>{worker.first_name}{worker?.last_name ? ` ${worker.last_name}` : ""}</td>
                                        <td>
                                            <div className="display_flex display_flex_center">
                                                {
                                                    worker.linked
                                                        ? <img src={iconAssets.CheckmarkCircle.success} alt="" />
                                                        : worker.invite_status === 'not-invited'
                                                            ? <img src={iconAssets.Plus.primary} alt="" />
                                                            : worker.invite_status === 'invited'
                                                                ? <img src={iconAssets.Dots3HorizCircle.primary} alt="" />
                                                                : worker.invite_status === 'refused'
                                                                    ? <img src={iconAssets.WarningTriangle.error} alt="" />
                                                                    : <img src={iconAssets.Plus.primary} alt="" />
                                                }
                                            </div>
                                        </td>
                                        <td>{worker?.nationality ?? "N/A"}</td>
                                        <td>
                                            <span className={`${worker?.permit_expire && moment.utc(worker.permit_expire).get('milliseconds') < moment.utc().get('milliseconds') ? "color_error" : "color_dark_grey"}`}>
                                                {worker?.permit_expire ? getDateString(worker.permit_expire, "MMM DD-YYYY") : "TBD"}
                                            </span>
                                        </td>
                                        <td>{worker.permit_type ? getElipsisString(worker.permit_type, 18) : "N/A"}</td>
                                        <td>{worker.job_title ? getElipsisString(worker.job_title, 15) : "N/A"}</td>
                                    </tr>
                                )
                    }
                </tbody>
            </table>
        </div>
    );
};

export default ForeignWorkersListTable;