import React from 'react';

const ErrorPage = () => {
    return (
        <div className="immployer__error__page_view">
            <h2><strong>Error 404</strong></h2>
            <br /><br />
            <h4>The page you are looking is not found!</h4>
        </div>
    );
};

export default ErrorPage;