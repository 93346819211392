import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { ILocation } from "../interfaces/entities";
import { ISaveLocation, ISortQuery } from "../interfaces/services";

export const getLocations = async (requestor: string, requestorId: number, sortQuery: ISortQuery): Promise<ILocation[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const { sort_key, sort_order } = sortQuery;

    try {
        const res = await api.get(`locations/list/${requestor}/${requestorId}`, {
            params: {
                sort_key,
                sort_order,
            },
        });
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const saveLocation = async (data: ISaveLocation) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`locations`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getLocationDetails = async (locationId: number): Promise<ILocation> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`locations/details/${locationId}`);
        return res.data?.data ?? null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};