import React from 'react';
import { useParams } from 'react-router-dom';

import EmployerDetailsLayout from '../../../layouts/details/Employer';
import { NotificationCard } from '../../../reusables/Cards';

import useGetEmployerDetails from '../../../../hooks/employers/useGetEmployerDetails';
import useGetNotifications from '../../../../hooks/notifications/useGetNotifications';

const EmployerNotificationsPage = () => {
    const { employer_id } = useParams();

    const { data: employer } = useGetEmployerDetails(employer_id);
    const { data: notifications, isLoading, error } = useGetNotifications('employer', employer_id);

    return (
        <EmployerDetailsLayout
            operation={null}
            currentTab="Notifications"
            title={employer?.legal_name ?? 'Employer Information'}
            error={!!error}
        >
            <div className='immployer__notifications__page_view'>
                {
                    isLoading
                        ?
                        <div className="display_flex flex_dir_column display_flex_center">
                            <br /><br />
                            <span className="wt_600 pb_10">Loading</span>
                            <i className='immployer__list_loader' />
                        </div>
                        : !notifications?.length
                            ? <div className="display_flex flex_dir_column display_flex_center">
                                <br /><br />
                                <span className="wt_600 pb_10">No new notifications.</span>
                            </div>
                            : notifications.map((notification, index) =>
                                <NotificationCard
                                    id={notification.id}
                                    key={index}
                                    title={notification.title}
                                    descr={notification.description}
                                    date={notification.date}
                                    actions={notification.actions ?? []}
                                    criticalAction="Action Required"
                                    isRead={notification.read}
                                />
                            )
                }
            </div>
        </EmployerDetailsLayout>
    );
};

export default EmployerNotificationsPage;