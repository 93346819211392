import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from "./rootReducer";

const persistConfig = {
    key: 'immployer',
    storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = () => {
    const middleware = [];

    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger();
        middleware.push(logger);
    };

    return middleware;
};

const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(middleware())
});

const persistor = persistStore(store);

export { store, persistor };