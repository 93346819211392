import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { IOrganizationStatus } from "../interfaces/services";

export const getOrganizationStatus = async (): Promise<IOrganizationStatus> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`/organizations/status`);
        return res.data?.data ? res.data.data : null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};