import { useQuery } from "@tanstack/react-query";

import { getEmployers } from "../../services/employers";

import { ISortQuery } from "../../interfaces/services";

const useGetEmployers = (sortQuery: ISortQuery) => {

    const queryFn = async () => {
        return await getEmployers(sortQuery);
    };

    const { sort_key, sort_order } = sortQuery;

    return useQuery({
        queryKey: ['employers_list', sort_key, sort_order],
        queryFn,
        retry: 0,
        enabled: !!sort_key && !!sort_order
    });
};

export default useGetEmployers;