import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

// import { BsX } from "react-icons/bs";

import { isModalActive, closeModal } from "../../store/slices/modal";

import useRefreshAuthToken from "../../hooks/auth/useRefreshAuthToken";
import useSignOut from "../../hooks/auth/useSignOut";

import { iconAssets } from "../../constants/assets";
import cookiesHelper from "../../helpers/cookies";

const modalName = "TIMEOUT_MODAL";
const modalTitle = "Timeout";
const modalClass = "timeout";

ReactModal.setAppElement('#root');
const TimeoutModal = () => {
    const dispatch = useDispatch();

    const isModalVisible: boolean = useSelector(isModalActive(modalName));

    const [token, setToken] = useState<string | null>(cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string));

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [signoutTimer, setSignoutTimer] = useState<number>(300);

    const { mutate: refreshAuthToken } = useRefreshAuthToken();
    const { mutate: signOut } = useSignOut();

    const afterOpenModal = () => {
        setToken(cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    };

    const onCloseModal = () => {
        const newToken = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
        if (newToken !== token) {
            setToken(newToken);
        }
        dispatch(closeModal(modalName));
    };

    const onStaySignedIn = () => {
        refreshAuthToken({
            setIsProcessing,
            setToken: setToken,
            onClose: () => dispatch(closeModal("TIMEOUT_MODAL")),
        });
    };

    useEffect(() => {
        if (isModalVisible) {
            const interval = setInterval(() => {
                setSignoutTimer((prev) => prev - 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [isModalVisible]); // eslint-disable-line

    useEffect(() => {
        if (signoutTimer <= 0) {
            dispatch(closeModal("MESSAGE_MODAL"));
            signOut();
        };
    }, [signoutTimer]); // eslint-disable-line

    useEffect(() => {
        if (isModalVisible) {
            setSignoutTimer(300);
        }
    }, [token]); // eslint-disable-line

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`immployer__modal_overlay immployer__modal_overlay__${modalClass}`}
            className={`immployer__modal_dialog immployer__modal_dialog__${modalClass}`}
            shouldCloseOnEsc={false}
            shouldCloseOnOverlayClick={false}
        >
            <div className={`immployer__modal_content immployer__modal_content__${modalClass}`}>
                {/* <button className="immployer__modal_close_btn" onClick={onCloseModal}>
                    <BsX />
                </button> */}
                <div className="immployer__session_timeout_view">
                    <br />
                    <h4 className="color_dark">Session Timeout</h4>
                    <p className="color_dark_grey">
                        Your session is about to expire. Would you like to stay signed in?
                    </p>
                    <br />
                    <div className="immployer__session_timeout_warning_content">
                        <img src={iconAssets.WarningTriangle.error} alt="" />
                        <p className="color_error">
                            You will be signed out in  {Math.floor(signoutTimer / 60)} minutes {(signoutTimer % 60).toString().padStart(2, '0')} seconds.
                        </p>
                    </div>
                    <br />
                    <div className="immployer__session_timeout_actions">
                        <button className="immployer__btn immployer__btn__secondary" onClick={() => signOut()}>Sign out</button>
                        <button
                            className={`immployer__btn immployer__btn__primary`}
                            onClick={onStaySignedIn}
                        >
                            {
                                isProcessing
                                    ? <i className='immployer__btn_loader' />
                                    : 'Stay Signed In'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default TimeoutModal;