import { useMutation, useQueryClient } from "@tanstack/react-query";

import { unlinkForeignWorker } from "../../services/foreign_workers";

import useToast from "../useToast";

export interface IPayload {
    foreignWorkerId: number;
    setIsProcessing: Function;
    onClose: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useUnlinkForeignWorker = () => {
    const queryClient = useQueryClient();

    const { addToast } = useToast();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await unlinkForeignWorker(data.foreignWorkerId);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response) {
                addToast({
                    type: 'success',
                    title: 'Foreign worker has been successfully unlinked.',
                });
                variables.onClose();
                queryClient.invalidateQueries({ queryKey: ['foreign_worker_details'] });
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useUnlinkForeignWorker;