import { Dispatch, SetStateAction } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { resendInvitation } from "../../services/invitations";

import useToast from "../useToast";

export interface IPayload {
    invitationId: number;
    setProcessRunningFor: Dispatch<SetStateAction<number | null>>;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useResendInvitation = () => {
    const queryClient = useQueryClient();

    const { addToast } = useToast();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await resendInvitation(data.invitationId);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response) {
                addToast({
                    type: 'success',
                    title: 'Success',
                    message: 'The invitation has been resent.'
                });

                queryClient.invalidateQueries({ queryKey: ['foreign_worker_invitations'] });
                queryClient.invalidateQueries({ queryKey: ['foreign_worker_details'] });
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setProcessRunningFor(null);
        },
    });
};

export default useResendInvitation;