interface ValidationMap {
    [key: string]: {
        regex: RegExp;
        errMsg: string;
        misMsg: string;
    };
};

const validationMap: ValidationMap = {
    email: {
        regex: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
        errMsg: 'Invalid email address!',
        misMsg: 'Email is required!'
    },
    password: {
        regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\w\d\s]).{8,}$/,
        errMsg: 'Invalid password! Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character required',
        misMsg: 'Password required!',
    },
    craInput: {
        regex: /^\d{9}$/,
        errMsg: 'Invalid CRA number! Minimum 9 digits required.',
        misMsg: 'CRA number required!',
    },
};

class ValidationHelper {
    validateInput(type: string, value: string): string | null {
        if (!value?.trim()?.length) {
            return validationMap[type].misMsg;
        } else if (!value.match(validationMap[type].regex)) {
            return validationMap[type].errMsg;
        } else {
            return null;
        }
    }
};

const validationHelper = new ValidationHelper();
export default validationHelper;