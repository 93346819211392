import { Dispatch, SetStateAction } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { cancelInvitation } from "../../services/invitations";

import useToast from "../useToast";

import { closeModal } from "../../store/slices/modal";

export interface IPayload {
    invitationId: number;
    setProcessRunningFor?: Dispatch<SetStateAction<number | null>>;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
};

const useCancelInvitation = () => {
    const queryClient = useQueryClient();

    const { addToast } = useToast();
    const dispatch = useDispatch();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await cancelInvitation(data.invitationId);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response) {
                dispatch(closeModal('MESSAGE_MODAL'));

                addToast({
                    type: 'success',
                    title: 'Success',
                    message: 'The invitation has been cancelled successfully.'
                });

                queryClient.invalidateQueries({ queryKey: ['foreign_worker_invitations'] });
                queryClient.invalidateQueries({ queryKey: ['foreign_worker_details'] });
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            if (variables.setProcessRunningFor) {
                variables.setProcessRunningFor(null);
            };
        },
    });
};

export default useCancelInvitation;