import React from "react";
import { useNavigate } from "react-router-dom";

import MainLayout from "../../layouts/Main";
import EmployersListTable from "../../sub_components/tables/EmployersList";

import { iconAssets } from "../../../constants/assets";

const EmployersPage = () => {
    const navigate = useNavigate();

    return (
        <MainLayout>
            <div className="immployer__lists__page_view">
                <h2 className="color_dark">Employers</h2>
                <br /><br />
                <div className="immployer__lists__top_section">
                    <button
                        onClick={() => navigate('/employers/new')}
                        className="immployer__btn immployer__btn__primary"
                    >
                        <img src={iconAssets.Plus.white} alt="" />
                        <span>Add&nbsp;Employer</span>
                    </button>
                </div>
                <EmployersListTable />
            </div>
        </MainLayout>
    );
};

export default EmployersPage;