import { useQuery } from "@tanstack/react-query";

import { getLMIAs } from "../../services/lmias";

import { ILMIA } from "../../interfaces/entities";
import { ISortQuery } from "../../interfaces/services";

const useGetLMIAs = (employerId: string | undefined, sortQuery: ISortQuery) => {
    const employer_id = employerId ? parseInt(employerId) : 0;

    const { sort_key, sort_order } = sortQuery;

    const queryFn = async (): Promise<ILMIA[]> => {
        return await getLMIAs(employer_id, sortQuery);
    };

    return useQuery({
        queryKey: ['lmias_list', employer_id, sort_key, sort_order],
        queryFn,
        enabled: employer_id > 0,
        retry: 0,
    });
};

export default useGetLMIAs;