import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { ISavePermit } from "../interfaces/services";
import { IExpiringPermitCounts } from "../interfaces/services";

export const savePermits = async (data: ISavePermit[]) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`permits`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const savePermit = async (data: ISavePermit) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`permits`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getExpiringPermits = async (): Promise<IExpiringPermitCounts | null> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`permits/expiring`);
        return res.data?.data ? res.data.data : null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const deletePermit = async (id: number) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.patch(`permits/delete/${id}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};