import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { SelectDropdown, TextInput } from "../../reusables/FormElements";

import { IEmployerDetailsProps, EmployerField } from "../../../interfaces/employer";
import { ISelectDropdownOption } from "../../../interfaces/formElements";
import { TEmployerType } from "../../../interfaces/entities.types";

import validationHelper from "../../../helpers/validators";

import useSaveEmployer from "../../../hooks/employers/useSaveEmployer";

import { OPBooleanOptions, OPEmployerTypes, OPLMIAInPastOptions, OPNAICS } from "../../../constants/options";

const EmployerDetailsFormView = (props: IEmployerDetailsProps) => {
    const navigate = useNavigate();

    const { operation, isLoading, employerData, formData, setFormData } = props;

    const requiredFields: EmployerField[] = ["legal_name", "operating_name", "type", "naics", "cra_number", "non_canadian_emp", "lmia_in_past"];
    const selectFields: EmployerField[] = ["type", "naics", "tech_company", "lmia_in_past"];

    const [isProcessing, setIsProcessing] = useState(false);

    const { mutate: saveEmployer } = useSaveEmployer();

    useEffect(() => {
        if (employerData) {
            const type = OPEmployerTypes.find(type => type.value === employerData.type);
            const naics = OPNAICS.find(naics => naics.value === employerData.naics);
            const techCompany = OPBooleanOptions.find(option => option.value === (employerData.tech_company ? 'true' : 'false'));
            const lmiaInPast = OPLMIAInPastOptions.find(option => option.value === (employerData.lmia_in_past === null ? 'null' : employerData.lmia_in_past === false ? 'false' : 'true'));

            setFormData({
                id: employerData.id,
                legal_name: { value: employerData.legal_name ?? '', error: '' },
                operating_name: { value: employerData.operating_name ?? '', error: '' },
                type: { value: { value: type?.value ?? '', label: type?.label ?? '' }, error: '' },
                naics: { value: { value: naics?.value ?? '', label: naics?.label ?? '' }, error: '' },
                cra_number: { value: employerData.cra_number ?? '', error: '' },
                tech_company: { value: { value: techCompany?.value ?? '', label: techCompany?.label ?? '' }, error: '' },
                non_canadian_emp: { value: employerData.non_canadian_emp.toString() ?? '', error: '' },
                lmia_in_past: { value: { value: lmiaInPast?.value ?? '', label: lmiaInPast?.label ?? '' }, error: '' },
            });
        };

        // eslint-disable-next-line
    }, [employerData])

    const onChangeText = (name: EmployerField, value: string) => {
        setFormData({
            ...formData,
            [name]: { value, error: "" }
        });
    };

    const onChangeSelect = (name: EmployerField, option: ISelectDropdownOption) => {
        setFormData(prevData => {
            return {
                ...prevData,
                [name]: { value: option, error: "" }
            };
        });
    };

    const isDisabled = () => {
        const emptyFields = requiredFields.some(field => {
            let value = "";
            if (selectFields.includes(field)) {
                const dataValue = formData[field].value as ISelectDropdownOption;
                value = dataValue.value;
            } else {
                value = formData[field].value as string;
            };
            return !value?.trim()?.length;
        });

        const isDataSame =
            formData.legal_name.value === (employerData?.legal_name ?? '') &&
            formData.operating_name.value === (employerData?.operating_name ?? '') &&
            formData.type.value.value === (employerData?.type ?? '') &&
            formData.naics.value.value === (employerData?.naics ?? '') &&
            formData.cra_number.value === (employerData?.cra_number ?? '') &&
            formData.tech_company.value.value === (employerData?.tech_company ? 'true' : 'false') &&
            formData.non_canadian_emp.value === (employerData?.non_canadian_emp?.toString() ?? '') &&
            formData.lmia_in_past.value.value === (employerData?.lmia_in_past === null ? 'null' : employerData?.lmia_in_past === false ? 'false' : 'true');

        return emptyFields || isDataSame || isProcessing || isLoading;
    };

    const onSaveData = () => {
        if (isDisabled()) return;

        // clear all errors
        setFormData(prevData => {
            const newData = { ...prevData };
            Object.keys(newData).forEach(key => {
                if (key === "id") return;
                newData[key as EmployerField].error = "";
            });
            return newData;
        });

        const craNumberValError = validationHelper.validateInput("craInput", formData.cra_number.value);
        if (craNumberValError) {
            setFormData({
                ...formData,
                cra_number: { ...formData.cra_number, error: craNumberValError }
            });
            return;
        };

        setIsProcessing(true);

        saveEmployer({
            payload: {
                id: formData.id,
                organization_id: null,
                legal_name: formData.legal_name.value,
                operating_name: formData.operating_name.value,
                type: formData.type.value.value as TEmployerType,
                naics: formData.naics.value.value,
                cra_number: formData.cra_number.value,
                tech_company: formData.tech_company.value.value === 'true',
                non_canadian_emp: formData.non_canadian_emp.value?.trim() ? parseInt(formData.non_canadian_emp.value) : 0,
                lmia_in_past: formData.lmia_in_past.value.value === 'null' ? null : formData.lmia_in_past.value.value === 'true' ? true : false,
            },
            operation,
            setIsProcessing,
        });
    };

    return (
        <div className='immployer__details_form_view'>
            <div className="immployer__details_form_inputs__container">
                <div className="immployer__details_form_inputs">
                    <TextInput
                        name="cra_number"
                        type="text"
                        max_length={9}
                        pattern_mask="integer"
                        label="CRA Number"
                        required={true}
                        errorMsg={formData.cra_number.error}
                        placeholder="CRA Number"
                        onChange={(value: string) => onChangeText("cra_number", value)}
                        value={formData.cra_number.value}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="legal_name"
                        type="text"
                        max_length={100}
                        label="Legal Name"
                        required={true}
                        errorMsg={formData.legal_name.error}
                        placeholder="Legal Name"
                        onChange={(value: string) => onChangeText("legal_name", value)}
                        value={formData.legal_name.value}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="operating_name"
                        type="text"
                        max_length={100}
                        label="Operating Name"
                        required={true}
                        errorMsg={formData.operating_name.error}
                        placeholder="Operating Name"
                        onChange={(value: string) => onChangeText("operating_name", value)}
                        value={formData.operating_name.value}
                        read_only={isLoading}
                    />
                    <SelectDropdown
                        name="naics"
                        label="Industry Type"
                        required={true}
                        placeholder="-- select industry type --"
                        value={formData.naics.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("naics", option)}
                        errorMsg={formData.naics.error}
                        options={OPNAICS}
                        read_only={isLoading}
                    />
                    <SelectDropdown
                        name="type"
                        label="Organization Type"
                        required={true}
                        placeholder="-- select organization type --"
                        value={formData.type.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("type", option)}
                        errorMsg={formData.type.error}
                        options={OPEmployerTypes}
                        read_only={isLoading}
                    />
                    {/* <SelectDropdown
                        name="tech_company"
                        label="Is this a considered a tech company or does it have internal tech employees?"
                        required={true}
                        placeholder="-- select --"
                        value={formData.tech_company.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("tech_company", option)}
                        errorMsg={formData.tech_company.error}
                        options={OPBooleanOptions}
                        read_only={isLoading}
                        length_flex={2}
                    /> */}
                    <TextInput
                        name="non_canadian_emp"
                        type="text"
                        label="Approximately, how many non-Canadianss are currently employed?"
                        pattern_mask="integer"
                        max_length={10}
                        required={true}
                        errorMsg={formData.non_canadian_emp.error}
                        placeholder="Number of non-Canadian employees"
                        onChange={(value: string) => onChangeText("non_canadian_emp", value)}
                        value={formData.non_canadian_emp.value}
                        read_only={isLoading}
                        length_flex={2}
                    />
                    <SelectDropdown
                        name="lmia_in_past"
                        label="Has this CRA number ever been used to apply for an LMIA or LMIA-exempt application?"
                        required={true}
                        placeholder="-- select --"
                        value={formData.lmia_in_past.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("lmia_in_past", option)}
                        errorMsg={formData.lmia_in_past.error}
                        options={OPLMIAInPastOptions}
                        read_only={isLoading}
                        length_flex={2}
                    />
                </div>
            </div>
            <div className="immployer__details_form_actions">
                <button
                    className={`immployer__btn immployer__btn__secondary ${isProcessing ? "immployer__btn__secondary__disabled" : ""}`}
                    onClick={() => isProcessing ? null : navigate('/employers')}
                >
                    {
                        operation === 'create'
                            ? "Discard"
                            : "Go back"
                    }
                </button>
                <button
                    className={`immployer__btn immployer__btn__primary ${isDisabled() ? "immployer__btn__primary__disabled" : ""}`}
                    onClick={onSaveData}
                >
                    {
                        isProcessing
                            ? <i className='immployer__btn_loader' />
                            : 'Save'
                    }
                </button>
            </div>
        </div>
    );
};

export default EmployerDetailsFormView;