import { useQuery } from "@tanstack/react-query";

import { getExpiringPermits } from "../../services/permits";

import { IExpiringPermitCounts } from "../../interfaces/services";

const useGetExpiringPermits = () => {

    const queryFn = async (): Promise<IExpiringPermitCounts | null> => {
        return await getExpiringPermits();
    };

    return useQuery({
        queryKey: ['expiring_permits'],
        queryFn,
        retry: 0,
    });
};

export default useGetExpiringPermits;