import React from "react";
import { useLocation, useParams } from "react-router-dom";

import WorkerDetailsLayout from "../../../layouts/details/Worker";
import { NotificationCard } from "../../../reusables/Cards";

import useGetForeignWorkerDetails from "../../../../hooks/foreign_workers/useGetForeignWorkerDetails";
import useGetForeignWorkerInvitations from "../../../../hooks/invitations/useGetForeignWorkerInvitations";
import useGetNotifications from "../../../../hooks/notifications/useGetNotifications";
import useGetWorkerJobs from "../../../../hooks/worker_jobs/useGetWorkerJobs";

const ForeignWorkerNotificationsPage = () => {
    const { worker_id } = useParams();
    const location = useLocation();

    const { data: worker } = useGetForeignWorkerDetails(worker_id);
    const { data: invitations } = useGetForeignWorkerInvitations(worker_id);
    const { data: notifications, isLoading, error } = useGetNotifications('foreign-worker', worker_id);
    const { data: workerJobs } = useGetWorkerJobs(worker_id);

    return (
        <WorkerDetailsLayout
            goBackRoute={location.state?.goBackRoute ?? null}
            operation='update'
            currentTab="Notifications"
            title={worker?.worker_details?.length ? `${worker.worker_details[0].first_name} ${worker.worker_details[0].last_name}` : 'Worker Notifications'}
            error={!!error}
            invitations={invitations ?? []}
            linkedStatus={{
                isDataAvailable: !!worker?.worker_details[0],
                linked: !!worker?.worker_details[0]?.linked,
                linkedDate: worker?.worker_details[0]?.date_linked ?? null,
                unlinkedDate: worker?.worker_details[0]?.date_unlinked ?? null,
            }}
            isEmploymentAdded={!!workerJobs?.length}
        >
            <div className='immployer__notifications__page_view'>
                {
                    isLoading
                        ?
                        <div className="display_flex flex_dir_column display_flex_center">
                            <br /><br />
                            <span className="wt_600 pb_10">Loading</span>
                            <i className='immployer__list_loader' />
                        </div>
                        : !notifications?.length
                            ? <div className="display_flex flex_dir_column display_flex_center">
                                <br /><br />
                                <span className="wt_600 pb_10">No new notifications.</span>
                            </div>
                            : notifications.map((notification, index) =>
                                <NotificationCard
                                    id={notification.id}
                                    key={index}
                                    title={notification.title}
                                    descr={notification.description}
                                    date={notification.date}
                                    actions={notification.actions ?? []}
                                    criticalAction="Action Required"
                                    isRead={notification.read}
                                />
                            )
                }
            </div>
        </WorkerDetailsLayout >
    );
};

export default ForeignWorkerNotificationsPage;