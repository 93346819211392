import { useQuery } from "@tanstack/react-query";

import { getLMIATypes } from "../../services/lmia_types";

import { ILMIAType } from "../../interfaces/entities";

const useGetLMIATypes = () => {

    const queryFn = async (): Promise<ILMIAType[]> => {
        return await getLMIATypes();
    };

    return useQuery({
        queryKey: ['lmia_types'],
        queryFn,
        retry: 0,
    });
};

export default useGetLMIATypes;