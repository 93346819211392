import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { TextInput } from "../../reusables/FormElements";

import validationHelper from "../../../helpers/validators";

import useResetPassword from "../../../hooks/auth/useResetPassword";
import { getFormattedString } from "../../../helpers/utility";

const ResetPasswordView = () => {
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const [secondsLeft, setSecondsLeft] = useState(5);


    const userName = searchParams.get("user_name") || null;
    const inviteToken = searchParams.get("invite_token") || null;

    const [password, setPassword] = useState({ value: "", error: "" });
    const [confirmPassword, setConfirmPassword] = useState({ value: "", error: "" });
    const [isProcessing, setIsProcessing] = useState(false);
    const [apiError, setApiError] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);

    const { mutate: resetPassword } = useResetPassword();

    const onResetPassword = () => {
        if (isProcessing) return;

        setPassword({ ...password, error: "" });
        setConfirmPassword({ ...confirmPassword, error: "" });
        setApiError("");
        setIsSuccess(false);

        const passwordValError = validationHelper.validateInput("password", password.value);
        if (passwordValError) {
            setPassword({ ...password, error: passwordValError });
        };

        const confirmPasswordValError = validationHelper.validateInput("password", confirmPassword.value);
        if (password.value !== confirmPassword.value) {
            setConfirmPassword({ ...confirmPassword, error: "Passwords do not match" });
        } else if (confirmPasswordValError) {
            setConfirmPassword({ ...confirmPassword, error: confirmPasswordValError });
        };

        if (passwordValError || confirmPasswordValError) return;

        setIsProcessing(true);

        const token = inviteToken || searchParams.get("reset_token") || "";

        resetPassword({
            token,
            creds: {
                password: password.value
            },
            setIsSuccess,
            setApiError,
            setIsProcessing,
        });
    };

    // redirecting in 5 seconds to sign in page if password is set successfully function below
    useEffect(() => {
        if (isSuccess && inviteToken) {
            const interval = setInterval(() => {
                setSecondsLeft((prev) => prev - 1);
            }, 1000);

            if (secondsLeft === 0) {
                clearInterval(interval);
                navigate("/auth/sign-in", { replace: true });
            };

            return () => clearInterval(interval);
        };

    }, [isSuccess, inviteToken, secondsLeft, setSearchParams, navigate])

    return (
        <div className="immployer__auth__form_view immployer__auth__reset_password_form_view">
            {(userName && inviteToken)
                ? <div className="immployer__auth__reset_password_form_view_header">
                    <h2 className="color_dark">Welcome&nbsp;{getFormattedString(userName.split(' ')[0], 'title_case')},</h2>
                    <h5 className="color_dark">Set your password to get started,</h5>
                </div>
                : <h3 className="color_dark">Reset Password</h3>
            }
            <hr />
            <TextInput
                name="password"
                type="password"
                label="Password"
                required={true}
                errorMsg={password.error}
                placeholder="Create a password"
                onChange={(value: string) => setPassword({ ...password, value })}
                value={password.value}
            />
            <br />
            <TextInput
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                required={true}
                errorMsg={confirmPassword.error}
                placeholder="Re-enter your password"
                onChange={(value: string) => setConfirmPassword({ ...confirmPassword, value })}
                value={confirmPassword.value}
            />
            <br /><br />
            <button
                className="btn_block immployer__btn immployer__btn__primary"
                onClick={onResetPassword}
                disabled={isProcessing || (isSuccess && !!inviteToken)}
            >
                {
                    isProcessing
                        ? <i className='immployer__btn_loader' />
                        : inviteToken ? 'Set Password' : 'Reset Password'
                }
            </button>
            {
                apiError?.length > 0
                    ? <p className="immployer__auth_api_error">
                        {inviteToken ? apiError : 'The password reset link is invalid or has expired. Please request a new link.'}
                    </p>
                    : isSuccess
                        ? <p className="immployer__auth_api_success">
                            Your password has been {inviteToken ? 'set' : 'reset'} successfully.< br />
                            {inviteToken ? <>Redirecting to Sign in {secondsLeft} seconds.< br /></> : ''}
                            <Link to="/auth/sign-in" replace={true} className="color_grey display_inline wt_500">Sign In now</Link>
                        </p>
                        : null
            }
            <hr />
            {(!userName || !inviteToken) &&
                <p className="color_dark_grey text_center">Sign in instead? <Link to="/auth/sign-in" className="color_grey display_inline wt_500">Sign In</Link></p>
            }
        </div>
    );
};

export default ResetPasswordView;