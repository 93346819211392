import { useQuery } from "@tanstack/react-query";

import { getForeignWorkerInvitations } from "../../services/invitations";

import { IForeignWorkerInvitation } from "../../interfaces/entities";

const useGetForeignWorkerInvitations = (foreignWorkerId: string | undefined) => {
    const foreign_worker_id = foreignWorkerId ? parseInt(foreignWorkerId) : 0;

    const queryFn = async (): Promise<IForeignWorkerInvitation[]> => {
        return await getForeignWorkerInvitations(foreign_worker_id);
    };

    return useQuery({
        queryKey: ['foreign_worker_invitations', foreign_worker_id],
        queryFn,
        retry: 0,
        enabled: (foreign_worker_id ?? 0) > 0,
    });
};

export default useGetForeignWorkerInvitations;