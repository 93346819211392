import React, { useEffect, useState } from "react";
import moment from "moment";

import { BiChevronLeft, BiChevronRight, BiChevronsLeft, BiChevronsRight } from "react-icons/bi";

type CurrentView = 'days' | 'months' | 'years';

const oDays = [
    { vshort: 'Su', short: 'Sun', full: 'Sunday' },
    { vshort: 'Mo', short: 'Mon', full: 'Monday' },
    { vshort: 'Tu', short: 'Tue', full: 'Tuesday' },
    { vshort: 'We', short: 'Wed', full: 'Wednesday' },
    { vshort: 'Th', short: 'Thu', full: 'Thursday' },
    { vshort: 'Fr', short: 'Fri', full: 'Friday' },
    { vshort: 'Sa', short: 'Sat', full: 'Saturday' }
];
const oMonths = [
    { vshort: 'Jan', short: 'Jan', full: 'January' },
    { vshort: 'Feb', short: 'Feb', full: 'February' },
    { vshort: 'Mar', short: 'Mar', full: 'March' },
    { vshort: 'Apr', short: 'April', full: 'April' },
    { vshort: 'May', short: 'May', full: 'May' },
    { vshort: 'Jun', short: 'June', full: 'June' },
    { vshort: 'Jul', short: 'July', full: 'July' },
    { vshort: 'Aug', short: 'Aug', full: 'August' },
    { vshort: 'Sep', short: 'Sept', full: 'September' },
    { vshort: 'Oct', short: 'Oct', full: 'October' },
    { vshort: 'Nov', short: 'Nov', full: 'November' },
    { vshort: 'Dec', short: 'Dec', full: 'December' }
];
const oYears = Array.from({ length: 141 }, (_, i) => i + 1960);

const MAX_GRID_DAYS = 42;

interface IDateState {
    month: 'current' | 'prev' | 'next';
    date: number;
};

interface ICalenderProps {
    selected: Date;
    setSelected: (date: Date) => void;
};

const Calender = (props: ICalenderProps) => {
    const { selected, setSelected } = props;

    const [currentView, setCurrentView] = useState<CurrentView>('days');
    const [dates, setDates] = useState<IDateState[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date>(moment.utc().toDate());

    useEffect(() => {
        setSelectedDate(selected);
    }, [selected]);

    const getDaysInMonth = (year: number, month: number): number => {
        return moment.utc(`${year}-${(month + 1) < 10 ? '0' : ''}${month + 1}`, "YYYY-MM").daysInMonth();
    };

    const getFirstDayOfMonth = (year: number, month: number): number => {
        return moment.utc(`${year}-${(month + 1) < 10 ? '0' : ''}${month + 1}`, "YYYY-MM").day();
    };

    useEffect(() => {
        const daysInMonth = getDaysInMonth(moment.utc(selectedDate).get('year'), moment.utc(selectedDate).get('month'));
        const dates: IDateState[] = Array.from({ length: daysInMonth }, (_, i) => ({ month: 'current', date: (i + 1) }));
        const firstDayOfMonth = getFirstDayOfMonth(moment.utc(selectedDate).get('year'), moment.utc(selectedDate).get('month'));
        const preDaysCt = firstDayOfMonth;
        const daysInPrevMonth = getDaysInMonth(moment.utc(selectedDate).get('year'), moment.utc(selectedDate).get('month'));
        const preDays: IDateState[] = Array.from({ length: preDaysCt }, (_, i) => ({ month: 'prev', date: (daysInPrevMonth - preDaysCt + i + 1) }));
        const postDaysCt = MAX_GRID_DAYS - (preDaysCt + daysInMonth);
        const postDays: IDateState[] = Array.from({ length: postDaysCt }, (_, i) => ({ month: 'next', date: (i + 1) }));
        dates.unshift(...preDays);
        dates.push(...postDays);
        setDates(dates);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moment.utc(selectedDate).get('year'), moment.utc(selectedDate).get('month')]);

    const onNavigateMonthYear = (action: string, trgtDt?: number): Date => {
        let trgtYear = moment.utc(selectedDate).get('year');
        let trgtMonth = moment.utc(selectedDate).get('month');
        let trgtDate = trgtDt ?? moment.utc(selectedDate).get('date')

        if (action === 'prev_month') {
            if (trgtMonth === 0) {
                trgtMonth = 11;
                trgtYear = trgtYear - 1;
            } else {
                trgtMonth = trgtMonth - 1;
            };
        } else if (action === 'next_month') {
            if (trgtMonth === 11) {
                trgtMonth = 0;
                trgtYear = trgtYear + 1;
            } else {
                trgtMonth = trgtMonth + 1;
            };
        } else if (action === 'prev_year') {
            trgtYear = trgtYear - 1;
        } else if (action === 'next_year') {
            trgtYear = trgtYear + 1;
        };

        if (action === 'prev_month' || action === 'prev_year') {
            const daysInMonth = getDaysInMonth(trgtYear, trgtMonth);
            if (trgtDate > daysInMonth) {
                trgtDate = daysInMonth;
            };
        };

        if (action === 'next_month' || action === 'next_year') {
            const daysInMonth = getDaysInMonth(trgtYear, trgtMonth);
            if (trgtDate > daysInMonth) {
                trgtDate = 1;
            };
        };

        const returnDate = moment.utc([trgtYear, trgtMonth, trgtDate]).toDate();
        setSelectedDate(returnDate);
        return returnDate;
    };

    const onSelectDate = (date: IDateState) => {
        if (date.month === 'current') {
            setSelected(moment.utc([moment.utc(selectedDate).get('year'), moment.utc(selectedDate).get('month'), date.date]).toDate());
        } else if (date.month === 'prev') {
            const selection = onNavigateMonthYear('prev_month', date.date);
            setSelected(selection);
        } else if (date.month === 'next') {
            const selection = onNavigateMonthYear('next_month', date.date);
            setSelected(selection);
        };
    };

    const onSetToday = () => {
        setSelected(moment.utc().toDate());
    };

    const onSelectMonth = (month: number) => {
        setSelectedDate(moment.utc([moment.utc(selectedDate).get('year'), month, moment.utc(selectedDate).get('date')]).toDate());
        setCurrentView('days');
    };

    const onSelectYear = (year: number) => {
        setSelectedDate(moment.utc([year, moment.utc(selectedDate).get('month'), moment.utc(selectedDate).get('date')]).toDate());
        setCurrentView('days');
    };

    const onSetView = (view: CurrentView) => {
        setCurrentView(view);
        setTimeout(() => {
            if (view === 'years') {
                const el = document.querySelector('.immployer__calender__year__btn__selected');
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        }, 500);
    };

    return (
        <div className="immployer__calender_component">
            <div className={`immployer__calender__top ${currentView !== 'days' ? 'display_flex_center_horiz' : ''}`}>
                {currentView === 'days' &&
                    <div className="immployer__calender__top__btn_grp">
                        <button className="immployer__calender__icon_btn" onMouseDown={() => onNavigateMonthYear('prev_year')}><BiChevronsLeft /></button>
                        <button className="immployer__calender__icon_btn" onMouseDown={() => onNavigateMonthYear('prev_month')}><BiChevronLeft /></button>
                    </div>
                }
                <div className="immployer__calender__top__btn_grp">
                    {currentView !== 'years' &&
                        <button
                            className="immployer__calender__text_btn"
                            onMouseDown={() => onSetView(currentView === 'months' ? 'days' : 'months')}
                        >
                            {oMonths[moment.utc(selectedDate).get('month')][currentView === 'months' ? 'full' : 'short']}
                        </button>
                    }
                    {currentView !== 'months' &&
                        <button
                            className="immployer__calender__text_btn"
                            onMouseDown={() => onSetView(currentView === 'years' ? 'days' : 'years')}
                        >
                            {moment.utc(selectedDate).get('year')}
                        </button>
                    }
                </div>
                {currentView === 'days' &&
                    <div className="immployer__calender__top__btn_grp">
                        <button className="immployer__calender__icon_btn" onMouseDown={() => onNavigateMonthYear('next_month')}><BiChevronRight /></button>
                        <button className="immployer__calender__icon_btn" onMouseDown={() => onNavigateMonthYear('next_year')}><BiChevronsRight /></button>
                    </div>
                }
            </div>
            <div className="immployer__calender__body">
                {currentView === 'days' &&
                    <div className="immployer__calender__days_view">
                        <div className="immployer__calender__days">
                            {oDays.map((day, index) =>
                                <div key={index} className="immployer__calender__day">{day.short.toUpperCase()}</div>
                            )}
                        </div>
                        <div className="immployer__calender__dates">
                            {dates.map((date, index) =>
                                <button
                                    key={index}
                                    className={`immployer__calender__date__btn ${date.month !== 'current' ? 'color_grey' : ''} ${((moment.utc(selectedDate).get('date') === date.date) && (date.month === 'current')) ? 'immployer__calender__date__btn__selected' : ''}`}
                                    onMouseDown={() => onSelectDate(date)}
                                >
                                    {date.date}
                                </button>
                            )}
                        </div>
                    </div>
                }
                {currentView === 'months' &&
                    <div className="immployer__calender__months_view">
                        {oMonths.map((month, index) =>
                            <button
                                key={index}
                                className={`immployer__calender__month__btn ${moment.utc(selectedDate).get('month') === index ? 'immployer__calender__month__btn__selected' : ''}`}
                                onMouseDown={() => onSelectMonth(index)}
                            >
                                {month.vshort.toUpperCase()}
                            </button>
                        )}
                    </div>
                }
                {currentView === 'years' &&
                    <div className="immployer__calender__years_view">
                        {oYears.map((year, index) =>
                            <button
                                key={index}
                                className={`immployer__calender__year__btn ${moment.utc(selectedDate).get('year') === year ? 'immployer__calender__year__btn__selected' : ''}`}
                                onMouseDown={() => onSelectYear(year)}
                            >
                                {year}
                            </button>
                        )}
                    </div>
                }
            </div>
            <div className="immployer__calender__bottom">
                <button className="immployer__calender__text_btn" onMouseDown={onSetToday}>Today</button>
            </div>
        </div>
    );
};

export default Calender;