import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { INotification } from "../interfaces/entities";
import { ICreateNotification } from "../interfaces/services";

export const getNotifications = async (requestor: string, requestorId: number): Promise<INotification[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`notifications/list/${requestor}/${requestorId}`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const createNotifications = async (notifications: ICreateNotification[]) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.post(`notifications`, notifications);
        return response.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
}

export const markNotificationAsRead = async (notificationId: number) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.patch(`notifications/read/${notificationId}`);
        return response.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
}