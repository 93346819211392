import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { IChangeLogWithDate, IForeignWorkerListItem, ISortQuery, ISaveForeignWorker, IWorkerImmigrationDocs, IApplicantProgramPayload, ICombinedApplicantScores, ICombinedApplicantPrograms, ICombinedApplicantScoreHypoGroups } from "../interfaces/services";
import { IForeignWorker } from "../interfaces/entities";

export const getForeignWorkersByLocation = async (locationId: number): Promise<IForeignWorker[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`foreign-workers/location/${locationId}`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getForeignWorkersByOrganization = async (): Promise<IForeignWorker[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`foreign-workers/organization`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getForeignWorkersDataList = async (requestor: string, employerId: number, sortQuery: ISortQuery): Promise<IForeignWorkerListItem[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const { sort_key, sort_order } = sortQuery;

    try {
        const res = await api.get(`foreign-workers/data-list/${requestor}/${employerId}`, {
            params: {
                sort_key,
                sort_order
            }
        });
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const saveForeignWorker = async (data: ISaveForeignWorker) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`foreign-workers`, data);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getForeignWorkerDetails = async (foreignWorkerId: number): Promise<IForeignWorker> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`foreign-workers/details/${foreignWorkerId}`);
        return res.data?.data ?? null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getWorkerImmigrationDocs = async (foreignWorkerId: number): Promise<IWorkerImmigrationDocs> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`foreign-workers/immigration-docs/${foreignWorkerId}`);
        return res.data?.data ?? null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const unlinkForeignWorker = async (foreignWorkerId: number) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`foreign-workers/unlink/${foreignWorkerId}`);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getForeignWorkerChangeLog = async (foreignWorkerId: number): Promise<IChangeLogWithDate[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`foreign-workers/change-log/${foreignWorkerId}`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getForeignWorkerApplicantScores = async (foreignWorkerId: number): Promise<ICombinedApplicantScores> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`foreign-workers/applicant-scores/${foreignWorkerId}`);
        return res.data?.data ?? null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getForeignWorkerApplicantPrograms = async (payload: IApplicantProgramPayload): Promise<ICombinedApplicantPrograms> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const { foreign_worker_id, category_group } = payload;

    try {
        const res = await api.get(`foreign-workers/applicant-programs/${foreign_worker_id}/${category_group}`);
        return res.data?.data ?? null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getForeignWorkerApplicantScoreHypoGroups = async (foreignWorkerId: number): Promise<ICombinedApplicantScoreHypoGroups> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`foreign-workers/applicant-score-hypo-groups/${foreignWorkerId}`);
        return res.data?.data ?? null;
    } catch (error: any) {
        throw error?.response?.data;
    };
};
