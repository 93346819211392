import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { CancelTokenSource } from 'axios';
import moment from 'moment';

import useParseDocument from '../../../hooks/documents/useParseDocument';
import useGetPreSignedUrl from '../../../hooks/documents/useGetPreSignedUrl';
import useDeleteDocument from '../../../hooks/documents/useDeleteDocument';
import useGetDocumentExpiryNotification from '../../../hooks/expiry_schedules/useGetDocumentExpiryNotification';
import useDeletePassport from '../../../hooks/passports/useDeletePassport';

import { IPassportData, PassportField } from '../../../interfaces/foreign_worker';
import { ISelectDropdownOption } from '../../../interfaces/formElements';
import { IPassport, IPermit, IVisa } from '../../../interfaces/entities';
import { IGenericDocument } from '../../../interfaces/services';
import { TDocStatus } from '../../../interfaces/entities.types';

import { closeModal, openModal } from '../../../store/slices/modal';

import { OPCountries, OPDocumentStatuses } from '../../../constants/options';

import UploadFileBtn from './UploadFileBtn';
import PreviewPanel from './PreviewPanel';
import ImmigrationFormAction from './ImmigrationFormAction';
import { CalenderInput, SearchSelect, TextInput } from '../../reusables/FormElements';

import { getDateString } from '../../../helpers/utility';

interface IPassportFormProps {
    index: number;
    passportData: IPassport | null;
    visasData: IVisa[];
    permitsData: IPermit[];
    linked: boolean;
    isLoading: boolean;
    hasUnsavedData: { [key: number]: boolean; };
    setPassportDatas: Dispatch<SetStateAction<(IPassport | null)[]>>;
    setIsGlobalLoading: Dispatch<SetStateAction<{ [key: number]: boolean }>>;
    setHasUnsavedData: Dispatch<SetStateAction<{ [key: number]: boolean }>>;
}

const PassportForm = (props: IPassportFormProps) => {
    const { worker_id } = useParams();
    const dispatch = useDispatch();

    const { passportData, setPassportDatas, visasData, permitsData, linked, isLoading, index, hasUnsavedData, setIsGlobalLoading, setHasUnsavedData } = props;

    const requiredFields: PassportField[] = ['passportNumber'];
    const selectFields: PassportField[] = ['nationality', 'status'];

    const initialFormData: IPassportData = {
        id: null,
        document_id: { value: 0, error: "" },
        passportNumber: { value: "", error: "" },
        nationality: { value: { value: "", label: "" }, error: "" },
        submissionDate: { value: "", error: "" },
        issueDate: { value: "", error: "" },
        expiryDate: { value: "", error: "" },
        status: { value: { value: "", label: "" }, error: "" },
        document: null,
    };

    const [formData, setFormData] = useState<IPassportData>(initialFormData);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFileProcessing, setIsFileProcessing] = useState(false);
    const [apiError, setApiError] = useState<string | null>(null);
    const [signedUrl, setSignedUrl] = useState<string | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [cancelParse, setCancelParse] = useState<CancelTokenSource | null>(null);

    const { mutate: parseDocument } = useParseDocument();
    const { mutate: getPreSignedUrl } = useGetPreSignedUrl();
    const { mutate: deleteDocument } = useDeleteDocument();
    const { mutate: getDocumentExpiryNotifications } = useGetDocumentExpiryNotification();
    const { mutate: deletePassport } = useDeletePassport();

    const getFormData = () => {
        if (passportData) {
            const passportDetail = passportData.passport_details[0];
            const nationality = OPCountries.find(country => country.value === passportDetail.nationality);
            const submissionDate = passportDetail.submission_date ? getDateString(passportDetail.submission_date) : null;
            const issueDate = passportDetail.issued ? getDateString(passportDetail.issued) : null;
            const expiryDate = passportDetail.expire ? getDateString(passportDetail.expire) : null;
            const status = OPDocumentStatuses.find(status => status.value === passportDetail.status);

            return {
                id: passportData.id,
                document_id: { value: passportDetail.document_id, error: "" },
                passportNumber: { value: passportDetail.passport_number, error: "" },
                nationality: { value: { value: nationality?.value ?? '', label: nationality?.label ?? '' }, error: "" },
                submissionDate: { value: submissionDate ?? '', error: "" },
                issueDate: { value: issueDate ?? '', error: "" },
                expiryDate: { value: expiryDate ?? '', error: "" },
                status: { value: { value: status?.value ?? '', label: status?.label ?? '' }, error: "" },
                document: passportDetail.document,
            };
        } else {
            return initialFormData;
        }
    }

    const hasGlobalUnsavedChanges = () => {
        return Object.entries(hasUnsavedData).some(([key, value]) => Number(key) !== index && value);
    }

    const onDiscardChanges = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (isDataChanged() && !hasGlobalUnsavedChanges())
            setFormData(getFormData());
    }

    const onChangeText = (name: PassportField, value: string) => {
        setFormData({ ...formData, [name]: { value, error: "" } });
    }

    const onChangeSelect = (name: PassportField, option: ISelectDropdownOption) => {
        setFormData({ ...formData, [name]: { value: option, error: "" } });
    }

    const isDataChanged = () => {
        const originalData = getFormData();
        return !isEqual(originalData, formData);
    }

    const isDisabled = (reason: string | null) => {
        const emptyFields = Object.keys(formData).some(field => {
            return requiredFields.some(field => {
                let value = "";
                if (selectFields.includes(field)) {
                    const dataValue = formData[field].value as ISelectDropdownOption;
                    value = dataValue.value;
                } else {
                    value = formData[field].value as string;
                }
                return !value?.trim()?.length;
            });
        });

        const commonDisabled = !worker_id || linked || isLoading || hasGlobalUnsavedChanges() || isProcessing || isFileProcessing || hasGlobalUnsavedChanges();

        if (reason === 'save') {
            return commonDisabled || emptyFields || !isDataChanged();
        }

        return commonDisabled;
    }

    const onDeletePassport = async () => {
        if (!worker_id) return;

        if (formData.id !== null) {
            deletePassport({
                foreignWorkerId: Number(worker_id),
                payload: { passportId: Number(formData.id) },
                setIsProcessing,
                onSuccess: () => {
                    setIsGlobalLoading({ [index]: false });
                    setHasUnsavedData({ [index]: false });
                    setPassportDatas(prevData => prevData.filter((_, i) => i !== index));
                }
            });
        } else {
            setIsGlobalLoading({ [index]: false });
            setHasUnsavedData({ [index]: false });
            setPassportDatas(prevData => prevData.filter((_, i) => i !== index));
        }
    }

    const isOkToDelete = () => {
        return !linked && !hasGlobalUnsavedChanges() && (formData.passportNumber.value?.trim()?.length ||
            formData.nationality.value.value?.trim()?.length ||
            formData.submissionDate.value?.trim()?.length ||
            formData.issueDate.value?.trim()?.length ||
            formData.expiryDate.value?.trim()?.length ||
            formData.status.value.value?.trim()?.length);
    }

    const onConfirmDelete = (e: React.MouseEvent) => {
        e.preventDefault();

        if (isProcessing || !isOkToDelete()) return;

        if (formData.id) {
            const linkedVisas = visasData.filter(visa => visa.passport_id === formData.id);
            const linkedPermits = permitsData?.filter(permit => permit.passport_id === formData.id);

            if (linkedVisas.length || linkedPermits.length) {
                dispatch(openModal({
                    name: 'MESSAGE_MODAL',
                    data: {
                        view: 'WARNING',
                        closeBtn: true,
                        title: "Cannot Delete!",
                        subtitle: "This passport is linked to visas and/or permits. Please DELETE the following records first. ",
                        content: [...linkedVisas.map(visa => `Visa: ${visa.visa_details[0].visa_number}`), ...linkedPermits.map(permit => `Permit: ${permit.permit_details[0].permit_number}`)],
                        cancelTxt: "Understood",
                        cancelFn: () => dispatch(closeModal('MESSAGE_MODAL')),
                    }
                }));
                return;
            }
        }

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: "Confirm delete?",
                content: "Do you want to delete this passport? This action is irreversible.",
                actionTxt: "Confirm",
                action: async () => {
                    await onDeletePassport();
                    dispatch(closeModal('MESSAGE_MODAL'));
                },
            }
        }));
    };

    const onSaveData = () => {
        if (isDisabled('save')) return;

        setApiError(null);

        setFormData(prevData => {
            return Object.keys(prevData).reduce((acc, key) => {
                if (key === 'id') return { ...acc, id: prevData.id };
                if (key === 'document') return { ...acc, document: prevData.document };
                return {
                    ...acc,
                    [key]: { value: prevData[key as PassportField].value, error: "" }
                }
            }, {} as IPassportData);
        });

        setIsProcessing(true);

        const genericDocument: IGenericDocument = {
            foreign_worker_id: worker_id ? Number(worker_id) : 0,
            documentMetadata: [
                {
                    id: formData.id ?? 0,
                    type: "passport",
                    doc_number: formData.passportNumber.value ?? "",
                    expiry_date: formData.expiryDate.value ? moment.utc(formData.expiryDate.value).toDate() : null,
                    issue_date: formData.issueDate.value ? moment.utc(formData.issueDate.value).toDate() : null,
                }
            ]
        }

        const payload = {
            id: formData.id,
            foreign_worker_id: worker_id ? Number(worker_id) : 0,
            passport_number: formData.passportNumber.value,
            nationality: formData.nationality.value.value?.length ? formData.nationality.value.value : null,
            submission_date: formData.submissionDate.value ? moment.utc(formData.submissionDate.value).toDate() : null,
            issued: formData.issueDate.value ? moment.utc(formData.issueDate.value).toDate() : null,
            expire: formData.expiryDate.value ? moment.utc(formData.expiryDate.value).toDate() : null,
            refused_date: null,
            status: formData.status.value.value?.length ? formData.status.value.value as TDocStatus : "approved",
            document_id: formData.document_id.value ? formData.document_id.value : null,
        }

        getDocumentExpiryNotifications({
            payload: genericDocument,
            isProcessing,
            setIsProcessing,
            documentType: 'passport',
            documentPayload: payload,
        });
    }

    const onGetPreSignedUrl = (isDownload = false) => {
        if (!formData.document?.id) return;

        getPreSignedUrl({
            workerId: worker_id ? Number(worker_id) : 0,
            documentId: formData.document.id ?? 0,
            documentType: 'passport',
            fileName: formData.document.original,
            expiresIn: 3600,
            setPresignedUrl: setSignedUrl,
            isDownload
        });
    }

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (linked || hasGlobalUnsavedChanges()) return;

        const file = e.target.files?.[0];
        e.target.value = '';

        if (file) {
            parseDocument({
                type: "passport",
                data: file,
                setIsProcessing: setIsFileProcessing,
                setApiError,
                docId: formData.id ?? 0,
                foreignWorkerId: Number(worker_id),
                passports: null,
                lmias: null,
                permitTypes: null,
                setCancelToken: setCancelParse,
                setFormData,
                isDataChanged,
                setPresignedUrl: setSignedUrl,
            }, {
                onSuccess: () => {
                    setShowPreview(true);
                }
            });
        }
    }

    const onRemoveFile = () => {
        if (linked || hasGlobalUnsavedChanges()) return;

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: "Remove Upload?",
                content: "Do you want to remove this passport document? You cannot undo this action.",
                actionTxt: "Confirm",
                action: () => {
                    deleteDocument({
                        workerId: worker_id ? Number(worker_id) : 0,
                        documentId: formData.document_id.value ?? 0,
                        type: 'passport',
                    }, {
                        onSuccess: () => {
                            setFormData(prevData => {
                                return {
                                    ...prevData,
                                    document_id: { value: 0, error: "" },
                                    document: null,
                                }
                            });

                            isDataChanged();
                        },
                    });

                    dispatch(closeModal('MESSAGE_MODAL'));
                },
            }
        }));
    };

    const onPreviewFile = () => {
        setShowPreview(true);
        onGetPreSignedUrl();
    };

    const onDownloadFile = async () => {
        onGetPreSignedUrl(true);
    };

    const onMinimizePreview = () => {
        setShowPreview(false);
        setSignedUrl(null);
    }

    useEffect(() => {
        const fetchedData = getFormData();
        setFormData(fetchedData);
    }, [passportData]); // eslint-disable-line

    useEffect(() => {
        if (isProcessing || isFileProcessing || isLoading) {
            setIsGlobalLoading({ [index]: true });
        } else {
            setIsGlobalLoading({ [index]: false });
        }
    }, [isFileProcessing, isLoading, isProcessing, index]); // eslint-disable-line

    useEffect(() => {
        if (isDataChanged()) {
            setHasUnsavedData({ [index]: true });
        } else {
            setHasUnsavedData({ [index]: false });
        }
    }, [formData, index]); // eslint-disable-line

    return (
        <>
            <div className='immployer__details_form_inputs__container__deletable_forms__with__preview'>
                <div className="immployer__details_form_inputs__container__deletable_forms">
                    <UploadFileBtn
                        linked={linked || hasGlobalUnsavedChanges()}
                        docType="Passport"
                        document={formData.document}
                        isProcessing={isFileProcessing}
                        apiError={apiError}
                        cancelParse={cancelParse}
                        onFileChange={onFileChange}
                        onRemoveFile={onRemoveFile}
                        onPreviewFile={onPreviewFile}
                    />

                    <div className="immployer__details_form_inputs">
                        <TextInput
                            name="passportNumber"
                            type="text"
                            label="Passport Number"
                            max_length={50}
                            required={true}
                            pattern_mask="doc_number"
                            placeholder="Enter passport number"
                            value={formData.passportNumber.value}
                            onChange={(value: string) => onChangeText("passportNumber", value)}
                            errorMsg={formData.passportNumber.error}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <SearchSelect
                            name="nationality"
                            label="Passport country"
                            placeholder="Search country"
                            value={formData.nationality.value}
                            onChange={(option: ISelectDropdownOption) => onChangeSelect("nationality", option)}
                            errorMsg={formData.nationality.error}
                            options={OPCountries}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <CalenderInput
                            name="issueDate"
                            label="Issue Date"
                            errorMsg={formData.issueDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("issueDate", value)}
                            value={formData.issueDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <CalenderInput
                            name="expiryDate"
                            label="Expiry Date"
                            errorMsg={formData.expiryDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("expiryDate", value)}
                            value={formData.expiryDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                    </div>
                </div>
                {formData.document && signedUrl && showPreview &&
                    <PreviewPanel
                        linked={linked || hasGlobalUnsavedChanges()}
                        fileName={formData.document.original}
                        src={signedUrl}
                        onDownloadFile={onDownloadFile}
                        onRemoveFile={onRemoveFile}
                        onMinimizePreview={onMinimizePreview}
                    />
                }
            </div>
            <ImmigrationFormAction
                docType="Passport"
                isProcessing={isProcessing}
                isDataChanged={isDataChanged || hasGlobalUnsavedChanges()}
                isDisabled={isDisabled}
                onDiscardChanges={onDiscardChanges}
                onConfirmDelete={onConfirmDelete}
                onSaveData={onSaveData}
                isOkToDelete={isOkToDelete}
            />
        </>
    )
}
export default PassportForm;