import axios, { CancelToken } from "axios";

import api from "."

import cookiesHelper from "../helpers/cookies";

export const parseDocument = async (foreignWorkerId: number, docId: number, type: string, data: FormData, cancelToken: CancelToken) => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`

    try {
        const response = await api.post(`documents/parse/${foreignWorkerId}/${docId}/${type}`, data, { headers: { 'Content-Type': 'multipart/form-data' }, cancelToken });
        return response.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getPreSignedUrl = async (workerId: number, documentId: number, documentType: string, expiresIn: number) => {
    try {
        const response = await api.get(`documents/pre-signed/${workerId}/${documentId}/${documentType}/${expiresIn}`);
        return response.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getDownloadUrl = async (preSignedUrl: string) => {
    const response = await axios.get(preSignedUrl, { responseType: 'blob' });

    const url = URL.createObjectURL(new Blob([response.data]));

    return url;
};

export const deleteDocument = async (workerId: number, documentId: number, type: string) => {
    try {
        const response = await api.delete(`documents/${workerId}/${documentId}/${type}`);
        return response.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};