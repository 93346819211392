import React from 'react'

import { IApplicantScoreHypoGroup } from '../../../../interfaces/services';

import { iconAssets } from '../../../../constants/assets';

interface IScoreHypoCardProps {
    scoreHypoGroup: IApplicantScoreHypoGroup
}
const ScoreHypoCard = (props: IScoreHypoCardProps) => {
    const { scoreHypoGroup } = props;

    const getDifficulty = (difficulty: number) => {
        if (difficulty <= 1) {
            return 'easy';
        } else if (difficulty <= 2) {
            return 'medium';
        } else {
            return 'hard';
        }
    };

    const hypoDifficulty = getDifficulty(scoreHypoGroup.difficulty);

    return (
        <div className='immployer__recommendation_card'>
            <div className='immployer__recommendation_card__content'>
                <div className='immployer__recommendation_card__header'>
                    <h6 className='immployer__recommendation_card__score_value immployer__score_easy_color'>
                        {scoreHypoGroup.score}pts
                    </h6>
                    <div className={`immployer__recommendation_card__score_badge immployer__recommendation_card__score_badge__${hypoDifficulty}`}>
                        <span className='immployer__recommendation_card__score_badge__icon' />
                        <span className='immployer__recommendation_card__score_badge__icon' />
                        <span className='immployer__recommendation_card__score_badge__icon' />
                        <span className='immployer__recommendation_card__difficulty_text'>
                            {hypoDifficulty.charAt(0).toUpperCase() + hypoDifficulty.slice(1)}
                        </span>
                    </div>
                </div>
                <div className='immployer__recommendation_card__body'>
                    <ul>
                        {scoreHypoGroup.grouped_score_hypos.map((hypo, index) => (
                            <li key={index}>
                                <span className='immployer__recommendation_card__body__bullet_icon' />
                                <h6 className='immployer__recommendation_card__body__hypo_text'>{hypo.description}</h6>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='immployer__recommendation_card__favorite_icon_container'>
                <img src={scoreHypoGroup.favorite ? iconAssets.Heart.heart_fill : iconAssets.Heart.heart_outline} alt='' />
            </div>
        </div>
    )
}

export default ScoreHypoCard;