import { useQuery } from "@tanstack/react-query";

import { getForeignWorkerChangeLog } from "../../services/foreign_workers";

import { IChangeLogWithDate } from "../../interfaces/services";

const useGetForeignWorkerChangeLog = (foreignWorkerId: string | undefined) => {
    const foreign_worker_id = foreignWorkerId ? parseInt(foreignWorkerId) : 0;

    const queryFn = async (): Promise<IChangeLogWithDate[]> => {
        return await getForeignWorkerChangeLog(foreign_worker_id);
    };

    return useQuery({
        queryKey: ['foreign_worker_change_log', foreign_worker_id],
        queryFn,
        enabled: foreign_worker_id > 0,
        retry: 0,
    });
};

export default useGetForeignWorkerChangeLog;