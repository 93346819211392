import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ISaveForeignWorker } from "../../interfaces/services";
import { IForeignWorker } from "../../interfaces/entities";

import useToast from "../useToast";

import { saveForeignWorker } from "../../services/foreign_workers";

import { closeModal, openModal } from "../../store/slices/modal";

import useCancelInvitation from "../invitations/useCancelInvitation";

export interface IPayload {
    payload: ISaveForeignWorker;
    operation: string;
    setIsProcessing: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
    data: IForeignWorker;
    invitationId?: number;
};

const useSaveForeignWorker = () => {
    const navigate = useNavigate();
    // const queryClient = useQueryClient();

    const { addToast } = useToast();
    const dispatch = useDispatch();

    const { mutate: cancelInvitation } = useCancelInvitation();

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await saveForeignWorker(data.payload);
    };

    return useMutation({
        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response?.statusCode === 200) {
                addToast({
                    type: 'success',
                    title: 'Worker details saved successfully.',
                });

                if (variables.operation === 'create') {
                    setTimeout(() => {
                        navigate(`/foreign-workers/${response?.data.id}/employment`);
                        // navigate(`/foreign-workers`);
                    }, 500);
                } else {
                    // queryClient.invalidateQueries({ queryKey: ['foreign_worker_details'] });
                    setTimeout(() => {
                        navigate(`/foreign-workers`);
                    }, 500);
                };
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            if (error.message.includes("PENDING-INVITATION")) {
                dispatch(openModal({
                    name: 'MESSAGE_MODAL',
                    data: {
                        view: 'CONFIRMATION',
                        closeBtn: true,
                        title: "Can't change the email.",
                        content: "The worker has a pending invitation. You can't change the email address until the invitation is responded or cancelled. You can cancel the invitation and resend it with the new email address. Do you want to cancel the invitation?",
                        actionTxt: "Cancel Invitation",
                        action: async () => {
                            if (error.invitationId) {
                                cancelInvitation({
                                    invitationId: error.invitationId,
                                });
                            } else {
                                dispatch(closeModal('MESSAGE_MODAL'));
                            };
                        }
                    }
                }));
            } else {
                addToast({
                    type: 'error',
                    title: 'Error occured',
                    message: error.message
                });
            };
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useSaveForeignWorker;