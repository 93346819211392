import { useQuery } from "@tanstack/react-query";
import { IApplicantProgramPayload, ICombinedApplicantPrograms } from "../../interfaces/services";
import { getForeignWorkerApplicantPrograms } from "../../services/foreign_workers";

const useGetForeignWorkerApplicantPrograms = (payload: IApplicantProgramPayload, linked: boolean) => {
    const { foreign_worker_id, category_group } = payload;

    const queryFn = async (): Promise<ICombinedApplicantPrograms> => {
        return await getForeignWorkerApplicantPrograms(payload);
    };

    return useQuery({
        queryKey: ['foreign_worker_applicant_programs', foreign_worker_id, category_group],
        queryFn,
        enabled: foreign_worker_id > 0 && category_group.length > 0 && linked,
        retry: 0,
    });
};
export default useGetForeignWorkerApplicantPrograms;