import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "../Main";

import { IEmployerLayoutProps } from "../../../interfaces/layouts";
import { EmployerTab } from "../../../interfaces/employer";
import { ISortQuery } from "../../../interfaces/services";

import useGetLocations from "../../../hooks/locations/useGetLocations";

const EmployerDetailsLayout = (props: IEmployerLayoutProps) => {
    const { operation, currentTab, title, error, children } = props;

    const { employer_id } = useParams();
    const navigate = useNavigate();

    const tabsList: EmployerTab[] = ['General', 'Locations', 'LMIAs', 'Foreign Workers', "Notifications"];
    const sortQuery: ISortQuery = { sort_key: 'identifier', sort_order: 'ASC' };

    const { data: locations } = useGetLocations('employer', employer_id, sortQuery);

    const onChangeTab = (tab: EmployerTab) => {
        if (tab === currentTab || operation === 'create') return;

        if (tab === 'General') {
            navigate(`/employers/${employer_id}/details`);
        } else if (tab === 'Locations') {
            navigate(`/employers/${employer_id}/locations`);
        } else if (tab === 'LMIAs') {
            if (!locations?.length) return;
            navigate(`/employers/${employer_id}/lmias`);
        } else if (tab === 'Foreign Workers') {
            if (!locations?.length) return;
            navigate(`/employers/${employer_id}/foreign-workers`);
        } else if (tab === 'Notifications') {
            navigate(`/employers/${employer_id}/notifications`);
        };
    };

    return (
        <MainLayout>
            <div className="immployer__common_details__layout">
                <div className="immployer__form_details__head">
                    <h2 className="color_dark">{title}</h2>
                </div>
                <div className="immployer__common_details__tabs__container">
                    <div className="immployer__tabs">
                        {tabsList.map((tab, index) =>
                            <button
                                key={index}
                                className={`immployer__tab ${currentTab === tab ? "immployer__tab__active" : ""} ${(operation === 'create' && tab !== "General") || (!locations?.length && (tab === 'LMIAs' || tab === 'Foreign Workers')) ? 'immployer__tab__disabled' : ''}`}
                                onClick={() => onChangeTab(tab)}
                            >
                                {tab}
                            </button>
                        )}
                    </div>
                </div>
                <div className="immployer__common_details__content">
                    {
                        error
                            ? <div className="immployer__common_details__error_view">
                                <br /><br />
                                <h4 className="color_dark text_center">Error occured while fetching the data.</h4>
                                <h4 className="color_dark text_center">Please try again later.</h4>
                            </div>
                            : children
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default EmployerDetailsLayout;