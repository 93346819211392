import { useQuery } from "@tanstack/react-query";

import { getLMIAsForForeignWorker } from "../../services/lmias";

import { ILMIA } from "../../interfaces/entities";

const useGetLMIAsForForeignWorker = (foreignWorkerId: string | undefined) => {
    const foreign_worker_id = foreignWorkerId ? parseInt(foreignWorkerId) : 0;

    const queryFn = async (): Promise<ILMIA[]> => {
        return await getLMIAsForForeignWorker(foreign_worker_id);
    };

    return useQuery({
        queryKey: ['lmias_list__foreign_worker', foreign_worker_id],
        queryFn,
        enabled: foreign_worker_id > 0,
        retry: 0,
    });
};

export default useGetLMIAsForForeignWorker;