import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import MainLayout from "../Main";

import { IWorkerLayoutProps } from "../../../interfaces/layouts";
import { WorkerTab } from "../../../interfaces/foreign_worker";
import { TInviteStatus } from "../../../interfaces/entities.types";

import { openModal } from "../../../store/slices/modal";

import { iconAssets } from "../../../constants/assets";

const WorkerDetailsLayout = (props: IWorkerLayoutProps) => {
    const { goBackRoute, operation, currentTab, title, error, children, invitations, linkedStatus, isEmploymentAdded } = props;
    const { isDataAvailable, linked } = linkedStatus;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { worker_id } = useParams();

    const tabsList: WorkerTab[] = ['Personal', 'Employment', 'Immigration', 'Notifications', 'Invitations', 'Change-Log'];
    const [inviteStatus, setInviteStatus] = useState<TInviteStatus | null>(null);

    useEffect(() => {
        if (invitations?.length) {
            const invitation = invitations[0];
            if (invitation?.status === 'pending') {
                setInviteStatus('invited');
            } else if (invitation?.status === 'accepted') {
                setInviteStatus('accepted');
            } else if (invitation?.status === 'refused') {
                setInviteStatus('refused');
            } else if (invitation?.status === 'rescinded') {
                setInviteStatus('rescinded');
            }
        } else {
            setInviteStatus('not-invited');
        }
    }, [invitations]);

    const onChangeTab = (tab: WorkerTab) => {
        if (tab === currentTab || operation === 'create') return;

        navigate(`/foreign-workers/${worker_id}/${tab.toLowerCase()}`, { state: { goBackRoute: goBackRoute ?? '/foreign-workers' } });
    };

    const onInviteWorker = () => {
        if (!worker_id || operation === 'create') return;

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'INVITATION',
                foreignWorkerId: worker_id ?? null,
            }
        }));
    };

    const onUnlinkWorker = () => {
        if (!worker_id || operation === 'create') return;

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'UNLINK_FOREIGN_WORKER',
                foreignWorkerId: worker_id ?? null,
            }
        }));
    };

    return (
        <MainLayout>
            <div className="immployer__common_details__layout">
                <div className="immployer__form_details__head">
                    <h2 className="color_dark">{title}</h2>
                    {
                        !isEmploymentAdded
                            ? <button className={`immployer__btn immployer__btn__primary immployer__btn__primary__disabled`}>
                                Invite
                            </button>
                            : isDataAvailable && linked
                                ? <button className='immployer__btn immployer__employee_linked_btn'>
                                    <span>Linked</span>
                                    <img src={iconAssets.CheckmarkCircle.success} alt="" />
                                </button>
                                : inviteStatus === 'invited'
                                    ? <button className='immployer__btn immployer__btn__primary cursor__default'>
                                        <span>Pending invite</span>
                                        <img src={iconAssets.Dots3HorizCircle.white} alt="" />
                                    </button>
                                    : isDataAvailable
                                        ? <button
                                            className={`immployer__btn immployer__btn__primary ${worker_id ? '' : 'immployer__btn__primary__disabled'}`}
                                            onClick={onInviteWorker}
                                        >
                                            Invite
                                        </button>
                                        : <button className={`immployer__btn immployer__btn__primary immployer__btn__primary__disabled`}>
                                            Invite
                                        </button>
                    }
                    {
                        isDataAvailable && linked
                            ? <button
                                className={`immployer__employee_unlink_btn ${worker_id ? '' : 'immployer__employee_unlink_btn__disabled'}`}
                                onClick={onUnlinkWorker}
                            >
                                <span>Unlink</span>
                            </button>
                            : null
                    }
                </div>
                <div className="immployer__common_details__tabs__container">
                    <div className="immployer__tabs">
                        {tabsList.map((tab, index) =>
                            <button
                                key={index}
                                className={`immployer__tab ${currentTab === tab ? "immployer__tab__active" : ""} ${operation === 'create' && tab !== "Personal" ? 'immployer__tab__disabled' : ''}`}
                                onClick={() => onChangeTab(tab)}
                            >
                                {tab}
                            </button>
                        )}
                    </div>
                </div>
                <div className="immployer__common_details__content">
                    {
                        error
                            ? <div className="immployer__common_details__error_view">
                                <br /><br />
                                <h4 className="color_dark text_center">Error occured while fetching the data.</h4>
                                <h4 className="color_dark text_center">Please try again later.</h4>
                            </div>
                            : children
                    }
                </div>
            </div>
        </MainLayout>
    );
};

export default WorkerDetailsLayout;