import React, { Fragment, ReactNode, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import '../styles/index.scss';

import Toasts from './reusables/Toast';

import { closeAllModals } from '../store/slices/modal';

import MessageModal from './modals/Message';
import TimeoutModal from './modals/Timeout';
import HubSpotChat from './hubspot/HubSpotChat';
import FilterModal from './modals/Filter';

const App = ({ children }: { children: ReactNode }) => {

  const location = useLocation();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(closeAllModals());

    // eslint-disable-next-line
  }, [location]);

  return (
    <Fragment>
      <HubSpotChat />
      <Toasts />
      <MessageModal />
      <TimeoutModal />
      <FilterModal />

      <div className="immployer__app">
        {children}
      </div>

    </Fragment>
  );
};

export default App;