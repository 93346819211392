import api from "./index";

import cookiesHelper from "../helpers/cookies";

import { IPermitType } from "../interfaces/entities";

export const getPermitTypes = async (): Promise<IPermitType[]> => {
    const token = cookiesHelper.getCookie((process.env.REACT_APP_AUTH_TOKEN_NAME as string));
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`permit-types/immployer`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};