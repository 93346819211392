import React, { RefObject, ReactNode, useEffect, useRef } from "react";

const useOutsideAlerter = (ref: RefObject<HTMLElement>, callback: () => void) => {
    const handleClick = (e: MouseEvent) => {
        if (ref.current && !ref.current.contains(e.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => {
            document.removeEventListener('mousedown', handleClick);
        };

        // eslint-disable-next-line
    }, [ref, callback]);
};

interface OutsideAlerterProps {
    children: ReactNode;
    callback: () => void;
    isActive: boolean;
    classes?: string;
};

const OutsideAlerter = (props: OutsideAlerterProps) => {
    const { children, callback, isActive, classes } = props;

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, callback);

    return <div ref={isActive ? wrapperRef : null} className={classes}>{children}</div>;
};

export default OutsideAlerter;