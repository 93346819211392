import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { GettingStartedCard } from "../reusables/Cards";

import { IOrganizationStatus } from "../../interfaces/services";

import { getLMIAApplicable } from "../../store/slices/global";

const GettingStarted = (props: IOrganizationStatus) => {
    const { employerId, ctEmployers, ctLocations, ctLMIAs, ctWorkers } = props;

    const navigate = useNavigate();

    const LMIAApplicable = useSelector(getLMIAApplicable());

    const onClickEmployer = () => {
        if (!!ctEmployers && !!ctLocations) return;

        if (employerId) {
            if (!ctLocations) {
                navigate(`/employers/${employerId}/locations/new`);
            } else {
                navigate('/employers');
            };
        } else {
            navigate('/employers/new');
        };
    };

    const onClickLMIA = () => {
        if (!ctEmployers || !ctLocations || ctLMIAs) return;

        if (employerId) {
            navigate(`employers/${employerId}/lmias/new`);
        } else {
            navigate(`employers`);
        };
    };

    const onClickForeignWorker = () => {
        if (!ctEmployers || !ctLocations || (!ctLMIAs && LMIAApplicable) || ctWorkers) return;

        navigate(`/foreign-workers/new`);
    };

    return (
        <>
            <GettingStartedCard
                name="employer_location"
                code="active"
                title="Step 1: Company Information"
                isComplete={!!ctEmployers && !!ctLocations}
                action={onClickEmployer}
            />

            <GettingStartedCard
                name="lmia"
                code={ctEmployers && ctLocations ? "active" : "inactive"}
                title="Step 2: Add LMIAs or LMIA exempt (if applicable)"
                isComplete={!!ctLMIAs}
                action={onClickLMIA}
            />

            <GettingStartedCard
                name="worker"
                code={(ctEmployers && ctLocations && (ctLMIAs || !LMIAApplicable)) || !!ctWorkers ? "active" : "inactive"}
                title="Step 3: Add your first foreign worker"
                isComplete={!!ctWorkers}
                action={onClickForeignWorker}
            />
        </>
    );
};

export default GettingStarted;