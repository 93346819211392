import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { IGenericDocument, ICreateNotification } from "../../interfaces/services";

import useToast from "../useToast";

import { getDocumentExpiryNotifications } from "../../services/expiry_schedules";

import { openModal } from "../../store/slices/modal";

import useSavePassport from "../passports/useSavePassport";
import useSavePermit from "../permits/useSavePermit";
import useSaveVisa from "../visas/useSaveVisa";

export interface IPayload {
    payload: IGenericDocument;
    documentType: string;
    documentPayload: any;
    isProcessing: boolean;
    setIsProcessing: Function;
};

export interface IResponse {
    error?: string;
    message: string;
    statusCode: number;
    data: ICreateNotification[];
};

const useGetDocumentExpiryNotification = () => {
    const { addToast } = useToast();

    const dispatch = useDispatch();

    const { mutate: savePassport } = useSavePassport();
    const { mutate: savePermit } = useSavePermit();
    const { mutate: saveVisa } = useSaveVisa();

    const saveDocument = (documentType: string, documentPayload: any, setIsProcessing: Function) => {
        switch (documentType) {
            case 'passport':
                savePassport({
                    payload: documentPayload,
                    setIsProcessing,
                });
                break;
            case 'permit':
                savePermit({
                    payload: documentPayload,
                    setIsProcessing,
                });
                break;
            case 'visa':
                saveVisa({
                    payload: documentPayload,
                    setIsProcessing,
                });
                break;

            default:
                break;
        };
    };

    const mutationFn = async (data: IPayload): Promise<IResponse> => {
        return await getDocumentExpiryNotifications(data.payload);
    };

    return useMutation({
        mutationFn,
        onSuccess: async (response: IResponse, variables: IPayload) => {
            if (response?.statusCode === 200) {

                if (response.data.length) {
                    dispatch(openModal({
                        name: 'MESSAGE_MODAL',
                        data: {
                            view: 'NOTIFICATION',
                            notifications: response.data,
                            documentType: variables.documentType,
                            documentPayload: variables.documentPayload,
                            isProcessing: variables.isProcessing,
                            setIsProcessing: variables.setIsProcessing,
                        }
                    }));
                } else {
                    saveDocument(variables.documentType, variables.documentPayload, variables.setIsProcessing);
                };
            };
        },
        onError: (error: IResponse, variables: IPayload) => {
            addToast({
                type: 'error',
                title: 'Error occured',
                message: error.message
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => {
            variables.setIsProcessing(false);
        },
    });
};

export default useGetDocumentExpiryNotification;