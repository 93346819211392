import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteDocument } from "../../services/documents";

import useToast from "../useToast";

interface IPayload {
    workerId: number;
    documentId: number;
    type: string;
}

interface IResponse {
    statusCode: number;
    message: string;
    error?: string;
}

const useDeleteDocument = () => {
    const mutationFn = async (payload: IPayload): Promise<IResponse> => {
        try {
            return await deleteDocument(payload.workerId, payload.documentId, payload.type);
        } catch (error: any) {
            throw error?.response?.data;
        };
    }

    const { addToast } = useToast();
    const queryClient = useQueryClient();

    return useMutation({

        mutationFn,
        onSuccess: (response: IResponse, variables: IPayload) => {
            if (response.statusCode === 200) {
                queryClient.invalidateQueries({ queryKey: ['foreign_worker_immigration_docs'] });

                addToast({
                    type: 'success',
                    title: variables.type.charAt(0).toUpperCase() + variables.type.slice(1) + ' deleted successfully',
                });
            }
        },
        onError: (error: IResponse) => {
            addToast({
                type: 'error',
                title: 'Error!',
                message: error.message,
            });
        },
        onSettled: (data: IResponse | undefined, error: IResponse | null, variables: IPayload) => { }
    })
}

export default useDeleteDocument;