import React, { useState } from "react";

import LMIADetailsLayout from "../../../layouts/details/LMIA";
import LMIADetailsFormView from "../../../views/details_forms/LMIA";

import { ILMIAData } from "../../../../interfaces/lmia";

const AddLMIAPage = () => {
    const [formData, setFormData] = useState<ILMIAData>({
        id: null,
        location: { value: { label: "", value: "" }, error: "" },
        lmia_number: { value: "", error: "" },
        type: { value: { label: "", value: "" }, error: "" },
        category: { value: { label: "", value: "" }, error: "" },
        jobTitle: { value: { label: "", value: "" }, error: "" },
        submissionDate: { value: "", error: "" },
        issueDate: { value: "", error: "" },
        expiryDate: { value: "", error: "" },
        wage: { value: "", error: "" },
        wageType: { value: { label: "", value: "" }, error: "" },
        positions: { value: "", error: "" },
        status: { value: { label: "", value: "" }, error: "" },
    });

    return (
        <LMIADetailsLayout
            title='Add a LMIA'
            error={false}
        >
            <LMIADetailsFormView
                operation='create'
                isLoading={false}
                lmiaData={null}
                formData={formData}
                setFormData={setFormData}
            />
        </LMIADetailsLayout>
    );
};

export default AddLMIAPage;