import { useQuery } from "@tanstack/react-query";

import { getWorkerJobs } from "../../services/worker_jobs";

import { IWorkerJob } from "../../interfaces/entities";

const useGetWorkerJobs = (foreignWorkerId: string | undefined) => {
    const foreign_worker_id = foreignWorkerId ? parseInt(foreignWorkerId) : 0;

    const queryFn = async (): Promise<IWorkerJob[]> => {
        return await getWorkerJobs(foreign_worker_id);
    };

    return useQuery({
        queryKey: ['worker_jobs', foreign_worker_id],
        queryFn,
        enabled: foreign_worker_id > 0,
        retry: 0,
    });
};

export default useGetWorkerJobs;