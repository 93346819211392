import { Navigate, Outlet, useLocation } from "react-router-dom";

import authHelper from "../helpers/auth";

const PrivateRoutes = () => {
    const location = useLocation();

    const tokenValidationRes = authHelper.isAuthTokenValid();

    return (
        tokenValidationRes.result
            ? <Outlet />
            : <Navigate to="/auth/sign-in" state={{ from: location }} replace />
    );
};

export default PrivateRoutes;